import { setAutoFreeze } from "immer";
import { useContext, useEffect, useState } from "react";
import { QueryKey, UseMutateFunction, useMutation, useQuery, useQueryClient } from "react-query";
import { useDebouncedCallback } from "use-debounce";
import { asyncSleep } from "../../../utils";
import {
	BondFormTemplate,
	BondFormTemplatePreview,
	BondRequest,
	BondRequestDraftData,
	BondRequestPreview,
	SubmittedBondRequest,
} from "./types";

import {
	AttachmentFile,
	AttachmentFileType,
	CompanyContactId,
	Dtos,
	FileType,
	SuretyAccountId,
	SuretyBondId,
	SuretyQuoteId,
	SuretyType,
	UploadedFile,
} from "@inrev/inrev-common";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { LocallyUploadedFile } from "../../../types";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { useCreateAccountIndemnityAgreement } from "../account/api";

setAutoFreeze(false);

export const useCreateBondRequestDraft = () => {
	const { post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: "createBondRequest",
		mutationFn: async (existingPrincipal?: {
			principalCompanyId: CompanyContactId;
			accountId: SuretyAccountId;
		}) => {
			const body: Dtos.SuretyQuote.Draft.Create.Request = {
				suretyType: SuretyType.contract,
				...(existingPrincipal ?? { principalCompanyId: undefined, accountId: undefined }),
			};
			return await post<BondRequest>("/v2/surety/quotes/draft", body);
		},
		onSuccess: (newRequest) => {
			queryClient.setQueryData(["bondRequests", newRequest.id], newRequest);
			queryClient.refetchQueries(["bondRequestPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createBondRequest: mutation.mutate,
		createBondRequestIsLoading: mutation.isLoading,
		createBondRequestError: mutation.error,
	};
};

export const useFetchBondRequest = (id: string) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading, isFetching } = useQuery({
		queryKey: ["bondRequests", id],
		queryFn: async () => await get<BondRequest>(`/v2/surety/quotes/${id}`),
		retry: false,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		bondRequest: data,
		bondRequestError: error,
		bondRequestLoading: isLoading,
		bondRequestIsFetching: isFetching,
	};
};

export const useAwaitedUpdateBondRequestDraft = (id: string) => {
	const { patch } = useRequest();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async (data: BondRequestDraftData): Promise<BondRequest> => {
			return await patch<Dtos.SuretyQuote.Draft.Update.Response>(
				`/v2/surety/quotes/draft/${id}/sync`,
				data,
			);
		},
		onSuccess: (result) => {
			queryClient.setQueryData<BondRequest>(["bondRequests", id], result);
			queryClient.invalidateQueries(["bondRequestPreviews"]);
		},
	});

	const debouncedMutate = useDebouncedCallback(mutation.mutate, 50); // debounced to let form fields move focus to next element

	return {
		awaitUpdateBondRequestDraft: debouncedMutate,
		awaitUpdateBondRequestDraftIsLoading: mutation.isLoading,
	};
};

export const useUpdateBondRequestDraft = (id: string) => {
	const { patch } = useRequest();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async (data: BondRequestDraftData): Promise<void> => {
			await patch(`/v2/surety/quotes/draft/${id}`, data, "none");
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["bondRequestPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
		},
	});

	return {
		updateBondRequestDraft: mutation.mutate,
		updateBondRequestDraftIsLoading: mutation.isLoading,
	};
};

export const useFetchBondFormTemplates = () => {
	const { get } = useRequest();
	const { data } = useQuery({
		queryKey: ["bondFormTemplates"],
		queryFn: async () => await get<BondFormTemplate[]>("/v2/surety/bond-forms/templates"),
		staleTime: Infinity,
		cacheTime: Infinity,
	});

	return data ?? [];
};

export const useFetchBondFormTemplatePreview = (id: string) => {
	const { get } = useRequest();
	const { data } = useQuery({
		queryKey: ["bondFormTemplatePreview", id],
		queryFn: async () =>
			await get<BondFormTemplatePreview>(`/v2/surety/bond-forms/templates/${id}/preview`),
		retry: false,
		staleTime: Infinity,
		cacheTime: Infinity,
	});

	return data;
};

const useFileUploadState = <TFileType extends FileType = FileType, T = UploadedFile<TFileType>>(
	onUploadSuccess: (data: T, args: { pendingFile: UploadedFile<TFileType> }) => void,
	onUploadError: (error: unknown, args: { pendingFile: UploadedFile<TFileType> }) => void,
	onDeleteSuccess: (args: { id: string }) => void,
	onDeleteError: (error: unknown, args: { id: string }) => void,
) => {
	const [uploadSuccess, setUploadSuccess] = useState<
		| {
				data: T;
				args: {
					rawFile: File;
					pendingFile: UploadedFile<TFileType>;
				};
		  }
		| undefined
	>();
	const [uploadError, setUploadError] = useState<
		| {
				error: unknown;
				args: {
					rawFile: File;
					pendingFile: UploadedFile<TFileType>;
				};
		  }
		| undefined
	>();
	const [deleteSuccess, setDeleteSuccess] = useState<
		| {
				args: {
					id: string;
				};
		  }
		| undefined
	>();
	const [deleteError, setDeleteError] = useState<
		| {
				error: unknown;
				args: {
					id: string;
				};
		  }
		| undefined
	>();

	useEffect(() => {
		if (uploadSuccess !== undefined) {
			onUploadSuccess(uploadSuccess.data, uploadSuccess.args);
		}
	}, [uploadSuccess]);

	useEffect(() => {
		if (uploadError !== undefined) {
			onUploadError(uploadError.error, uploadError.args);
		}
	}, [uploadError]);

	useEffect(() => {
		if (deleteSuccess !== undefined) {
			onDeleteSuccess(deleteSuccess.args);
		}
	}, [deleteSuccess]);

	useEffect(() => {
		if (deleteError !== undefined) {
			onDeleteError(deleteError.error, deleteError.args);
		}
	}, [deleteError]);

	return { setUploadSuccess, setUploadError, setDeleteSuccess, setDeleteError };
};

export type UseUpload<T, TFileType extends FileType = FileType> = (
	onUploadSuccess: (data: T, args: { pendingFile: UploadedFile<TFileType> }) => void,
	onUploadError: (error: unknown, args: { pendingFile: UploadedFile<TFileType> }) => void,
	onDeleteSuccess: (args: { id: string }) => void,
	onDeleteError: (error: unknown, args: { id: string }) => void,
) => {
	uploadFn: UseMutateFunction<
		T,
		unknown,
		{
			rawFile: File;
			pendingFile: UploadedFile<TFileType>;
		},
		unknown
	>;
	deleteFn: UseMutateFunction<
		void,
		unknown,
		{
			id: string;
		},
		unknown
	>;
};

export const useFileUpload = <TFileType extends FileType = FileType>(
	onUploadSuccess: (
		data: UploadedFile<TFileType>,
		args: { pendingFile: UploadedFile<TFileType> },
	) => void,
	onUploadError: (error: unknown, args: { pendingFile: UploadedFile<TFileType> }) => void,
	onDeleteSuccess: (args: { id: string }) => void,
	onDeleteError: (error: unknown, args: { id: string }) => void,
) => {
	const { setUploadSuccess, setUploadError, setDeleteSuccess, setDeleteError } = useFileUploadState(
		onUploadSuccess,
		onUploadError,
		onDeleteSuccess,
		onDeleteError,
	);

	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const uploadFile = useMutation({
		mutationFn: async (args: { rawFile: File; pendingFile: UploadedFile<TFileType> }): Promise<
			UploadedFile<TFileType>
		> => {
			const data = new FormData();
			data.append("file", args.rawFile);
			data.append("types", args.pendingFile.types.join(","));

			return await post<UploadedFile<TFileType>>("/v2/files", data);
		},
		onSuccess: (data, args) => {
			setUploadSuccess({ data, args });
		},
		onError: (error: ApiError, args) => {
			setUploadError({ error, args });
			triggerErrorModal(error);
		},
	}).mutate;

	const deleteFile = useMutation({
		mutationFn: async (_args: { id: string }) => {
			// TODO: implement deleteFile
			await asyncSleep(750);
		},
		onSuccess: (_data, args) => {
			setDeleteSuccess({ args });
		},
		onError: (error: ApiError, args) => {
			setDeleteError({ error, args });
		},
	}).mutate;

	return { uploadFn: uploadFile, deleteFn: deleteFile };
};

export const useSilentlyDeleteFile = () => {
	const mutation = useMutation({
		mutationFn: async (_args: { fileId: string }) => {
			await asyncSleep(1000);
		},
		onError: (error: ApiError, args) => {
			console.error(`Error silently deleting file with id ${args.fileId}: `, error);
		},
	});

	return { silentlyDeleteFile: mutation.mutate };
};

export const useAttachmentUpload =
	<TFileType extends AttachmentFileType = AttachmentFileType>(
		url: `${string}/attachments`,
		invalidateQueryKeys?: QueryKey[],
	) =>
	(
		onUploadSuccess: (
			data: AttachmentFile<TFileType>,
			args: { pendingFile: UploadedFile<TFileType> },
		) => void,
		onUploadError: (error: unknown, args: { pendingFile: UploadedFile<TFileType> }) => void,
		onDeleteSuccess: (args: { id: string }) => void,
		onDeleteError: (error: unknown, args: { id: string }) => void,
	) => {
		const queryClient = useQueryClient();
		const { setUploadSuccess, setUploadError, setDeleteSuccess, setDeleteError } =
			useFileUploadState<TFileType, AttachmentFile<TFileType>>(
				onUploadSuccess,
				onUploadError,
				onDeleteSuccess,
				onDeleteError,
			);

		const { post, _delete } = useRequest();
		const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

		const uploadAttachment = useMutation({
			mutationFn: async (args: { rawFile: File; pendingFile: UploadedFile<TFileType> }): Promise<
				AttachmentFile<TFileType>
			> => {
				const data = new FormData();
				data.append("file", args.rawFile);
				data.append("types", args.pendingFile.types.join(","));

				return await post<AttachmentFile<TFileType>>(url, data);
			},
			onSuccess: (data, args) => {
				if (invalidateQueryKeys)
					invalidateQueryKeys.forEach((queryKey) =>
						queryClient.invalidateQueries(queryKey, undefined, { cancelRefetch: true }),
					);
				setUploadSuccess({ data, args });
			},
			onError: (error: ApiError, args) => {
				setUploadError({ error, args });
				triggerErrorModal(error);
			},
		}).mutate;

		const deleteAttachment = useMutation({
			mutationFn: async (_args: { id: string }) => {
				await _delete(url + `/${_args.id}`, undefined, "none");
			},
			onSuccess: (_data, args) => {
				if (invalidateQueryKeys)
					invalidateQueryKeys.forEach((queryKey) =>
						queryClient.invalidateQueries(queryKey, undefined, { cancelRefetch: true }),
					);
				setDeleteSuccess({ args });
			},
			onError: (error: ApiError, args) => {
				setDeleteError({ error, args });
			},
		}).mutate;

		return { uploadFn: uploadAttachment, deleteFn: deleteAttachment };
	};

export const useSubmitBondRequest = (bondRequestId: string) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["createBondRequest", bondRequestId],
		mutationFn: async (args: {
			data: Dtos.SuretyQuote.Submit.Request["data"];
			draftData: Dtos.SuretyQuote.Submit.Request.Base["draftData"];
		}) => {
			return await post<SubmittedBondRequest>(`/v2/surety/quotes/draft/${bondRequestId}`, {
				suretyType: SuretyType.contract,
				...args,
			});
		},
		onSuccess: (result) => {
			queryClient.setQueryData<BondRequest>(
				["bondRequests", (result as SubmittedBondRequest).id],
				result,
			);
			queryClient.refetchQueries(["bondRequestPreviews"]);
			queryClient.invalidateQueries(["suretyAccounts"]);
			queryClient.refetchQueries(["accountPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { submitBondRequest: mutation.mutate, submitBondRequestIsLoading: mutation.isLoading };
};

export const useRejectBondRequestTerms = (bondRequestId: string) => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["rejectBondRequestTerms", bondRequestId],
		mutationFn: async () => {
			return await patch<Dtos.SuretyQuote.Submitted.Get.Response>(
				`/v2/surety/quotes/${bondRequestId}/reject`,
			);
		},
		onSuccess: (result) => {
			queryClient.setQueryData<BondRequest>(["bondRequests", result.id], result);
			queryClient.refetchQueries(["bondRequestPreviews"]);
			queryClient.invalidateQueries(["suretyAccounts"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		rejectBondRequestTerms: mutation.mutate,
		rejectBondRequestTermsIsLoading: mutation.isLoading,
	};
};

export const useAcceptBondRequestTerms = (bondRequestId: string) => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["acceptBondRequestTerms", bondRequestId],
		mutationFn: async () => {
			return await patch<Dtos.SuretyQuote.Submitted.Get.Response>(
				`/v2/surety/quotes/${bondRequestId}/accept`,
			);
		},
		onSuccess: (result) => {
			queryClient.setQueryData<BondRequest>(["bondRequests", result.id], result);
			queryClient.refetchQueries(["bondRequestPreviews"]);
			queryClient.invalidateQueries(["suretyAccounts"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		acceptBondRequestTerms: mutation.mutate,
		acceptBondRequestTermsIsLoading: mutation.isLoading,
	};
};

export const useIssueBond = (
	request: BondRequest & Extract<BondRequest, { status: "accepted" }>,
) => {
	const queryClient = useQueryClient();
	const { createAccountIndemnityAgreement } = useCreateAccountIndemnityAgreement();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: {
			bondForm: Dtos.SuretyBondIssuance.Create.Request;
			indemnityAgreement?: Dtos.IndemnityAgreement.Create.Request;
		}): Promise<void> => {
			const bondFormData = data.bondForm;
			const indemnityAgreementData = (() => {
				if (request.bondIssuance.indemnityAgreement.status === "incomplete") {
					if (data.indemnityAgreement === undefined) {
						throw new Error("Indemnity agreement is required to issue bond");
					}
					return {
						accountId: request.account.id,
						data: data.indemnityAgreement,
					};
				}
			})();
			if (indemnityAgreementData !== undefined) {
				await createAccountIndemnityAgreement({
					accountId: indemnityAgreementData.accountId,
					data: indemnityAgreementData.data,
				});
			}
			await post(`/v2/surety/quotes/${request.id}/issue`, bondFormData);
		},
		onSuccess: () => {
			queryClient.refetchQueries(["bondRequests", request.id]);
			queryClient.invalidateQueries({
				queryKey: ["bondRequests"],
				predicate: (query) => {
					if (!query.state.data) return false;
					const data = query.state.data as BondRequest;
					return (
						data.status !== "draft" &&
						data.id !== request.id &&
						data.account.id === request.account.id
					);
				},
			});
			queryClient.refetchQueries(["bondRequestPreviews"]);
			queryClient.invalidateQueries(["suretyAccounts"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
			queryClient.refetchQueries(["bondRequests", request.id]);
		},
	});

	return { issueBond: mutation.mutate, issueBondIsLoading: mutation.isLoading };
};

export const useUploadAgentIssuedBondForm = (id: SuretyQuoteId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (file: LocallyUploadedFile): Promise<void> => {
			await post(`/v2/surety/quotes/${id}/bond-form/agent-issue`, {
				file: {
					name: file.name,
					base64: file.base64,
				},
			});
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bondRequests", id] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		uploadAgentIssuedBondForm: mutation.mutate,
		uploadAgentIssuedBondFormIsLoading: mutation.isLoading,
	};
};

export const useDeleteRequestDraft = () => {
	const queryClient = useQueryClient();
	const { _delete } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (requestId: string): Promise<void> => {
			queryClient.setQueryData<BondRequestPreview[]>(["bondRequestPreviews"], (old) => {
				if (!old) throw new Error();
				const index = old.findIndex((preview) => preview.id === requestId);
				if (index === -1) return old;
				else return [...old.slice(0, index), ...old.slice(index + 1)];
			});

			await _delete(`/v2/surety/quotes/draft/${requestId}`, undefined, "none");
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { deleteRequestDraft: mutation.mutate, deleteRequestDraftIsLoading: mutation.isLoading };
};

export const useCreateBidToFinalContractSuretyQuoteDraft = () => {
	const queryClient = useQueryClient();
	const [createdBidToFinalContractSuretyQuoteDraft, setCreatedBidToFinalContractSuretyQuoteDraft] =
		useState<Dtos.SuretyQuote.Get.Response | undefined>();
	const { get, post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (bidBondId: SuretyBondId): Promise<Dtos.SuretyQuote.Get.Response> => {
			try {
				return await post<Dtos.SuretyQuote.Get.Response>(
					`/v2/surety/quotes/contract/b2f/${bidBondId}`,
				);
			} catch (error: any) {
				if (error.name === "EntityAlreadyExistsError") {
					return await get<Dtos.SuretyQuote.Get.Response>(`/v2/surety/quotes/${error.entityId}`);
				}
				throw error;
			}
		},
		onSuccess: (result) => {
			queryClient.setQueryData<Dtos.SuretyQuote.Get.Response>(["bondRequests", result.id], result);
			setCreatedBidToFinalContractSuretyQuoteDraft(result);
			queryClient.refetchQueries(["bondRequestPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createdBidToFinalContractSuretyQuoteDraft,
		createBidToFinalContractSuretyQuoteDraft: mutation.mutate,
		createBidToFinalContractSuretyQuoteDraftIsLoading: mutation.isLoading,
	};
};

export const useArchiveRequest = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (requestId: string): Promise<void> => {
			await post(`/v2/surety/quotes/${requestId}/archive`, undefined, "none");
		},
		onMutate: (requestId: string) => {
			try {
				queryClient.setQueryData<BondRequestPreview[]>(["bondRequestPreviews"], (old) => {
					if (!old) throw new Error();
					const index = old.findIndex((preview) => preview.id === requestId);
					const newPreviews = [...old];
					newPreviews[index] = { ...newPreviews[index], archived: true };
					return newPreviews;
				});
			} catch (error) {}
			queryClient.setQueryData<BondRequest | undefined>(["bondRequests", requestId], (old) => {
				if (!!old) {
					return { ...old, archived: true };
				}
				return old;
			});
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { archiveRequest: mutation.mutate, archiveRequestLoading: mutation.isLoading };
};

export const useUnarchiveRequest = () => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (requestId: string): Promise<void> => {
			await post(`/v2/surety/quotes/${requestId}/unarchive`, undefined, "none");
		},
		onMutate: (requestId: string) => {
			try {
				queryClient.setQueryData<BondRequestPreview[]>(["bondRequestPreviews"], (old) => {
					if (!old) throw new Error();
					const index = old.findIndex((preview) => preview.id === requestId);
					const newPreviews = [...old];
					newPreviews[index] = { ...newPreviews[index], archived: false };
					return newPreviews;
				});
			} catch (error) {}
			queryClient.setQueryData<BondRequest | undefined>(["bondRequests", requestId], (old) => {
				if (!!old) {
					return { ...old, archived: false };
				}
				return old;
			});
		},
		onError: (error: ApiError) => {
			queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] });
			triggerErrorModal(error);
		},
	});

	return { unarchiveRequest: mutation.mutate, unarchiveRequestLoading: mutation.isLoading };
};
