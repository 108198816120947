import { ContractSuretyBondFormType, ContractSuretyType, FileType } from "@inrev/inrev-common";
import { pick } from "rambda";
import { useContext, useEffect, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useGetFileUrl } from "../../../../../../api";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { FormItem } from "../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../components/layout/form/FormRow";
import { FormSchemaInclude } from "../../../../../../components/layout/form/FormSchemaInclude";
import { FormSection } from "../../../../../../components/layout/form/FormSection";
import { BondFormSelect } from "../../../../../../components/ui/BondFormSelect";
import { Tooltip } from "../../../../../../components/ui/Tooltip";
import { FileUpload } from "../../../../../../components/ui/Upload";
import { FormCheckboxGroup } from "../../../../../../components/ui/form/FormCheckboxGroup";
import { FormCurrencyInput } from "../../../../../../components/ui/form/FormCurrencyInput";
import { FormDatePicker } from "../../../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../../components/ui/form/FormItemLabel";
import { FormNAICSCodeSelect } from "../../../../../../components/ui/form/FormNAICSCodeSelect";
import { FormPercentInput } from "../../../../../../components/ui/form/FormPercentInput";
import { FormQuestionLabel } from "../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../../components/ui/form/FormYesNo";
import {
	contractDamageOptions,
	contractHazardOptions,
	usStateOrTerritoryOptions,
} from "../../../../../../constants";
import { useSilentlyDeleteFile } from "../../../../../../domain/agent/request/api";
import {
	BondFormTemplate,
	BondRequestDraftData,
} from "../../../../../../domain/agent/request/types";
import { useIsFirstRender, useScrollToId } from "../../../../../../utils";
import { DraftBondRequestContext } from "../DraftBondRequestView";

type DraftBondRequestBondSectionProps = {
	sections: { name: string; label: string }[];
	bondFormTypesAndLabels: Record<ContractSuretyBondFormType, string>;
	bondFormTemplates: BondFormTemplate[];
};

export const bondTypeOptions = [
	{ value: "bid", label: "Bid Bond" },
	{ value: "final", label: "Final Bond" },
];

export const bidBondAmountTypeOptions = [
	{ value: "percent", label: "Percent of bid amount" },
	{ value: "dollars", label: "Dollar amount" },
];

export const DraftBondRequestBondSection = (props: DraftBondRequestBondSectionProps) => {
	const request = useContext(DraftBondRequestContext);
	const { control, watch, setValue, formState } = useFormContext<BondRequestDraftData>();
	const { silentlyDeleteFile } = useSilentlyDeleteFile();
	const bondFormController = useController({
		control,
		name: "bond.bondForm",
	});
	const isFirstRender = useIsFirstRender();
	const domReadyRef = useScrollToId();
	const bondType = watch("bond.type");
	const bidBondAmountType = watch("bond.bidBondAmountType");
	const accountNaicsCodes = watch("history.naicsCodes");
	const { getFileUrl } = useGetFileUrl();

	const allowedBondFormUploadTypesAndLabels = useMemo(() => {
		if (bondType === ContractSuretyType.final)
			return pick(["pnp", "performance", "payment"], props.bondFormTypesAndLabels);
		else if (bondType === ContractSuretyType.bid)
			return pick(["bid"], props.bondFormTypesAndLabels);
		else return {};
	}, [bondType]);
	const filesController = useController({
		control,
		name: "bond.project.files",
	});

	useEffect(() => {
		if (!isFirstRender && filesController.field.value.length > 0) {
			silentlyDeleteFile({ fileId: filesController.field.value[0].id });
			setValue("bond.project.files", []);
		}
	}, [bondType]);

	return (
		<ShrinkingHeaderSectionNavLayout headerTitle="Bond" sections={props.sections}>
			<div
				ref={domReadyRef}
				className="flex flex-col space-y-[44px] pt-[18px] pb-[125px] min-h-fit"
			>
				<FormItemGroup>
					<FormItem
						schemaInclude={
							request.draft.schema.bond?.include.type && !request.draft.schema.bond?.dataOnly?.type
						}
						id="type"
					>
						<FormItemLabel marker>Bond Type</FormItemLabel>
						<FormDropdown
							name="bond.type"
							control={control}
							placeholder="Select one"
							options={bondTypeOptions}
						/>
					</FormItem>
					<FormRow
						schemaInclude={
							request.draft.schema.bond?.include.bidDate ||
							request.draft.schema.bond?.include.bidAmount
						}
					>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.bidDate}
							id="bidDate"
							className="max-w-fit shrink-0"
						>
							<FormItemLabel marker>Bid Date</FormItemLabel>
							<FormDatePicker name="bond.bidDate" control={control} />
						</FormItem>
						<FormItem schemaInclude={request.draft.schema.bond?.include.bidAmount} id="bidAmount">
							<FormItemLabel marker>Bid Amount</FormItemLabel>
							<FormCurrencyInput name="bond.bidAmount" control={control} updateOnBlur />
						</FormItem>
					</FormRow>
					<FormRow
						schemaInclude={
							request.draft.schema.bond?.include.bidBondAmountType ||
							request.draft.schema.bond?.include.bondAmount ||
							request.draft.schema.bond?.include.contractAmount
						}
					>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.bidBondAmountType}
							id="type"
						>
							<FormItemLabel marker>Bond Amount</FormItemLabel>
							<FormDropdown
								name="bond.bidBondAmountType"
								control={control}
								placeholder="Select one"
								options={bidBondAmountTypeOptions}
							/>
						</FormItem>
						<FormItem schemaInclude={request.draft.schema.bond?.include.bondAmount} id="bondAmount">
							{bondType !== "bid" && <FormItemLabel marker>Bond Amount</FormItemLabel>}
							{(bondType !== "bid" || bidBondAmountType === "dollars") && (
								<FormCurrencyInput name="bond.bondAmount" control={control} updateOnBlur />
							)}
							{bondType === "bid" && bidBondAmountType === "percent" && (
								<FormPercentInput
									name="bond.bondAmountPercentOfBid"
									control={control}
									updateOnBlur
								/>
							)}
						</FormItem>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.contractAmount}
							id="contractAmount"
						>
							<FormItemLabel marker>Contract Amount</FormItemLabel>
							<FormCurrencyInput name="bond.contractAmount" control={control} />
						</FormItem>
					</FormRow>
					<FormRow
						schemaInclude={
							request.draft.schema.bond?.include.bidDate ||
							request.draft.schema.bond?.include.finalBondType
						}
					>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.finalBondType}
							id={"finalBondType"}
						>
							<FormItemLabel marker>Final Bond Form</FormItemLabel>
							<FormDropdown
								name="bond.finalBondType"
								control={control}
								options={[
									{ label: "Performance and Payment", value: "pnp" },
									{ label: "Performance", value: "performance" },
									{ label: "Payment", value: "payment" },
								]}
								placeholder="Select one"
							/>
						</FormItem>
					</FormRow>
				</FormItemGroup>
				<FormSchemaInclude
					schemaInclude={request.draft.schema.bond?.include.bondForm !== undefined}
				>
					<BondFormSelect
						marker
						value={bondFormController.field.value}
						onChange={bondFormController.field.onChange}
						onBlur={bondFormController.field.onBlur}
						onDownload={(fileId) =>
							getFileUrl({
								baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
								queryKey: [`downloadQuoteDraftFile`, fileId, true],
								asDownload: true,
							})
						}
						bondFormTemplates={props.bondFormTemplates}
						allowedBondFormUploadTypesAndLabels={allowedBondFormUploadTypesAndLabels}
						id="form"
						error={control.getFieldState("bond.bondForm").error}
					/>
				</FormSchemaInclude>
				<FormSection
					header="Project"
					contentClassName="space-y-[35px] mt-[25px]"
					schemaInclude={request.draft.schema.bond?.include.project}
				>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.project?.scopeOfWork}
						id="scopeOfWork"
					>
						<FormQuestionLabel marker>What type of work will be performed?</FormQuestionLabel>
						<FormNAICSCodeSelect
							name="bond.project.scopeOfWork"
							control={control}
							selectText="Select scopes of work"
							editText="Edit scopes of work"
							title="Select Scopes of Work"
							searchPlaceholder="Search scopes of work..."
							subsetFilters={[{ label: "Account specialties", subset: accountNaicsCodes }]}
							defaultSubsetIndex={accountNaicsCodes.length > 0 ? 0 : undefined}
							allNAICSCodesLabel="All scopes of work"
						/>
					</FormItem>
					<FormItemGroup>
						<FormRow schemaInclude={request.draft.schema.bond?.include.project?.description}>
							<FormItem
								schemaInclude={request.draft.schema.bond?.include.project?.description}
								id="description"
							>
								<FormItemLabel marker>Project Description</FormItemLabel>
								<FormInput name="bond.project.description" control={control} />
							</FormItem>
						</FormRow>
						<FormRow
							schemaInclude={
								request.draft.schema.bond?.include.project?.address?.city ||
								request.draft.schema.bond?.include.project?.address?.state
							}
						>
							<FormItem
								schemaInclude={request.draft.schema.bond?.include.project?.address?.city}
								id="city"
							>
								<FormItemLabel marker>Project City</FormItemLabel>
								<FormInput name="bond.project.address.city" control={control} />
							</FormItem>
							<FormItem
								schemaInclude={request.draft.schema.bond?.include.project?.address?.state}
								id="state"
							>
								<FormItemLabel marker>Project State</FormItemLabel>
								<FormDropdown
									name="bond.project.address.state"
									control={control}
									placeholder="Select one"
									options={usStateOrTerritoryOptions}
									optionsClassName="max-h-[230px] overflow-y-auto"
								/>
							</FormItem>
						</FormRow>
					</FormItemGroup>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.tListingRequired}
						id="tListingRequired"
					>
						<FormQuestionLabel marker>Does this bond require a Treasury Listing?</FormQuestionLabel>
						<FormYesNo name="bond.tListingRequired" control={control} />
					</FormItem>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.completionBond}
						id="completionBond"
					>
						<FormQuestionLabel marker>
							Is the principal a developer and/or is this a completion bond?
							<Tooltip
								id="completionBond"
								content="Completion bonds are performance guarantees where the Principal is responsible for financing the project. They include site bonds, subdivision bonds, right-of-way bonds, and takeover contracts."
								className="ml-[5px]"
							/>
						</FormQuestionLabel>
						<FormYesNo name="bond.completionBond" control={control} />
					</FormItem>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.project?.monthsToComplete}
						id="monthsToComplete"
					>
						<FormQuestionLabel marker>
							How many months will it take to complete the project?
						</FormQuestionLabel>
						<FormInput
							name="bond.project.monthsToComplete"
							control={control}
							type="numeric"
							min={1}
						/>
					</FormItem>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.project?.monthsToComplete}
						id="monthsToComplete"
					>
						<FormQuestionLabel marker>
							How many months does the contract's warranty period last?
						</FormQuestionLabel>
						<FormInput
							name="bond.project.contractWarrantyMonths"
							control={control}
							type="numeric"
							min={0}
						/>
					</FormItem>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.project?.contractDamages}
						id="contractDamages"
					>
						<FormQuestionLabel marker subLabel="Check all that apply">
							Does the contract include any of the following damages?
							<Tooltip
								id="damages"
								content="Damages are usually described in the contract but may only be described in the prime contract. Note: AIA contracts usually describe damages in Section 4.5 (A101, A503)."
								className="ml-[5px]"
							/>
						</FormQuestionLabel>
						<FormCheckboxGroup
							name="bond.project.contractDamages"
							control={control}
							options={contractDamageOptions}
							className="bg-gray-50 border border-gray-300"
						/>
					</FormItem>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.project?.contractHazards}
						id="contractHazards"
					>
						<FormQuestionLabel marker subLabel="Check all that apply">
							Do any of the following apply to the contract?
							<Tooltip
								id="hazards"
								content="If these attributes are present, they will be described in the contract."
								className="ml-[5px]"
							/>
						</FormQuestionLabel>
						<FormCheckboxGroup
							name="bond.project.contractHazards"
							control={control}
							options={contractHazardOptions}
							className="bg-gray-50 border border-gray-300"
						/>
					</FormItem>
					<FormItemGroup>
						{bondType === "bid" && (
							<FormSection
								topPadding
								header="RFP / Bid Package"
								marker
								subHeader="Please upload a copy of the RFP / Bid Package"
								schemaInclude={request.draft.schema.bond?.include.project?.files !== undefined}
								id="rfp_bid_package"
							>
								<FileUpload
									value={filesController.field.value}
									onChange={filesController.field.onChange}
									onBlur={filesController.field.onBlur}
									onDownload={(fileId) =>
										getFileUrl({
											baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
											queryKey: [`downloadQuoteDraftFile`, fileId, true],
											asDownload: true,
										})
									}
									allowedTypesAndLabels={{ [FileType.bid_package]: "RFP / Bid Package" }}
									requiredTypes={[FileType.bid_package]}
									maxTypesPerFile={1}
									maxFiles={1}
									showErrors={formState.isSubmitted}
									hideChecklist
									className="w-[450px]"
								/>
							</FormSection>
						)}
						{bondType === "final" && (
							<FormSection
								topPadding
								header="Contract"
								marker
								subHeader="Please upload a copy of the contract"
								schemaInclude={request.draft.schema.bond?.include.project?.files !== undefined}
								id="contract"
							>
								<FileUpload
									value={filesController.field.value}
									onChange={filesController.field.onChange}
									onBlur={filesController.field.onBlur}
									onDownload={(fileId) =>
										getFileUrl({
											baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
											queryKey: [`downloadQuoteDraftFile`, fileId, true],
											asDownload: true,
										})
									}
									allowedTypesAndLabels={{ [FileType.contract]: "Contract" }}
									requiredTypes={[FileType.contract]}
									maxTypesPerFile={1}
									maxFiles={1}
									showErrors={formState.isSubmitted}
									hideChecklist
									className="w-[450px]"
								/>
							</FormSection>
						)}
					</FormItemGroup>
				</FormSection>
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};
