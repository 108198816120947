import {
	AttachmentFile,
	AttachmentFileType,
	defaultAttachmentTypeLabelMap,
} from "@inrev/inrev-common";
import { ReactNode } from "react";
import { useGetFileUrl } from "../../api";
import { FileUploadItem } from "../ui/FileUploadItem";
import { AttachmentUpload, AttachmentUploadProps } from "../ui/Upload";

type AttachmentsListSectionProps<
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = {
	attachments: AttachmentFile<TFileType>[];
	upload?: Omit<
		AttachmentUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
		"value"
	>;
	download?: {
		baseUrl: string;
		baseQueryKey: string;
	};
	typeLabelMap: Partial<typeof defaultAttachmentTypeLabelMap>;
	preventDelete?: boolean;
	subHeader?: ReactNode;
};

export const AttachmentsListSection = <
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>({
	attachments,
	upload,
	download,
	typeLabelMap,
	preventDelete,
	subHeader,
}: AttachmentsListSectionProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>) => {
	const { getFileUrl } = useGetFileUrl();

	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">Attachments</span>
			</div>
			{subHeader && subHeader}
			{(() => {
				if (upload !== undefined) {
					return (
						<AttachmentUpload
							{...upload}
							value={attachments}
							preventDelete={preventDelete}
							onDownload={(attachmentId) =>
								!!download &&
								getFileUrl({
									baseUrl: `${download.baseUrl}/${attachmentId}`,
									queryKey: [download.baseQueryKey, attachmentId, true],
									asDownload: true,
								})
							}
						/>
					);
				} else {
					if (attachments.length) {
						return (
							<div className="flex flex-col space-y-[10px]">
								{attachments.map((attachment) => (
									<FileUploadItem
										key={attachment.id}
										id={attachment.id}
										name={attachment.file.name}
										types={attachment.file.types}
										extension={attachment.file.extension}
										typeLabelMap={typeLabelMap}
										onDownload={(attachmentId) =>
											!!download &&
											getFileUrl({
												baseUrl: `${download.baseUrl}/${attachmentId}`,
												queryKey: [download.baseQueryKey, attachmentId, true],
												asDownload: true,
											})
										}
										preventDelete
									/>
								))}
							</div>
						);
					} else {
						return (
							<span className="text-[15px] text-gray-600 font-normal italic">No attachments</span>
						);
					}
				}
			})()}
		</div>
	);
};
