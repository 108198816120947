import { formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { KPICard } from "../../../components/ui/KPICard";
import { AdminBondRequest } from "../../../domain/admin/request/types";
import { carrierDisplayNameMap } from "../../../domain/common";

type AdminBondRequestTermsProps = {
	request: Exclude<AdminBondRequest, { status: "draft" }>;
};

export const AdminBondRequestTerms = ({ request }: AdminBondRequestTermsProps) => {
	if (request.suretyType !== "contract") return <></>;
	return (
		<div className="flex flex-col w-full min-h-fit !mt-[0px]">
			<div
				className="w-full h-[1.5px] bg-center"
				style={{
					backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='1' viewBox='0 0 10 1' fill='none'%3E%3Cline x1='0' y1='1' x2='10' y2='0' stroke-width='1.5' stroke='%23E5E7EB' stroke-dasharray='4 6'/%3E%3C/svg%3E")`,
				}}
			></div>
			<div className="flex flex-col space-y-[40px] w-full h-fit px-[40px] py-[40px] pt-[25px]">
				<div className="flex flex-col space-y-[25px]">
					<div className="font-medium text-[16px] text-gray-900">Terms</div>
					{!!request.carrier?.name && (
						<div className="flex space-x-[20px] items-center bg-gray-100 rounded-md px-[20px] py-[15px] w-fit">
							<span className="flex-1 text-[14px] text-gray-800 font-medium">Carrier</span>
							<span className="text-[17px] text-gray-600">
								{carrierDisplayNameMap[request.carrier.name] ?? ""}
							</span>
						</div>
					)}
					<div className="flex space-x-[20px]">
						{request.contractSuretyType !== "bid" && (
							<>
								<KPICard
									title="Rate"
									value={formatToPercent(request.rate)}
									className="w-[160px] h-[120px]"
								/>
								<KPICard
									title="Premium"
									value={formatToDollar(request.premium)}
									className="w-[160px] h-[120px] flex-1"
								/>
								<KPICard
									title="Commission"
									value={formatToPercent(request.agencyCommissionPercentage)}
									className="w-[160px] h-[120px]"
								/>
							</>
						)}
						{request.contractSuretyType === "bid" && (
							<>
								<KPICard
									title="Final Bond Rate"
									value={formatToPercent(request.finalBondContingentRate)}
									className="w-[160px] h-[120px]"
								/>
								<KPICard
									title="Final Bond Premium"
									value={formatToDollar(request.finalBondContingentPremium)!}
									className="w-[160px] h-[120px] flex-1"
								/>
								<KPICard
									title="Commission"
									value={formatToPercent(request.agencyCommissionPercentage)}
									className="w-[160px] h-[120px]"
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
