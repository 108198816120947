import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, SuretyType, type Types } from "@inrev/inrev-common";
import { equals, paths } from "rambda";
import { useEffect, useMemo, useState } from "react";
import {
	type FieldPath,
	FormProvider,
	type Resolver,
	useForm,
	useFormContext,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { Spinner } from "../../../../components/ui/Spinner";
import { SpinnerCheck } from "../../../../components/ui/SpinnerCheck";
import { useAdminReUnderwriteSuretyAccount } from "../../../../domain/admin/account/api";
import type {
	AdminSuretyAccount,
	UseAdminReUnderwriteSuretyAccountReturn,
} from "../../../../domain/admin/account/types";
import { useIsFirstRender } from "../../../../utils";
import type { SuretyAccountDraftSectionName } from "../../../agent/account/status/draft/AccountDraftView";
import { getSectionsFromDraft } from "../../../agent/account/status/draft/AccountDraftView";
import { AdminAccountReUnderwriteDraftDetailsSection } from "./sections/AdminAccountReUnderwriteDraftDetailsSection";
import { AdminAccountReUnderwriteFinancialsSection } from "./sections/AdminAccountReUnderwriteFinancialsSection";
import { AdminAccountReUnderwriteHistorySection } from "./sections/AdminAccountReUnderwriteHistorySection";
import { AdminAccountReUnderwriteSummarySection } from "./sections/AdminAccountReUnderwriteSummarySection";

type _AdminAccountReUnderwriteFormProps = Pick<
	UseAdminReUnderwriteSuretyAccountReturn,
	"reUnderwrite"
> & {
	draft: NonNullable<UseAdminReUnderwriteSuretyAccountReturn["draft"]>;
	account: AdminSuretyAccount;
};

const _AdminAccountReUnderwriteForm = ({
	draft,
	account,
	reUnderwrite,
}: _AdminAccountReUnderwriteFormProps) => {
	const formMethods = useFormContext<
		Types.SuretyAccount.Draft.Contract.Data,
		{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema },
		Dtos.Admin.SuretyAccount.Contract.Submit.Request.Existing["data"]
	>();
	const isFirstRender = useIsFirstRender();
	const [section, setSection] = useState<SuretyAccountDraftSectionName | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentSection = useMemo(
		() => searchParams.get("section") as SuretyAccountDraftSectionName | null,
		[searchParams],
	);
	const sections = useMemo(
		() => getSectionsFromDraft(draft.schema, formMethods.formState.errors),
		[draft.schema, formMethods.formState],
	);

	const onSubmit = (data: Dtos.Admin.SuretyAccount.Contract.Submit.Request.Existing["data"]) => {
		reUnderwrite(data);
	};

	useEffect(() => {
		if (!isFirstRender) {
			formMethods.reset(draft.data, {
				keepIsSubmitted: true,
				keepSubmitCount: true,
			});
			if (formMethods.formState.isSubmitted) {
				formMethods.trigger();
			}
		}
	}, [draft.data]);

	useEffect(() => {
		setSection(currentSection);
		if (currentSection === null || !sections.some((section) => section.name === currentSection)) {
			setSearchParams({ section: sections[0].name }, { replace: true });
		}
	}, [currentSection]);

	return (
		<form
			id="bond_request"
			onSubmit={formMethods.handleSubmit(onSubmit)}
			className="w-full min-w-fit h-full flex-1 flex justify-center"
		>
			{section === "details" && (
				<AdminAccountReUnderwriteDraftDetailsSection
					account={account}
					draft={draft}
					sections={sections}
				/>
			)}
			{section === "financials" && (
				<AdminAccountReUnderwriteFinancialsSection
					account={account}
					draft={draft}
					sections={sections}
				/>
			)}
			{section === "history" && (
				<AdminAccountReUnderwriteHistorySection
					account={account}
					draft={draft}
					sections={sections}
				/>
			)}
			{section === "summary" && (
				<AdminAccountReUnderwriteSummarySection
					account={account}
					draft={draft}
					sections={sections}
				/>
			)}
		</form>
	);
};

type _AdminAccountReUnderwriteModalProps = Pick<
	UseAdminReUnderwriteSuretyAccountReturn,
	"draftLoading" | "updateDraft" | "reUnderwrite" | "updateDraftLoading" | "reUnderwriteLoading"
> & {
	draft: NonNullable<UseAdminReUnderwriteSuretyAccountReturn["draft"]>;
	account: AdminSuretyAccount;
	onClose: () => void;
};

const fieldPathsToAwait: FieldPath<Types.SuretyAccount.Draft.Contract.Data>[] = [
	"details.exposureSize",
	"financials.statementDate",
	"financials.preparationMethod",
];

const resolver: Resolver<
	Types.SuretyAccount.Draft.Contract.Data,
	{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema }
> = async (values, context, options) => {
	if (!context) throw new Error();
	return zodResolver(
		Dtos.Admin.SuretyAccount.Contract.Submit.Request.Existing.Data.buildDataValidationSchema(
			context.draftSchema,
		),
	)(values, context.draftSchema, options);
};

const _AdminAccountReUnderwriteModal = ({
	account,
	draft,
	onClose,
	updateDraft,
	updateDraftLoading,
	reUnderwrite,
	reUnderwriteLoading,
}: _AdminAccountReUnderwriteModalProps) => {
	const formMethods = useForm<
		Types.SuretyAccount.Draft.Contract.Data,
		{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema },
		Dtos.Admin.SuretyAccount.Contract.Submit.Request.Existing["data"]
	>({
		reValidateMode: "onBlur",
		defaultValues: draft.data,
		context: { draftSchema: draft.schema },
		resolver,
	});
	const fieldsToAwait = fieldPathsToAwait.map((path) => formMethods.watch(path));

	const _onClose = () => {
		updateDraft(formMethods.getValues());
		onClose();
	};

	useEffect(() => {
		if (!equals(paths(fieldPathsToAwait, formMethods.formState.defaultValues), fieldsToAwait)) {
			updateDraft(formMethods.getValues());
		}
	}, fieldsToAwait);

	return (
		<Modal onClickOutside={_onClose}>
			<ModalItemWithHeader
				header={`Run Model for ${account.displayName}`}
				onClose={_onClose}
				className="w-[95vw] h-[95vh]"
			>
				<FormProvider {...formMethods}>
					<_AdminAccountReUnderwriteForm
						draft={draft}
						account={account}
						reUnderwrite={reUnderwrite}
					/>
				</FormProvider>
				{updateDraftLoading && <LoadingModal />}
				{reUnderwriteLoading && (
					<Modal>
						<div
							className={
								"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
							}
						>
							<div className="flex items-center space-x-[10px]">
								<SpinnerCheck spinning={true} />
								<div className="flex-1 text-gray-800">Underwriting</div>
							</div>
						</div>
					</Modal>
				)}
			</ModalItemWithHeader>
		</Modal>
	);
};

type AdminAccountReUnderwriteModalProps = {
	account: AdminSuretyAccount;
	onClose: () => void;
};

export const AdminAccountReUnderwriteModal = ({
	account,
	onClose,
}: AdminAccountReUnderwriteModalProps) => {
	const {
		draft,
		draftLoading,
		draftError,
		updateDraft,
		updateDraftLoading,
		reUnderwrite,
		reUnderwriteLoading,
		reUnderwriteSuccess,
		resetDraftQuery,
	} = useAdminReUnderwriteSuretyAccount(account.id, SuretyType.contract);
	const [_searchParams, setSearchParams] = useSearchParams();

	const _onClose = () => {
		setSearchParams({});
		onClose();
	};

	useEffect(() => {
		if (reUnderwriteSuccess) {
			resetDraftQuery();
			_onClose();
		}
	}, [reUnderwriteSuccess]);

	if (draft === undefined || draftLoading) {
		return (
			<div className="flex justify-center items-center h-full w-full">
				<Spinner />
			</div>
		);
	}
	if (draftError) {
		return (
			<div className="flex justify-center items-center h-full w-full">{draftError as any}</div>
		);
	}
	return (
		<_AdminAccountReUnderwriteModal
			account={account}
			draft={draft}
			draftLoading={draftLoading}
			updateDraft={updateDraft}
			updateDraftLoading={updateDraftLoading}
			reUnderwrite={reUnderwrite}
			reUnderwriteLoading={reUnderwriteLoading}
			onClose={_onClose}
		/>
	);
};
