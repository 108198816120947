import { zodResolver } from "@hookform/resolvers/zod";
import {
	BidSuretyBondClosedReason,
	ContractSuretyType,
	Dtos,
	FinalContractSuretyBondClosedReason,
	SuretyBondId,
	SuretyBondType,
	SuretyType,
} from "@inrev/inrev-common";
import { ReactNode, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { match } from "ts-pattern";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { Button } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { InfoCard } from "../../../../components/ui/InfoCard";
import { FormCurrencyInput } from "../../../../components/ui/form/FormCurrencyInput";
import { FormDatePicker } from "../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormTextArea } from "../../../../components/ui/form/FormTextArea";
import { useCloseBond, useRequestCloseBond } from "../../../../domain/agent/bond/api";
import { bondTypeLabelMap } from "../../../../types";
import { stripEmptyResolver } from "../../../../utils/form";

type CloseBondModalProps = {
	bond: {
		id: SuretyBondId;
		suretyType: SuretyType;
		contractSuretyType: ContractSuretyType;
		bondType: SuretyBondType;
		amount: number;
		number: string;
	};
	button: ReactNode;
};

type CloseBondFormData = {
	suretyType: SuretyType;
	contractSuretyType: ContractSuretyType;
	closedReason: FinalContractSuretyBondClosedReason | BidSuretyBondClosedReason | "";
	closedNote: string | "";
	actualCompletionDate: string | "";
	actualContractAmount: string | "";
};

const getDefaultFormData = (bond: CloseBondModalProps["bond"]): CloseBondFormData => ({
	suretyType: bond.suretyType,
	contractSuretyType: bond.contractSuretyType,
	closedReason: "",
	closedNote: "",
	actualCompletionDate: "",
	actualContractAmount: "",
});

const getClosedReasonOptions = (suretyBondType: SuretyBondType) => {
	return match(suretyBondType as SuretyBondType)
		.with(SuretyBondType.bid, () => [
			{ value: BidSuretyBondClosedReason.bid_lost, label: "Bid lost" },
			{ value: BidSuretyBondClosedReason.not_awarded, label: "Project was not awarded" },
			{
				value: FinalContractSuretyBondClosedReason.returned_by_obligee,
				label: "Returned by obligee",
			},
			{ value: BidSuretyBondClosedReason.other, label: "Other" },
		])
		.with(SuretyBondType.final, () => [
			{ value: FinalContractSuretyBondClosedReason.project_completed, label: "Project completed" },
			{
				value: FinalContractSuretyBondClosedReason.returned_by_obligee,
				label: "Returned by obligee",
			},
			{ value: FinalContractSuretyBondClosedReason.other, label: "Other" },
		])
		.otherwise(() => []);
};

export const CloseBondModal = ({ bond, button }: CloseBondModalProps) => {
	const { closeBond, closeBondLoading } = useCloseBond(bond.id);
	const { requestCloseBond, requestCloseBondLoading } = useRequestCloseBond(bond.id);
	const [isOpen, setIsOpen] = useState(false);
	const formMethods = useForm<CloseBondFormData, any, Dtos.SuretyBond.Close.Request>({
		defaultValues: getDefaultFormData(bond),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.SuretyBond.Close.Request.schema)),
	});
	const closedReasonOtions = useMemo(() => getClosedReasonOptions(bond.bondType), [bond.bondType]);
	const closedReason = formMethods.watch("closedReason");
	const canCloseFinalBond = useMemo(
		() => bond.bondType !== "final" || bond.amount <= 1000000,
		[bond.bondType, bond.amount],
	);
	const isCloseRequest = () => {
		return closedReason === "other" || !canCloseFinalBond;
	};

	const handleSubmit = (data: Dtos.SuretyBond.Close.Request) => {
		if (isCloseRequest()) {
			requestCloseBond(data);
		} else {
			closeBond(data);
		}
		setIsOpen(false);
	};

	return (
		<>
			<span onClick={() => setIsOpen(true)}>{button}</span>
			{isOpen && (
				<Modal onClickOutside={() => setIsOpen(false)}>
					<ModalItemWithHeader
						header={`Close ${bondTypeLabelMap[bond.bondType]} Bond ${bond.number}`}
						className="w-[500px]"
						bodyClassName="p-[30px] pt-[26px]"
						onClose={() => setIsOpen(false)}
					>
						<form>
							<FormItemGroup>
								{bond.bondType === "final" && bond.amount > 1000000 && (
									<InfoCard>
										The inRev Agent Portal does not yet support automatically closing Final Bonds
										over $1,000,000. An inRev underwriter will close the bond after reviewing your
										request.
									</InfoCard>
								)}
								<FormItem>
									<FormItemLabel condensed>Reason for Closing</FormItemLabel>
									<FormDropdown
										control={formMethods.control}
										name="closedReason"
										options={closedReasonOtions}
										placeholder="Select one"
										condensed
									/>
								</FormItem>
								{closedReason !== "" && (
									<>
										{closedReason === FinalContractSuretyBondClosedReason.project_completed && (
											<>
												<FormItem>
													<FormItemLabel condensed>Project Completion Date</FormItemLabel>
													<FormDatePicker
														control={formMethods.control}
														name="actualCompletionDate"
														condensed
													/>
												</FormItem>
												<FormItem>
													<FormItemLabel condensed>
														Contract Amount at Project Completion
													</FormItemLabel>
													<FormCurrencyInput
														control={formMethods.control}
														name="actualContractAmount"
														condensed
													/>
												</FormItem>
											</>
										)}
										{(closedReason === "returned_by_obligee" || closedReason === "other") && (
											<>
												{canCloseFinalBond && closedReason === "other" && (
													<InfoCard>
														When selecting "Other", the bond will not be closed immediately. An
														inRev underwriter will close the bond after reviewing your request.
													</InfoCard>
												)}
												<FormItem>
													{closedReason === "returned_by_obligee" && (
														<FormItemLabel condensed>
															Please describe why the bond was returned by the obligee
														</FormItemLabel>
													)}
													{closedReason === "other" && (
														<FormItemLabel condensed>
															Please describe the reason for closing the bond
														</FormItemLabel>
													)}
													<FormTextArea
														control={formMethods.control}
														name="closedNote"
														placeholder="Enter description here"
														className="h-[100px]"
													/>
												</FormItem>
											</>
										)}
									</>
								)}
							</FormItemGroup>
							<div className="flex justify-end mt-[35px] space-x-[10px]">
								<Button
									onClick={() => setIsOpen(false)}
									color="gray"
									className="w-[80px]"
									filled
									thinFont
								>
									Cancel
								</Button>
								<Button
									color="light-blue"
									className="w-[80px]"
									filled
									thinFont
									onClick={formMethods.handleSubmit(handleSubmit)}
								>
									<div className="flex items-center space-x-[8px]">
										<Icon
											type="shield-slash"
											className="stroke-[1] text-white h-[14px]"
											width={13}
											height={17}
										/>
										{!isCloseRequest() && <span>Close Bond</span>}
										{isCloseRequest() && <span>Submit Close Request</span>}
									</div>
								</Button>
							</div>
						</form>
					</ModalItemWithHeader>
				</Modal>
			)}
			{(closeBondLoading || requestCloseBondLoading) && !isOpen && <LoadingModal />}
		</>
	);
};
