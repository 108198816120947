import { Types } from "@inrev/inrev-common";
import { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { HiPlus } from "react-icons/hi2";
import { HiOutlinePencilSquare, HiOutlineTrash, HiOutlineUser } from "react-icons/hi2";
import { useCustomCompareEffect } from "use-custom-compare";
import { ConfirmationModalClickWrapper } from "../../../../../../../components/layout/ConfirmationModalClickWrapper";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { FormItem } from "../../../../../../../components/layout/form/FormItem";
import { FormSection } from "../../../../../../../components/layout/form/FormSection";
import { cn } from "../../../../../../../components/lib/utils";
import { Button } from "../../../../../../../components/ui/Button";
import { Checkbox } from "../../../../../../../components/ui/Checkbox";
import { InlineLink } from "../../../../../../../components/ui/InlineLink";
import { FormErrorCard } from "../../../../../../../components/ui/form/FormErrorCard";
import { FormQuestionLabel } from "../../../../../../../components/ui/form/FormQuestionLabel";
import { formatName, useIsFirstRender, useScrollToId } from "../../../../../../../utils";
import { AccountExposureSizeSelect } from "../../../../shared/AccountExposureSizeSelect";
import { SuretyAccountDraftContext } from "../../AccountDraftView";
import { AccountDraftCompanyModal } from "./AccountDraftCompanyModal";
import { AccountDraftIndividualModal } from "./AccountDraftNewIndividualModal";

type AccountDraftDetailsSectionProps = {
	sections: { name: string; label: string }[];
};

export const AccountDraftDetailsSection = (props: AccountDraftDetailsSectionProps) => {
	const domReadyRef = useScrollToId();
	const isFirstRender = useIsFirstRender();
	const account = useContext(SuretyAccountDraftContext);
	const detailsSchema = account.draft.schema.details;
	const { watch, formState, control, trigger } = useFormContext<Types.SuretyAccount.Draft.Data>();
	const companiesFieldArray = useFieldArray({
		control,
		name: "details.companies",
		keyName: "draftId",
	});
	const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number | undefined>();
	const [companyEditorOpen, setCompanyEditorOpen] = useState<boolean>(false);
	const companies = watch("details.companies");
	const individualsFieldArray = useFieldArray({
		control,
		name: "details.individuals",
		keyName: "draftId",
	});
	const [selectedIndividualIndex, setSelectedIndividualIndex] = useState<number | undefined>();
	const [individualEditorOpen, setIndividualEditorOpen] = useState<boolean>(false);
	const individuals = watch("details.individuals");
	const owners = individuals.filter((individual) => individual.type === "owner");
	const creditReportPermission = watch("details.creditReportPermission");
	const creditReportPermissionFieldArray = useFieldArray({
		control,
		name: "details.creditReportPermission",
		keyName: "id",
	});
	const individualsContainerRef = useRef<HTMLDivElement>(null);

	const handleDeleteCompany = (companyIndex: number) => {
		companiesFieldArray.remove(companyIndex);
	};

	const handleDeleteIndividual = (
		individualIndex: number,
		spouse?: Types.SuretyAccount.Draft.Contract.Data.Details.Individual,
	) => {
		const individualCreditReportPermissionIndex = creditReportPermission.findIndex(
			(permission) => permission.id === individuals[individualIndex].draftId,
		);
		if (individualCreditReportPermissionIndex === -1) throw new Error();

		if (spouse) {
			const spouseIndex = individuals.findIndex(
				(individual) => individual.draftId === spouse.draftId,
			);
			if (spouseIndex === -1) throw new Error();

			if (spouse.type === "owner") {
				individualsFieldArray.update(spouseIndex, { ...spouse, spouseDraftId: "", married: false });
				creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
				individualsFieldArray.remove(individualIndex);
			} else {
				creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
				individualsFieldArray.remove([individualIndex, spouseIndex]);
			}
		} else {
			creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
			individualsFieldArray.remove(individualIndex);
		}
	};

	useCustomCompareEffect(
		() => {
			if (!isFirstRender && !!owners.length && individualsContainerRef.current) {
				document
					.getElementById("individuals")
					?.scrollIntoView({ block: "center", behavior: "smooth" });
			}
		},
		[{ length: owners.length }],
		(prev, next) => prev.length >= next.length,
	);

	useEffect(() => {
		if (formState.isSubmitted) {
			trigger();
		}
	}, [companies, individuals, creditReportPermission]);

	useEffect(() => {
		if (selectedCompanyIndex !== undefined) setCompanyEditorOpen(true);
	}, [selectedCompanyIndex]);

	useEffect(() => {
		if (!companyEditorOpen) setSelectedCompanyIndex(undefined);
	}, [companyEditorOpen]);

	useEffect(() => {
		if (selectedIndividualIndex !== undefined) setIndividualEditorOpen(true);
	}, [selectedIndividualIndex]);

	useEffect(() => {
		if (!individualEditorOpen) setSelectedIndividualIndex(undefined);
	}, [individualEditorOpen]);

	if (!detailsSchema) return null;
	return (
		<ShrinkingHeaderSectionNavLayout headerTitle={"New Account Details"} sections={props.sections}>
			<div
				ref={domReadyRef}
				className="w-full min-h-fit flex flex-col space-y-[45px] pt-[18px] pb-[125px]"
			>
				<FormErrorCard
					error={formState.errors.details?.companies}
					className="!mt-[60px] !mb-[-25px]"
				/>
				<FormSection
					topPadding
					header="Companies"
					marker
					subHeader={
						<span className={cn(formState.errors.details?.companies ? "text-red-500" : undefined)}>
							Please list all companies in this account
						</span>
					}
					id="companies"
					schemaInclude={detailsSchema.include.companies}
				>
					{companies.length ? (
						<div className="w-full flex flex-col space-y-[25px] ml-[-5px] mb-[25px]">
							{companies.map((company, companyIndex) => (
								<div
									key={company.draftId}
									className="w-full flex flex-col space-y-[10px] last:pb-[5px]"
								>
									<div
										key={company.draftId}
										className="w-full h-[50px] flex items-center space-x-[15px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm cursor-pointer"
										onClick={() => setSelectedCompanyIndex(companyIndex)}
									>
										<HiOutlineUser className="text-[18px] text-gray-800" />
										<div className="flex-1 font-medium text-gray-900">{company.name}</div>
										<div className="flex items-center space-x-[5px]">
											<div className="p-[5px] hover:bg-gray-100 rounded">
												<HiOutlinePencilSquare className="text-[19px] text-gray-600" />
											</div>
											<div
												className="p-[5px] hover:bg-gray-100 rounded"
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteCompany(companyIndex);
												}}
											>
												<HiOutlineTrash className="text-[19px] text-red-500" />
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : null}
					<Button
						onClick={() => setCompanyEditorOpen(true)}
						className="w-fit"
						color="gray"
						rounded
						filled
						thinFont
					>
						<div className="flex min-w-fit items-center space-x-[5px]">
							<HiPlus className="text-[18px] text-gray-800" />
							<div className="min-w-fit">Add Company</div>
						</div>
					</Button>
					{companyEditorOpen && (
						<AccountDraftCompanyModal
							companies={companies}
							fieldArray={companiesFieldArray}
							selectedIndex={selectedCompanyIndex}
							onClose={() => setCompanyEditorOpen(false)}
						/>
					)}
				</FormSection>
				<FormSection
					topPadding
					header="Individual Owners"
					marker
					subHeader={
						<span
							className={cn(
								formState.errors.details?.individuals !== undefined &&
									!Array.isArray(formState.errors.details.individuals)
									? "text-red-500"
									: undefined,
							)}
						>
							Please list all individuals that own at least 20% of one or more companies in this
							account
						</span>
					}
					id="individuals"
					schemaInclude={detailsSchema.include.individuals}
				>
					{individuals.length ? (
						<div className="w-full flex flex-col space-y-[25px] ml-[-5px] mb-[30px]">
							{owners.map((owner) => {
								const formattedOwnerName = formatName(owner);
								const ownerIndex = individuals.findIndex(
									(individual) => individual.draftId === owner.draftId,
								);
								if (ownerIndex === -1) throw new Error();
								const ownerCreditReportPermissionIndex = creditReportPermission.findIndex(
									(permission) => permission.id === owner.draftId,
								);
								const ownerCreditReportPermission =
									creditReportPermission[ownerCreditReportPermissionIndex];
								const spouse = individuals.find(
									(individual) => individual.spouseDraftId === owner.draftId,
								);

								return (
									<div
										key={owner.draftId}
										className="w-full flex flex-col space-y-[10px] last:pb-[5px]"
									>
										<div
											key={owner.draftId}
											className="w-full h-[50px] flex items-center space-x-[15px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm cursor-pointer"
											onClick={() => setSelectedIndividualIndex(ownerIndex)}
										>
											<HiOutlineUser className="text-[18px] text-gray-800" />
											<div className="flex-1 flex items-center space-x-[5px]">
												<div className="font-medium text-gray-900">{formattedOwnerName}</div>
												{spouse?.type === "spouse" && (
													<div className="text-gray-500">& {formatName(spouse)}</div>
												)}
											</div>
											<div className="flex items-center space-x-[5px]">
												<div className="p-[5px] hover:bg-gray-100 rounded">
													<HiOutlinePencilSquare className="text-[19px] text-gray-600" />
												</div>
												<div
													className="p-[5px] hover:bg-gray-100 rounded"
													onClick={(e) => {
														e.stopPropagation();
														handleDeleteIndividual(ownerIndex, spouse);
													}}
												>
													<HiOutlineTrash className="text-[19px] text-red-500" />
												</div>
											</div>
										</div>
										{ownerCreditReportPermissionIndex !== -1 && (
											<>
												<div className="flex space-x-[10px] ml-[10px]">
													<ConfirmationModalClickWrapper
														shouldConfirm={!ownerCreditReportPermission.permission}
														onConfirm={() =>
															creditReportPermissionFieldArray.update(
																ownerCreditReportPermissionIndex,
																{ ...ownerCreditReportPermission, permission: true },
															)
														}
														subMessage={
															<div className="max-w-[450px] h-full flex space-x-[15px] basis-0">
																<div className="w-[8px] min-h-full rounded bg-gray-200"></div>
																<div className="text-[14px] font-normal text-gray-600 leading-[23px] text-left">
																	You understand that by checking this box,{" "}
																	<span className="font-semibold">{formattedOwnerName}</span> is
																	providing "written instructions" to InRev under the
																	<br />
																	<InlineLink
																		to="https://www.ftc.gov/legal-library/browse/statutes/fair-credit-reporting-act"
																		newTab
																	>
																		Fair Credit Reporting Act
																	</InlineLink>{" "}
																	authorizing InRev to obtain personal credit report information or
																	other information from Experian.
																	<br />
																	<span className="font-semibold">{formattedOwnerName}</span>{" "}
																	authorizes InRev to obtain such information solely for
																	underwriting purposes.
																</div>
															</div>
														}
														confirmButtonText="I Understand"
														cancelButton={false}
													>
														<Checkbox
															className="mt-[1px]"
															checked={ownerCreditReportPermission.permission}
															onChange={() => {
																if (ownerCreditReportPermission.permission) {
																	creditReportPermissionFieldArray.update(
																		ownerCreditReportPermissionIndex,
																		{ ...ownerCreditReportPermission, permission: false },
																	);
																}
															}}
														/>
													</ConfirmationModalClickWrapper>
													<div className="text-[13px] text-gray-700">
														<span className="font-medium text-gray-800">
															{ownerCreditReportPermission.name}
														</span>{" "}
														authorizes InRev to obtain personal credit report information
													</div>
												</div>
												{formState.errors.details?.creditReportPermission?.[
													ownerCreditReportPermissionIndex
												] && (
													<div className="text-xs text-red-500 !mt-[5px] ml-[10px]">Required</div>
												)}
											</>
										)}
									</div>
								);
							})}
						</div>
					) : null}
					<Button
						onClick={() => setIndividualEditorOpen(true)}
						className="ml-[-5px] w-fit"
						color="gray"
						rounded
						filled
						thinFont
					>
						<div className="flex min-w-fit items-center space-x-[5px]">
							<HiPlus className="text-[20px] text-gray-800" />
							<div className="min-w-fit">Add Individual</div>
						</div>
					</Button>
					{individualEditorOpen && (
						<AccountDraftIndividualModal
							individuals={individuals}
							individualsFieldArray={individualsFieldArray}
							creditReportPermission={creditReportPermission}
							creditReportPermissionFieldArray={creditReportPermissionFieldArray}
							selectedIndex={selectedIndividualIndex}
							onClose={() => setIndividualEditorOpen(false)}
						/>
					)}
				</FormSection>
				<FormItem schemaInclude={detailsSchema.include.exposureSize}>
					<FormQuestionLabel marker className="pb-[5px]">
						Which best describes the total bonding need for all companies in this account over the
						next 12 months?
					</FormQuestionLabel>
					<AccountExposureSizeSelect name="details.exposureSize" control={control} />
				</FormItem>
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};
