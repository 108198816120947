import { ContractSuretyBondFormType } from "@inrev/inrev-common";
import { WorkflowLayout } from "../../../../../../components/layout/WorkflowLayout";
import { useFetchBond } from "../../../../../../domain/agent/bond/api";
import { BondFormTemplate, BondRequest } from "../../../../../../domain/agent/request/types";
import type { DraftBondRequestSectionName } from "../DraftBondRequestView";
import { DraftBidToFinalBondRequestBondSection } from "./sections/DraftBidToFinalBondRequestBondSection";
import { DraftBidToFinalBondRequestSummarySection } from "./sections/DraftBidToFinalBondRequestSummarySection";

type DraftBidToFinalBondRequestViewProps = {
	request: BondRequest & Extract<BondRequest, { status: "draft" }>;
	section: DraftBondRequestSectionName | null;
	sections: { name: string; label: string }[];
	bondFormTypesAndLabels: Record<ContractSuretyBondFormType, string>;
	bondFormTemplates: BondFormTemplate[];
	principalName?: string;
};

export const DraftBidToFinalBondRequestView = ({
	request,
	section,
	sections,
	bondFormTypesAndLabels,
	bondFormTemplates,
	principalName,
}: DraftBidToFinalBondRequestViewProps) => {
	const { bond: bidBond } = useFetchBond(request.draft.data.bond.bidBondId);

	if (!bidBond) return <></>;
	return (
		<WorkflowLayout
			title={
				<div className="flex items-center space-x-[5px]">
					<span>Final Bond Request from Bid Bond {bidBond.number}</span>
					{!!principalName && (
						<>
							<span className="text-gray-300">|</span>
							<span className="text-gray-500 text-[13px]">{principalName}</span>
						</>
					)}
				</div>
			}
		>
			{section === "bond" && (
				<DraftBidToFinalBondRequestBondSection
					bondFormTypesAndLabels={bondFormTypesAndLabels}
					bondFormTemplates={bondFormTemplates}
					sections={sections}
				/>
			)}
			{section === "summary" && (
				<DraftBidToFinalBondRequestSummarySection
					bondFormTypesAndLabels={bondFormTypesAndLabels}
					bondFormTemplates={bondFormTemplates}
					sections={sections}
				/>
			)}
		</WorkflowLayout>
	);
};
