import { Types, formatToDollar } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { Fragment, ReactElement, useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { SummaryItem, SummaryItemProps } from "../../../../../../components/layout/SummaryItem";
import { SummaryItemGroup } from "../../../../../../components/layout/SummaryItemGroup";
import { SummaryItemSection } from "../../../../../../components/layout/SummaryItemSection";
import {
	SummarySection,
	SummarySectionProps,
} from "../../../../../../components/layout/SummarySection";
import { Separator } from "../../../../../../components/ui/Separator";
import {
	exposureSizeOptions,
	financialsPreparationMethodOptions,
} from "../../../../../../constants";
import { boolToYN, formatAddress, formatName } from "../../../../../../utils";
import { NAICSCodesList } from "../../../../../admin/shared/NAICSCodeList";
import { SuretyAccountDraftContext, SuretyAccountDraftSectionName } from "../AccountDraftView";

type _AccountDraftSummarySectionProps = AccountDraftSummarySectionProps & {
	draft: Types.SuretyAccount.Draft.Type;
};

export const _AccountDraftSummarySection = ({
	sections,
	draft,
}: _AccountDraftSummarySectionProps) => {
	const { pathname } = useLocation();
	const schema = draft.schema;
	const { watch, formState } = useFormContext<Types.SuretyAccount.Draft.Data>();
	const detailsData = watch("details");
	const detailsSchema = schema.details;
	const companies = watch("details.companies");
	const exposureSizeLabel = exposureSizeOptions.find(
		(option) => option.value === detailsData.exposureSize,
	)?.label;
	const individuals = watch("details.individuals");
	const individualOwners = useMemo(
		() => individuals.filter((individual) => individual.type === "owner"),
		[individuals],
	);
	const creditReportPermission = watch("details.creditReportPermission");
	const historyData = watch("history");
	const financialsData = watch("financials");

	const summarySectionMap: Record<
		SuretyAccountDraftSectionName,
		ReactElement<SummarySectionProps> | null
	> = {
		details: (
			<SummarySection header="Principal" to={{ pathname, section: "details" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="12 Month Bonding Need"
						value={exposureSizeLabel}
						schemaInclude={Boolean(detailsSchema?.include.exposureSize)}
						error={formState.errors.details?.exposureSize}
						to={{ pathname, section: "details", id: "exposureSize" }}
					/>
				</SummaryItemGroup>
				<SummaryItemSection
					header="Companies"
					error={formState.errors.details?.companies}
					to={{ pathname, section: "details", id: "companies" }}
					schemaInclude={detailsSchema?.include.companies}
				>
					{!companies.length && <div className="text-[14px] font-normal text-gray-900">None</div>}
					{companies.map((company, index) => (
						<Fragment key={company.draftId}>
							<SummaryItemGroup key={company.draftId}>
								<SummaryItem
									label="Name"
									value={company.name}
									error={formState.errors.details?.companies?.[index]?.name}
									schemaInclude={Boolean(detailsSchema?.include.companies)}
									to={{ pathname, section: "details", id: "companies" }}
								/>
								<SummaryItem
									label="FEIN"
									value={company.fein}
									error={formState.errors.details?.companies?.[index]?.fein}
									schemaInclude={Boolean(detailsSchema?.include.companies)}
									to={{ pathname, section: "details", id: "companies" }}
								/>
								<SummaryItem
									label="Address"
									value={formatAddress(company.address)}
									error={formState.errors.details?.companies?.[index]?.address}
									schemaInclude={Boolean(detailsSchema?.include.companies)}
									to={{ pathname, section: "details", id: "companies" }}
								/>
							</SummaryItemGroup>
							{index !== detailsData.companies.length - 1 && (
								<Separator orientation="horizontal" className="bg-gray-200" />
							)}
						</Fragment>
					))}
				</SummaryItemSection>
				<SummaryItemSection
					header="Individuals"
					error={formState.errors.details?.individuals}
					to={{ pathname, section: "details", id: "individuals" }}
					schemaInclude={detailsSchema?.include.individuals}
				>
					{!detailsData.individuals.length && (
						<div className="text-[14px] font-normal text-gray-900">None</div>
					)}
					{individualOwners.map((owner, index) => (
						<Fragment key={owner.draftId}>
							<SummaryItemGroup key={owner.draftId}>
								<SummaryItem
									label="First Name"
									value={owner.firstName}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Middle Initial"
									value={owner.middleInitial}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Last Name"
									value={owner.lastName}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Suffix"
									value={owner.suffix}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Address"
									value={formatAddress(owner.address)}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Email"
									value={owner.email}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="SSN"
									value={owner.ssn}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								<SummaryItem
									label="Credit Report Permission"
									value={boolToYN(
										creditReportPermission.find((permission) => permission.id === owner.draftId)
											?.permission,
									)}
									error={
										formState.errors.details?.creditReportPermission?.[
											creditReportPermission.findIndex(
												(permission) => permission.id === owner.draftId,
											)
										]
									}
									schemaInclude={Boolean(detailsSchema?.include.individuals)}
									to={{ pathname, section: "details", id: "individuals" }}
								/>
								{owner.spouseDraftId ? (
									((): ReactElement<SummaryItemProps> => {
										const spouse = individuals.find(
											(individual) => individual.draftId === owner.spouseDraftId,
										);
										if (!spouse) throw new Error();
										if (spouse.type === "owner")
											return (
												<SummaryItem
													label="Spouse"
													value={formatName(spouse)}
													schemaInclude={Boolean(detailsSchema?.include.individuals)}
													to={{ pathname, section: "details", id: "individuals" }}
												/>
											);
										else
											return (
												<>
													<SummaryItem
														label="Spouse First Name"
														value={spouse.firstName}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Middle Initial"
														value={spouse.middleInitial}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Last Name"
														value={spouse.lastName}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Suffix"
														value={spouse.suffix}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Address"
														value={formatAddress(spouse.address)}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Email"
														value={spouse.email}
														schemaInclude={Boolean(detailsSchema?.include.individuals)}
														to={{ pathname, section: "details", id: "individuals" }}
													/>
												</>
											);
									})()
								) : (
									<></>
								)}
							</SummaryItemGroup>
							{index !== individualOwners.length - 1 && (
								<Separator orientation="horizontal" className="bg-gray-200" />
							)}
						</Fragment>
					))}
				</SummaryItemSection>
			</SummarySection>
		),
		history: (
			<SummarySection header="History" to={{ pathname, section: "history" }}>
				<SummaryItemGroup>
					<SummaryItem
						vertical
						label="What type of work does this account specialize in?"
						value={
							<NAICSCodesList
								naicsCodes={historyData.naicsCodes}
								emptyMessage="--"
								className="my-[8px] !bg-gray-100 bg-opacity-100"
								hideExpand
								showCount
							/>
						}
						error={formState.errors.history?.naicsCodes}
						schemaInclude={Boolean(draft.schema.history?.include.naicsCodes)}
						to={{ pathname, section: "history", id: "naicsCode" }}
					/>
					<SummaryItem
						vertical
						label="What is the value of the largest project completed by any company in this account?"
						value={formatToDollar(historyData.largestProjectCompleted)}
						error={formState.errors.history?.largestProjectCompleted}
						schemaInclude={Boolean(draft.schema.history?.include.largestProjectCompleted)}
						to={{ pathname, section: "history", id: "largestProjectCompleted" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever failed to complete a project?"
						value={boolToYN(historyData.failedToCompleteAProject)}
						error={formState.errors.history?.failedToCompleteAProject}
						schemaInclude={Boolean(draft.schema.history?.include.failedToCompleteAProject)}
						to={{ pathname, section: "history", id: "failedToCompleteAProject" }}
					/>
					<SummaryItem
						vertical
						label="In the past 3 years, has any company in this account experienced a gross loss on any project greater than 10% of that project's contract value?"
						value={boolToYN(historyData.constructionProjectGrossLoss)}
						error={formState.errors.history?.constructionProjectGrossLoss}
						schemaInclude={Boolean(draft.schema.history?.include.constructionProjectGrossLoss)}
						to={{ pathname, section: "history", id: "constructionProjectGrossLoss" }}
					/>
					<SummaryItem
						vertical
						label="Does any company in this account expect a gross loss on a project they are currently working on?"
						value={boolToYN(historyData.currentConstructionProjectGrossLoss)}
						error={formState.errors.history?.currentConstructionProjectGrossLoss}
						schemaInclude={Boolean(
							draft.schema.history?.include.currentConstructionProjectGrossLoss,
						)}
						to={{ pathname, section: "history", id: "currentConstructionProjectGrossLoss" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever lost a payment suit?"
						value={boolToYN(historyData.lostAPaymentSuit)}
						error={formState.errors.history?.lostAPaymentSuit}
						schemaInclude={Boolean(draft.schema.history?.include.lostAPaymentSuit)}
						to={{ pathname, section: "history", id: "lostAPaymentSuit" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever caused a loss to a surety?"
						value={boolToYN(historyData.causedSuretyLoss)}
						error={formState.errors.history?.causedSuretyLoss}
						schemaInclude={Boolean(draft.schema.history?.include.causedSuretyLoss)}
						to={{ pathname, section: "history", id: "causedSuretyLoss" }}
					/>
				</SummaryItemGroup>
			</SummarySection>
		),
		financials: (
			<SummarySection header="Financials" to={{ pathname, section: "financials" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="Statement Date"
						value={
							typeof financialsData.statementDate === "object"
								? DateTime.fromJSDate(financialsData.statementDate).toLocaleString(
										DateTime.DATE_FULL,
									)
								: financialsData.statementDate
						}
						error={formState.errors.financials?.statementDate}
						schemaInclude={Boolean(draft.schema.financials?.include.statementDate)}
						to={{ pathname, section: "financials", id: "statementDate" }}
					/>
					<SummaryItem
						label="Preparation Method"
						value={
							financialsPreparationMethodOptions.find(
								(option) => option.value === financialsData.preparationMethod,
							)?.label
						}
						error={formState.errors.financials?.preparationMethod}
						schemaInclude={Boolean(draft.schema.financials?.include.preparationMethod)}
						to={{ pathname, section: "financials", id: "preparationMethod" }}
					/>
				</SummaryItemGroup>
				<SummaryItemSection
					header="Balance Sheet"
					schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet)}
					to={{ pathname, section: "financials", id: "balanceSheet" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Cash"
							value={formatToDollar(financialsData.balanceSheet.corporateCash)}
							error={formState.errors.financials?.balanceSheet?.corporateCash}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.corporateCash)}
							to={{ pathname, section: "financials", id: "corporateCash" }}
						/>
						<SummaryItem
							label="Accounts Receivable (< 90 days)"
							value={formatToDollar(financialsData.balanceSheet.accountsReceivable)}
							error={formState.errors.financials?.balanceSheet?.accountsReceivable}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.accountsReceivable,
							)}
							to={{ pathname, section: "financials", id: "accountsReceivable" }}
						/>
						<SummaryItem
							label="Underbillings"
							value={formatToDollar(financialsData.balanceSheet.underbillings)}
							error={formState.errors.financials?.balanceSheet?.underbillings}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.underbillings)}
							to={{ pathname, section: "financials", id: "underbillings" }}
						/>
						<SummaryItem
							label="Current Assets"
							value={formatToDollar(financialsData.balanceSheet.currentAssets)}
							error={formState.errors.financials?.balanceSheet?.currentAssets}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.currentAssets)}
							to={{ pathname, section: "financials", id: "currentAssets" }}
						/>
						<SummaryItem
							label="Total Assets"
							value={formatToDollar(financialsData.balanceSheet.totalAssets)}
							error={formState.errors.financials?.balanceSheet?.totalAssets}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.totalAssets)}
							to={{ pathname, section: "financials", id: "totalAssets" }}
						/>
						<SummaryItem
							label="Accounts Payable"
							value={formatToDollar(financialsData.balanceSheet.accountsPayable)}
							error={formState.errors.financials?.balanceSheet?.accountsPayable}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.accountsPayable,
							)}
							to={{ pathname, section: "financials", id: "accountsPayable" }}
						/>
						<SummaryItem
							label="Credit Cards Payable"
							value={formatToDollar(financialsData.balanceSheet.creditCardsPayable)}
							error={formState.errors.financials?.balanceSheet?.creditCardsPayable}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.creditCardsPayable,
							)}
							to={{ pathname, section: "financials", id: "creditCardsPayable" }}
						/>
						<SummaryItem
							label="Revolving Line of Credit"
							value={formatToDollar(financialsData.balanceSheet.bankLinePayable)}
							error={formState.errors.financials?.balanceSheet?.bankLinePayable}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.bankLinePayable,
							)}
							to={{ pathname, section: "financials", id: "bankLinePayable" }}
						/>
						<SummaryItem
							label="Accrued Expenses"
							value={formatToDollar(financialsData.balanceSheet.accruedExpenses)}
							error={formState.errors.financials?.balanceSheet?.accruedExpenses}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.accruedExpenses,
							)}
							to={{ pathname, section: "financials", id: "accruedExpenses" }}
						/>
						<SummaryItem
							label="Overbillings"
							value={formatToDollar(financialsData.balanceSheet.overbillings)}
							error={formState.errors.financials?.balanceSheet?.overbillings}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.overbillings)}
							to={{ pathname, section: "financials", id: "overbillings" }}
						/>
						<SummaryItem
							label="Current Portion of Long Term Debt"
							value={formatToDollar(financialsData.balanceSheet.currentPortionOfLtd)}
							error={formState.errors.financials?.balanceSheet?.currentPortionOfLtd}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.currentPortionOfLtd,
							)}
							to={{ pathname, section: "financials", id: "currentPortionOfLtd" }}
						/>
						<SummaryItem
							label="Current Liabilities"
							value={formatToDollar(financialsData.balanceSheet.currentLiabilities)}
							error={formState.errors.financials?.balanceSheet?.currentLiabilities}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.currentLiabilities,
							)}
							to={{ pathname, section: "financials", id: "currentLiabilities" }}
						/>
						<SummaryItem
							label="Term Loan Debt"
							value={formatToDollar(financialsData.balanceSheet.termLoanDebt)}
							error={formState.errors.financials?.balanceSheet?.termLoanDebt}
							schemaInclude={Boolean(draft.schema.financials?.include.balanceSheet?.termLoanDebt)}
							to={{ pathname, section: "financials", id: "termLoanDebt" }}
						/>
						<SummaryItem
							label="Total Liabilities"
							value={formatToDollar(financialsData.balanceSheet.totalLiabilities)}
							error={formState.errors.financials?.balanceSheet?.totalLiabilities}
							schemaInclude={Boolean(
								draft.schema.financials?.include.balanceSheet?.totalLiabilities,
							)}
							to={{ pathname, section: "financials", id: "totalLiabilities" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Income Statement"
					schemaInclude={Boolean(draft.schema.financials?.include.incomeStatement)}
					to={{ pathname, section: "financials", id: "incomeStatement" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Revenue"
							value={formatToDollar(financialsData.incomeStatement.revenue)}
							error={formState.errors.financials?.incomeStatement?.revenue}
							schemaInclude={Boolean(draft.schema.financials?.include.incomeStatement?.revenue)}
							to={{ pathname, section: "financials", id: "revenue" }}
						/>
						<SummaryItem
							label="General Administrative Expense"
							value={formatToDollar(financialsData.incomeStatement.gaExpense)}
							error={formState.errors.financials?.incomeStatement?.gaExpense}
							schemaInclude={Boolean(draft.schema.financials?.include.incomeStatement?.gaExpense)}
							to={{ pathname, section: "financials", id: "gaExpense" }}
						/>
						<SummaryItem
							label="Profitable YTD"
							value={boolToYN(financialsData.incomeStatement.profitableYtd)}
							error={formState.errors.financials?.incomeStatement?.profitableYtd}
							schemaInclude={Boolean(
								draft.schema.financials?.include.incomeStatement?.profitableYtd,
							)}
							to={{ pathname, section: "financials", id: "profitableYtd" }}
						/>
						<SummaryItem
							label="Profitable Last FYE"
							value={boolToYN(financialsData.incomeStatement.profitableLastFye)}
							error={formState.errors.financials?.incomeStatement?.profitableLastFye}
							schemaInclude={Boolean(
								draft.schema.financials?.include.incomeStatement?.profitableLastFye,
							)}
							to={{ pathname, section: "financials", id: "profitableLastFye" }}
						/>
						<SummaryItem
							label="Profitable year prior to last fiscal year"
							value={boolToYN(financialsData.incomeStatement.profitableYearPriorToLastFiscalYear)}
							error={
								formState.errors.financials?.incomeStatement?.profitableYearPriorToLastFiscalYear
							}
							schemaInclude={Boolean(
								draft.schema.financials?.include.incomeStatement
									?.profitableYearPriorToLastFiscalYear,
							)}
							to={{ pathname, section: "financials", id: "profitableYearPriorToLastFiscalYear" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Work In Progress"
					schemaInclude={Boolean(draft.schema.financials?.include.wip)}
					to={{ pathname, section: "financials", id: "wip" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Backlog Cost-to-Complete"
							value={formatToDollar(financialsData.wip.projectBacklogCost)}
							error={formState.errors.financials?.wip?.projectBacklogCost}
							schemaInclude={Boolean(draft.schema.financials?.include.wip?.projectBacklogCost)}
							to={{ pathname, section: "financials", id: "projectBacklogCost" }}
						/>
						<SummaryItem
							label="Gross Profit in the Backlog"
							value={formatToDollar(financialsData.wip.projectBacklogGrossProfit)}
							error={formState.errors.financials?.wip?.projectBacklogGrossProfit}
							schemaInclude={Boolean(
								draft.schema.financials?.include.wip?.projectBacklogGrossProfit,
							)}
							to={{ pathname, section: "financials", id: "projectBacklogGrossProfit" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Bank Details"
					schemaInclude={Boolean(draft.schema.financials?.include.bankDetails)}
					to={{ pathname, section: "financials", id: "bankDetails" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Revolving Line of Credit Limit"
							value={formatToDollar(financialsData.bankDetails.blocSize)}
							error={formState.errors.financials?.bankDetails?.blocSize}
							schemaInclude={Boolean(draft.schema.financials?.include.bankDetails?.blocSize)}
							to={{ pathname, section: "financials", id: "blocSize" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
			</SummarySection>
		),
		summary: null,
	};

	return (
		<ShrinkingHeaderSectionNavLayout headerTitle="Summary" sections={sections}>
			<div className="w-full flex flex-col space-y-[25px] pt-[18px] pb-[125px]">
				{sections.map((section) => (
					<Fragment key={section.name}>{summarySectionMap[section.name]}</Fragment>
				))}
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};

type AccountDraftSummarySectionProps = {
	sections: { name: "details" | "history" | "financials" | "summary"; label: string }[];
};

export const AccountDraftSummarySection = ({ sections }: AccountDraftSummarySectionProps) => {
	const account = useContext(SuretyAccountDraftContext);

	return <_AccountDraftSummarySection sections={sections} draft={account.draft} />;
};
