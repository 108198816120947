import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, SuretyType, Types } from "@inrev/inrev-common";
import { createContext, useEffect, useMemo, useState } from "react";
import { FieldErrors, FieldPath, FormProvider, Resolver, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { Modal } from "../../../../../components/layout/Modal";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { SpinnerCheck } from "../../../../../components/ui/SpinnerCheck";
import {
	useAwaitedUpdateSuretyAccountDraft,
	useSubmitSuretyAccount,
	useUpdateSuretyAccountDraft,
} from "../../../../../domain/agent/account/api";
import { SuretyAccount } from "../../../../../domain/agent/account/types";
import { submitContractSuretyAccountDtoValidationSchema } from "../../../../../domain/agent/account/validation";
import { stripEmptyResolver, useAutoSave } from "../../../../../utils/form";
import { AccountDraftDetailsSection } from "./sections/AccountDraftDetailsSection";
import { AccountDraftFinancialsSection } from "./sections/AccountDraftFinancialsSection";
import { AccountDraftHistorySection } from "./sections/AccountDraftHistorySection";
import { AccountDraftSummarySection } from "./sections/AccountDraftSummarySection";

type AccountDraftViewProps = {
	account: DraftSuretyAccount;
};
type DraftSuretyAccount = Extract<SuretyAccount, { status: "draft" }>;
export type SuretyAccountDraftSectionName =
	| keyof Types.SuretyAccount.Draft.Contract.Schema
	| "summary";

export const getSectionsFromDraft = (
	schema: DraftSuretyAccount["draft"]["schema"],
	errors: FieldErrors<Types.SuretyAccount.Draft.Contract.Data>,
): { name: SuretyAccountDraftSectionName; label: string; error?: boolean }[] => {
	const sections: { name: SuretyAccountDraftSectionName; label: string }[] = (
		Object.entries(schema) as [keyof typeof schema, (typeof schema)[keyof typeof schema]][]
	)
		.sort((a, b) => a[1]!.index - b[1]!.index)
		.map((entry) => ({
			name: entry[0]!,
			label: entry[1]!.sectionLabel,
			error: errors[entry[0]!] !== undefined,
		}));

	return [...sections, { name: "summary", label: "Summary" }];
};
const accountDraftFormResolver: Resolver<
	Types.SuretyAccount.Draft.Contract.Data,
	{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema }
> = stripEmptyResolver((values, context, options) => {
	if (!context) throw new Error();
	return zodResolver(submitContractSuretyAccountDtoValidationSchema)(
		values,
		context.draftSchema,
		options,
	);
}, true);

export const SuretyAccountDraftContext = createContext<DraftSuretyAccount>(undefined!);

const fieldPathsToAwait: FieldPath<Types.SuretyAccount.Draft.Contract.Data>[] = [
	"details.exposureSize",
	"details.companies",
	"details.individuals",
];

export const AccountDraftView = ({ account }: AccountDraftViewProps) => {
	const [section, setSection] = useState<SuretyAccountDraftSectionName | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentSection = useMemo(
		() => searchParams.get("section") as SuretyAccountDraftSectionName | null,
		[searchParams],
	);
	const formMethods = useForm<
		Types.SuretyAccount.Draft.Contract.Data,
		{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema },
		Dtos.SuretyAccount.Contract.Submit.Request.New.Data
	>({
		reValidateMode: "onBlur",
		defaultValues: account.draft.data,
		context: { draftSchema: account.draft.schema },
		resolver: accountDraftFormResolver,
	});
	const sections = useMemo(
		() => getSectionsFromDraft(account.draft.schema, formMethods.formState.errors),
		[account.draft.schema, formMethods.formState],
	);
	const { updateSuretyAccountDraft } = useUpdateSuretyAccountDraft(account.id);
	const { awaitUpdateSuretyAccountDraft, awaitUpdateSuretyAccountDraftIsLoading } =
		useAwaitedUpdateSuretyAccountDraft(account.id);
	const { submitSuretyAccount, submitSuretyAccountIsLoading } = useSubmitSuretyAccount(
		account.id,
		SuretyType.contract,
	);
	useAutoSave(
		updateSuretyAccountDraft,
		awaitUpdateSuretyAccountDraft,
		formMethods,
		account.draft.data,
		account.draft,
		fieldPathsToAwait,
	);

	useEffect(() => {
		setSection(currentSection);
		if (currentSection === null || !sections.some((section) => section.name === currentSection)) {
			setSearchParams({ section: sections[0].name }, { replace: true });
		}
	}, [currentSection]);

	const onSubmit = (data: Dtos.SuretyAccount.Contract.Submit.Request.New.Data) => {
		submitSuretyAccount(data);
	};

	return (
		<SuretyAccountDraftContext.Provider value={account}>
			<FormProvider {...formMethods}>
				<form
					id="bond_request"
					onSubmit={formMethods.handleSubmit(onSubmit)}
					className="w-full h-full"
				>
					<WorkflowLayout title={"New Account Submission"}>
						{section === "details" && <AccountDraftDetailsSection sections={sections} />}
						{section === "financials" && <AccountDraftFinancialsSection sections={sections} />}
						{section === "history" && <AccountDraftHistorySection sections={sections} />}
						{section === "summary" && <AccountDraftSummarySection sections={sections} />}
					</WorkflowLayout>
				</form>
				{awaitUpdateSuretyAccountDraftIsLoading && <LoadingModal />}
				{submitSuretyAccountIsLoading && (
					<Modal>
						<div
							className={
								"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
							}
						>
							<div className="flex items-center space-x-[10px]">
								<SpinnerCheck spinning={true} />
								<div className="flex-1 text-gray-800">Underwriting</div>
							</div>
						</div>
					</Modal>
				)}
			</FormProvider>
		</SuretyAccountDraftContext.Provider>
	);
};
