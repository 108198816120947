import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { HiOutlineQueueList } from "react-icons/hi2";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { BondRequestDraftData } from "../../../../../../../domain/agent/request/types";
import { DraftBondRequestNewPrincipalSection } from "./DraftBondRequestNewPrincipalSection";
import { DraftBondRequestSelectPrincipalSection } from "./DraftBondRequestSelectPrincipalSection";

type DraftBondRequestPrincipalSectionProps = {
	sections: { name: string; label: string }[];
};

export const DraftBondRequestPrincipalSection = (props: DraftBondRequestPrincipalSectionProps) => {
	const { watch, setValue, formState } = useFormContext<BondRequestDraftData>();
	const newPrincipal = watch("principal.newPrincipal");
	const selectedPrincipalId = watch("principal.principalCompanyId");
	const confirmAccountTypeSwitch = useMemo(() => {
		return (
			formState.dirtyFields.principal?.individuals !== undefined ||
			formState.dirtyFields.principal?.companies !== undefined ||
			formState.dirtyFields.history !== undefined ||
			formState.dirtyFields.financials !== undefined
		);
	}, [formState]);

	return (
		<ShrinkingHeaderSectionNavLayout
			headerTitle={newPrincipal ? "New Principal" : "Select a Principal"}
			headerButton={selectedPrincipalId === "" || newPrincipal}
			headerButtonContent={
				newPrincipal ? (
					<div className="flex items-center">
						<HiOutlineQueueList className="text-[14px] mr-[5px]" />
						<div>Select a Principal</div>
					</div>
				) : (
					"New Principal"
				)
			}
			headerButtonProps={{
				color: newPrincipal ? "gray" : "light-blue",
				rounded: true,
				className: newPrincipal
					? "border-[0px] font-medium text-gray-600 hover:underline hover:bg-white"
					: undefined,
				onClick: () => setValue("principal.newPrincipal", !newPrincipal, { shouldDirty: true }),
				confirmationModal: confirmAccountTypeSwitch,
				confirmationMessage: newPrincipal
					? "Are you sure you want to create a new account?"
					: "Are you sure you want to select an existing account?",
				confirmationMessageSubtext: (
					<span>
						{"All account, financial, and history details you"}
						<br />
						{"have entered will be lost"}
					</span>
				),
			}}
			sections={props.sections}
		>
			{newPrincipal ? (
				<DraftBondRequestNewPrincipalSection />
			) : (
				<DraftBondRequestSelectPrincipalSection />
			)}
		</ShrinkingHeaderSectionNavLayout>
	);
};
