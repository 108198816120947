import { NameSuffix } from "@inrev/inrev-common";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import {
	AdminIndividualOwnerFormData,
	AdminIndividualSpouseFormData,
	DefaultAdminIndividualFormData,
} from ".";
import { UseAddressValidationReturn } from "../../../../../api";
import { FormItem } from "../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../components/layout/form/FormRow";
import { Button } from "../../../../../components/ui/Button";
import { FormAddressAutocomplete } from "../../../../../components/ui/form/FormAddressAutocomplete";
import { FormDropdown } from "../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../components/ui/form/FormYesNo";
import { formatAddress } from "../../../../../utils";

type AdminIndividualSpouseFormProps = {
	form: UseFormReturn<DefaultAdminIndividualFormData, any, AdminIndividualSpouseFormData>;
	addressValidation: UseAddressValidationReturn;
	validatedOwnerData: AdminIndividualOwnerFormData | undefined;
	onBack: () => void;
	onSubmit: (data: AdminIndividualSpouseFormData) => void;
	loading: boolean;
};

export const AdminIndividualSpouseForm = ({
	form,
	addressValidation,
	validatedOwnerData,
	onBack,
	onSubmit,
	loading,
}: AdminIndividualSpouseFormProps) => {
	const includeSpouseInUnderwriting = form.watch("includeInUnderwriting");

	const handleSubmit = (formData: AdminIndividualSpouseFormData) => {
		addressValidation.resetAddressValidation();
		onSubmit(formData);
	};

	useEffect(() => {
		if (
			validatedOwnerData &&
			includeSpouseInUnderwriting === "" &&
			addressValidation.addressFieldControl.value === ""
		) {
			addressValidation.setAddressValue(formatAddress(validatedOwnerData.address));
		}
	}, []);

	return (
		<form className="w-full h-fit flex flex-col space-y-[25px]">
			<FormRow className="justify-start gap-x-[40px]">
				<FormItem className="max-w-fit">
					<FormQuestionLabel condensed>Include spouse in underwriting?</FormQuestionLabel>
					<FormYesNo name="includeInUnderwriting" control={form.control} />
				</FormItem>
				<FormItem className="max-w-fit">
					<FormQuestionLabel condensed>Include spouse in indemnity?</FormQuestionLabel>
					<FormYesNo name="includeInIndemnity" control={form.control} />
				</FormItem>
			</FormRow>
			<FormItemGroup condensed>
				<FormRow>
					<FormItem className="flex-2" condensed>
						<FormItemLabel condensed>First Name</FormItemLabel>
						<FormInput name="firstName" control={form.control} condensed />
					</FormItem>
					<FormItem className="max-w-[90px]" condensed>
						<FormItemLabel condensed>Middle Initial</FormItemLabel>
						<FormInput
							name="middleInitial"
							control={form.control}
							maxLength={1}
							transformer={{
								input: (value) => value as string,
								output: (value) => value.toUpperCase(),
							}}
							condensed
						/>
					</FormItem>
					<FormItem condensed className="flex-2">
						<FormItemLabel condensed>Last Name</FormItemLabel>
						<FormInput name="lastName" control={form.control} condensed />
					</FormItem>
					<FormItem className="w-[100px] max-w-[100px]" condensed>
						<FormItemLabel condensed>Suffix</FormItemLabel>
						<FormDropdown
							name="suffix"
							control={form.control}
							options={Object.values(NameSuffix).map((suffix) => ({
								value: suffix,
								label: suffix,
							}))}
							buttonClassName="h-[46px] text-[16px]"
						/>
					</FormItem>
				</FormRow>
				<FormItem condensed>
					<FormItemLabel condensed>Address</FormItemLabel>
					<FormAddressAutocomplete
						control={addressValidation.addressFieldControl}
						className="h-[46px] text-[16px]"
					/>
				</FormItem>
				<FormRow>
					<FormItem condensed>
						<FormItemLabel condensed>
							Email
							<span className="text-gray-400 text-[13px] ml-[8px] font-thin">If Available</span>
						</FormItemLabel>
						<FormInput name="email" control={form.control} condensed />
					</FormItem>
					{includeSpouseInUnderwriting && (
						<FormItem className="max-w-[200px]" condensed>
							<FormItemLabel condensed>SSN</FormItemLabel>
							<FormInput name="ssn" control={form.control} type="numeric" maxLength={9} condensed />
						</FormItem>
					)}
				</FormRow>
			</FormItemGroup>
			<div className="w-full flex justify-center pt-[5px] space-x-[10px]">
				<Button onClick={onBack} color="gray" className="w-[150px] h-[36px]">
					Back
				</Button>
				<Button
					onClick={form.handleSubmit(handleSubmit)}
					color="light-blue"
					filled
					className="w-[150px] h-[36px]"
					disabled={includeSpouseInUnderwriting === ""}
					loading={form.formState.isSubmitting || loading}
				>
					Submit
				</Button>
			</div>
		</form>
	);
};
