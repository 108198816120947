import { formatToDollar } from "@inrev/inrev-common";
import { useMemo } from "react";
import { Card } from "../../../../components/ui/Card";
import { suretyBondClosedReasonTypeLabelMap } from "../../../../constants";
import { AdminSuretyBond } from "../../../../domain/admin/bond/types";
import { formatEmpty } from "../../../../utils";
import { AdminDataList, AdminDataListProps } from "../../shared/AdminDataList";

type AdminBondClosingDetailsCardProps = {
	bond: AdminSuretyBond;
};

export const AdminBondClosingDetailsCard = ({ bond }: AdminBondClosingDetailsCardProps) => {
	if (bond.status !== "closed") return null;
	const dataList = useMemo(() => {
		const dataList: AdminDataListProps["data"] = [
			{ label: "Closed Date", value: formatEmpty(bond.closedAt) },
			{
				label: "Closed Reason",
				value: formatEmpty(
					!!bond.closedReason ? suretyBondClosedReasonTypeLabelMap[bond.closedReason] : undefined,
				),
			},
		];

		if (bond.contractSuretyType === "final" && bond.closedReason === "project_completed") {
			dataList.push({
				label: "Actual Completion Date",
				value: formatEmpty(bond.actualCompletionDate),
			});
			dataList.push({
				label: "Final Contract Price",
				value: formatEmpty(formatToDollar(bond.actualContractAmount)),
			});
		}

		dataList.push({ label: "Closed Note", value: formatEmpty(bond.closedNote) });

		return dataList;
	}, [bond]);

	return (
		<Card>
			<div className="w-full px-[33px] pt-[26px] pb-[25px] flex flex-col space-y-[25px]">
				<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
					Closing Details
				</div>
				<AdminDataList data={dataList} />
			</div>
		</Card>
	);
};
