import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos } from "@inrev/inrev-common";
import { useMemo } from "react";
import { FieldPath, UseFormReturn, useForm } from "react-hook-form";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { Button } from "../../../components/ui/Button";
import { NotificationCard } from "../../../components/ui/NotificationCard";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { FormYesNo } from "../../../components/ui/form/FormYesNo";
import { useApproveBondFormForAgentIssue } from "../../../domain/admin/request/api";
import {
	AdminApproveBondFormForAgentIssueFormData,
	AdminBondRequest,
} from "../../../domain/admin/request/types";
import { stripEmptyResolver } from "../../../utils/form";

type AdminApproveBondFormForAgentIssueFormProps = {
	request: Extract<AdminBondRequest, { status: "pending" }>;
};

export const getDefaultFormData = (
	request: AdminBondRequest,
): AdminApproveBondFormForAgentIssueFormData => ({
	suretyType: request.suretyType,
	contractSuretyType: request.contractSuretyType,
	bondFormType: request.contractSuretyType === "bid" ? "bid" : request.bondForm.bondFormType,
	cancellable: request.contractSuretyType === "bid" ? false : "",
	continuous: request.contractSuretyType === "bid" ? true : "",
	renewable: false,
});

type AdminApproveBondFormForAgentIssueFormFieldsProps<TData extends Record<string, any>> = {
	formMethods: UseFormReturn<TData, any, any>;
	fieldPaths: {
		bondFormType: FieldPath<TData>;
		cancellable: FieldPath<TData>;
		continuous: FieldPath<TData>;
	};
	request: AdminBondRequest;
};

export const AdminApproveBondFormFormFields = <TData extends Record<string, any>>({
	formMethods,
	fieldPaths,
	request,
}: AdminApproveBondFormForAgentIssueFormFieldsProps<TData>) => {
	const bondFormTypeOptions = useMemo(() => {
		if (request.contractSuretyType === "bid") {
			return [{ label: "Bid", value: "bid" }];
		}
		return [
			{ label: "Performance", value: "performance" },
			{ label: "Payment", value: "payment" },
			{ label: "Performance and Payment", value: "pnp" },
		];
	}, [request.contractSuretyType]);

	return (
		<div className="flex flex-col space-y-[25px]">
			<FormItemGroup condensed>
				<FormItem condensed>
					<FormItemLabel>Bond Form Type</FormItemLabel>
					<FormDropdown
						name={fieldPaths.bondFormType}
						control={formMethods.control}
						options={bondFormTypeOptions}
						readonly={request.contractSuretyType === "bid"}
						placeholder="Select one"
						condensed
					/>
				</FormItem>
				<FormRow className="space-x-[50px]">
					<FormItem condensed className="max-w-fit">
						<FormItemLabel>Continuous</FormItemLabel>
						<FormYesNo name={fieldPaths.continuous} control={formMethods.control} />
					</FormItem>
					<FormItem condensed className="flex-2">
						<FormItemLabel>Cancellable</FormItemLabel>
						<FormYesNo name={fieldPaths.cancellable} control={formMethods.control} />
					</FormItem>
				</FormRow>
			</FormItemGroup>
		</div>
	);
};

const _AdminApproveBondFormForAgentIssueForm = ({
	request,
}: AdminApproveBondFormForAgentIssueFormProps) => {
	const formMethods = useForm<
		AdminApproveBondFormForAgentIssueFormData,
		any,
		Dtos.Admin.SuretyBondForm.Approve.Request
	>({
		defaultValues: getDefaultFormData(request),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Admin.SuretyBondForm.Approve.Request.schema)),
	});

	const { approveBondFormForAgentIssue, approveBondFormForAgentIssueIsLoading } =
		useApproveBondFormForAgentIssue(request.id);

	const handleSubmit = (data: Dtos.Admin.SuretyBondForm.Approve.Request) => {
		approveBondFormForAgentIssue(data);
	};

	return (
		<form className="flex flex-col space-y-[25px]">
			<NotificationCard type="action" text="Approve the uploaded bond form for agent issue" />
			<AdminApproveBondFormFormFields
				formMethods={formMethods}
				fieldPaths={{
					bondFormType: "bondFormType",
					cancellable: "cancellable",
					continuous: "continuous",
				}}
				request={request}
			/>
			<ConfirmationModalClickWrapper
				shouldConfirm={true}
				message="Are you sure?"
				onConfirm={formMethods.handleSubmit(handleSubmit)}
			>
				<Button color="green" className="max-w-fit self-center px-[50px] !mt-[40px]" filled>
					Approve for Agent Issue
				</Button>
			</ConfirmationModalClickWrapper>
			{approveBondFormForAgentIssueIsLoading && <LoadingModal />}
		</form>
	);
};

export const AdminApproveBondFormForAgentIssueForm = ({
	request,
}: AdminApproveBondFormForAgentIssueFormProps) => {
	if (request.bondIssuance.pendingBondFormReview && request.bondForm.issuanceMethod === "agent") {
		return <_AdminApproveBondFormForAgentIssueForm request={request} />;
	}
	return <></>;
};
