import { formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { HiOutlineCalendar } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { FollowerControls } from "../../../../components/ui/FollowerControls";
import { Icon } from "../../../../components/ui/Icon";
import { KPICard } from "../../../../components/ui/KPICard";
import { PDFViewer } from "../../../../components/ui/PDFViewer";
import { Separator } from "../../../../components/ui/Separator";
import { StackedCard } from "../../../../components/ui/StackedCard";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import {
	carrierLogoSmallMap,
	contractSuretyTypeToLabelMap,
	suretyBondClosedReasonTypeLabelMap,
} from "../../../../constants";
import { SuretyBond } from "../../../../domain/agent/bond/types";
import { useFetchAgentPreviews } from "../../../../domain/agent/user/api";
import { useFollowers } from "../../../../domain/shared/followers/api";

export type BondCardProps = {
	bond: SuretyBond;
	actionButtons?: ReactNode;
};

export const BondCard = ({ bond, actionButtons }: BondCardProps) => {
	const followersControl = useFollowers("bonds", bond.id);
	const statusTag = getStatusTagByStatus(bond.status);
	const navigate = useNavigate();

	return (
		<StackedCard className="w-full h-fit max-h-fit">
			<div id="bond_request_card" className="w-full h-fit flex flex-col">
				<div className="flex items-center w-full h-[48px] px-[15px] bg-gray-50 border border-gray-100 rounded-t-md text-[13px]">
					<div className="flex-1 text-gray-600 font-medium">
						<div className="flex items-center space-x-[10px]">
							{carrierLogoSmallMap[bond.carrierName]}
							<span>{bond.carrierDisplayName}</span>
						</div>
					</div>
					<div className="flex items-center gap-[15px]">
						<div className="font-mono font-medium text-[13px] text-gray-600">{bond.number}</div>
						<Separator orientation="vertical" className="bg-gray-200 h-[20px]" />
						{statusTag}
					</div>
				</div>
				<div className="flex flex-col space-y-[52px] w-full h-fit px-[40px] pb-[30px] pt-[25px]">
					<div className="w-full h-fit flex space-x-[15px]">
						<div className="flex-1 flex flex-col space-y-[25px]">
							<div className="flex flex-col space-y-[20px]">
								<div className="flex flex-col space-y-[10px]">
									<span className="text-[24px] text-gray-800 font-semibold leading-[32px]">
										{`${formatToDollar(bond.amount)} ${contractSuretyTypeToLabelMap[bond.contractSuretyType]} Bond`}
									</span>
									<div className="flex flex-col space-y-[7px] pl-[2px] text-[14.5px] font-[425] text-gray-700 leading-[18px]">
										<span className="cursor-pointer">
											for{" "}
											<span
												className="hover:underline cursor-pointer"
												onClick={() => navigate(`/accounts/${bond.accountId}`)}
											>
												{bond.principalName}
											</span>
										</span>
										<div className="flex flex-col space-y-[6px]">
											<div className="flex items-center space-x-[5px]">
												<HiOutlineCalendar className="text-[16px] stroke-[1.75]" />
												<span>
													Effective{" "}
													{DateTime.fromISO(bond.effectiveDate).toLocaleString(DateTime.DATE_MED)}
												</span>
											</div>
											{bond.status === "closed" && !!bond.closedAt && (
												<div className="flex items-center space-x-[7px]">
													<Icon type="shield-slash" className="h-[14px]" />
													<span>
														Closed{" "}
														{DateTime.fromISO(bond.closedAt).toLocaleString(DateTime.DATE_MED)}
														{!!bond.closedReason
															? ` - ${suretyBondClosedReasonTypeLabelMap[bond.closedReason]}`
															: ""}
													</span>
												</div>
											)}
											<div className="flex space-x-[15px] !mt-[28px]">
												<div className="h-full w-[2.5px] min-w-[2.5px] rounded-full bg-gray-200"></div>
												<div className="text-[14.5px] text-gray-600 leading-[22px] font-[425] italic pr-[50px]">
													{bond.description}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<FollowerControls
								{...followersControl}
								useFetchAgentPreviews={useFetchAgentPreviews}
								notFollowingLabel="Follow this bond"
								manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this bond"
							/>
						</div>
						<div>
							<div className="flex flex-col items-end space-y-[20px] w-[165px]">
								<div className="w-[165px] flex flex-col space-y-[4px]">
									{actionButtons && actionButtons}
								</div>
							</div>
						</div>
					</div>
					<div className="flex space-x-[20px]">
						{bond.contractSuretyType === "final" && (
							<>
								<KPICard
									title="Rate"
									value={formatToPercent(bond.rate, 3)}
									className="w-[160px] h-[120px]"
								/>
								<KPICard
									title="Premium"
									value={formatToDollar(bond.premium)!}
									className="w-[160px] h-[120px] flex-1"
								/>
								<KPICard
									title="Commission"
									value={formatToPercent(bond.agencyCommissionPercentage)}
									className="w-[160px] h-[120px]"
								/>
							</>
						)}
						{bond.contractSuretyType === "bid" && (
							<>
								<KPICard
									title="Final Bond Rate"
									value={formatToPercent(bond.finalBondContingentRate, 3)}
									className="w-[160px] h-[120px]"
								/>
								<KPICard
									title="Final Bond Premium"
									value={formatToDollar(bond.finalBondContingentPremium)!}
									className="w-[160px] h-[120px] flex-1"
								/>
								<KPICard
									title="Commission"
									value={formatToPercent(bond.finalBondAgencyCommissionPercentage)}
									className="w-[160px] h-[120px]"
								/>
							</>
						)}
					</div>
					<PDFViewer className="h-[830px] mx-[10px]" src={`${bond.bondForm.url}#navpanes=0`} />
				</div>
			</div>
		</StackedCard>
	);
};
