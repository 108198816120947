import { zodResolver } from "@hookform/resolvers/zod";
import { NameSuffix } from "@inrev/inrev-common";
import { useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { useAddressValidation } from "../../../../../api";
import { ConfirmationModal } from "../../../../../components/layout/ConfirmationModal";
import { Modal } from "../../../../../components/layout/Modal";
import { FormItem } from "../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../components/layout/form/FormRow";
import { FormSection } from "../../../../../components/layout/form/FormSection";
import { ArchivedBanner } from "../../../../../components/ui/Banner";
import { Button } from "../../../../../components/ui/Button";
import { InfoCard } from "../../../../../components/ui/InfoCard";
import { MoreActionsMenu } from "../../../../../components/ui/MoreActionsMenu";
import { SpinnerCheck } from "../../../../../components/ui/SpinnerCheck";
import { StackedCardGrayBody } from "../../../../../components/ui/StackedCardGrayBody";
import { FormAddressAutocomplete } from "../../../../../components/ui/form/FormAddressAutocomplete";
import { FormDatePicker } from "../../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../components/ui/form/FormQuestionLabel";
import {
	useArchiveRequest,
	useIssueBond,
	useUnarchiveRequest,
} from "../../../../../domain/agent/request/api";
import {
	BondIssuanceFormData,
	BondRequest,
	ValidatedBondIssuanceFormData,
} from "../../../../../domain/agent/request/types";
import { getBondIssuanceFormValidationSchema } from "../../../../../domain/agent/request/validation";
import { stripEmptyResolver } from "../../../../../utils/form";
import { SubmittedBondRequestLayout } from "../../../../shared/request/SubmittedBondRequestLayout";

type AcceptedBondRequestViewProps = {
	request: BondRequest & Extract<BondRequest, { status: "accepted" }>;
};

const draftBondRequestBondIssuanceFormResolver: Resolver<
	BondIssuanceFormData,
	AcceptedBondRequestViewProps["request"]
> = async (values, context, options) => {
	if (!context) throw new Error();

	// This is a patch to ensure that zod errors are included for empty obligee fields while we only support one obligee
	if (!!!values.bondForm.data.obligees || !!!values.bondForm.data.obligees[0]) {
		values.bondForm.data.obligees = [{} as any];
	}
	return zodResolver(getBondIssuanceFormValidationSchema(context))(values, context, options);
};

const getDefaultCompanyIndemnitorFormData = (
	companyIndemnitor: AcceptedBondRequestViewProps["request"]["bondIssuance"]["indemnityAgreement"]["indemnitors"]["companies"][number],
) => {
	return {
		contactId: companyIndemnitor.id,
		name: companyIndemnitor.name,
		signerEmail: "",
	};
};

const getDefaultIndividualIndemnitorFormData = (
	individualIndemnitor: AcceptedBondRequestViewProps["request"]["bondIssuance"]["indemnityAgreement"]["indemnitors"]["individuals"][number],
) => {
	return {
		contactId: individualIndemnitor.id,
		name: individualIndemnitor.name,
		signerEmail: individualIndemnitor.signerEmail ?? "",
	};
};

const getDefaultFormData = (
	request: AcceptedBondRequestViewProps["request"],
): BondIssuanceFormData => ({
	bondForm: {
		suretyType: request.suretyType,
		contractSuretyType: request.contractSuretyType,
		issuanceMethod: "",
		data: {
			contractDate: "",
			contractDescription: request.bondIssuance.bondForm.description,
			projectDescription: request.bondIssuance.bondForm.description,
			obligees: [
				{
					name: "",
					address: "",
					role: "",
				},
			],
			principalSigner: {
				firstName: "",
				lastName: "",
				suffix: "",
				title: "",
			},
		},
	},
	indemnityAgreement: {
		suretyType: request.suretyType,
		signatureMethod: "",
		companySigners: request.bondIssuance.indemnityAgreement.indemnitors.companies.map(
			(companyIndemnitor) => getDefaultCompanyIndemnitorFormData(companyIndemnitor),
		),
		individualSigners: request.bondIssuance.indemnityAgreement.indemnitors.individuals.map(
			(individualIndemnitor) => getDefaultIndividualIndemnitorFormData(individualIndemnitor),
		),
	},
});

export const AcceptedBondRequestView = ({ request }: AcceptedBondRequestViewProps) => {
	const formMethods = useForm<
		BondIssuanceFormData,
		AcceptedBondRequestViewProps["request"],
		ValidatedBondIssuanceFormData
	>({
		defaultValues: getDefaultFormData(request),
		reValidateMode: "onBlur",
		context: request,
		resolver: stripEmptyResolver(draftBondRequestBondIssuanceFormResolver, false),
	});
	const obligeeAddressValidation = useAddressValidation<BondIssuanceFormData>(
		"",
		formMethods,
		"bondForm.data.obligees.0.address",
	);
	const [showSubmissionConfirmation, setShowSubmissionConfirmation] = useState<boolean>(false);
	const [validatedSubmitData, setValidatedSubmitData] = useState<
		ValidatedBondIssuanceFormData | undefined
	>();
	const { archiveRequest } = useArchiveRequest();
	const { unarchiveRequest } = useUnarchiveRequest();
	const { issueBond, issueBondIsLoading } = useIssueBond(request);

	useEffect(() => {
		if (validatedSubmitData !== undefined) setShowSubmissionConfirmation(true);
		else if (showSubmissionConfirmation) setShowSubmissionConfirmation(false);
	}, [validatedSubmitData]);

	const onSubmit = (data: ValidatedBondIssuanceFormData) => {
		setValidatedSubmitData(data);
	};

	const onSubmitConfirm = () => {
		if (validatedSubmitData === undefined) throw new Error();
		setValidatedSubmitData(undefined);
		document
			.getElementById("bond_request_card")
			?.scrollIntoView({ block: "center", behavior: "instant" });
		issueBond(validatedSubmitData);
	};

	const onSubmitCancel = () => {
		setValidatedSubmitData(undefined);
	};
	const companyIndemnitors = formMethods.watch("indemnityAgreement.companySigners");
	const individualIndemnitors = formMethods.watch("indemnityAgreement.individualSigners");
	const indemnityAgreementSignatureMethod = formMethods.watch("indemnityAgreement.signatureMethod");
	const bondFormIssuanceMethod = formMethods.watch("bondForm.issuanceMethod");

	return (
		<SubmittedBondRequestLayout
			request={request}
			banner={
				request.archived ? (
					<ArchivedBanner onUnarchive={() => unarchiveRequest(request.id)} />
				) : undefined
			}
			actionButtons={
				<div className="flex flex-col space-y-[4px] max-w-[165px]">
					<Button
						onClick={formMethods.handleSubmit(onSubmit)}
						color="light-blue"
						filled
						rounded
						loading={false}
					>
						Issue Bond
					</Button>
					{formMethods.formState.isSubmitted &&
						Object.keys(formMethods.formState.errors).length > 0 && (
							<span className="text-[13px] text-red-500 leading-[18px] self-center text-center">
								Please review form errors
							</span>
						)}
					<MoreActionsMenu
						items={[
							{
								label: "Archive",
								icon: <HiOutlineArchiveBox className="text-[15px]" />,
								onClick: () => {
									archiveRequest(request.id);
								},
							},
						]}
						panelClassName="mt-[10px]"
					/>
				</div>
			}
		>
			<form
				id="bond_request_issuance"
				className="w-[725px] max-w-[725px] flex flex-col items-center space-y-[30px] !mt-[30px]"
				onSubmit={formMethods.handleSubmit(onSubmit)}
			>
				<div className="w-full flex flex-col space-y-[40px] items-center">
					{request.bondIssuance.indemnityAgreement.status === "incomplete" && (
						<StackedCardGrayBody
							header={
								<div className="w-full h-fit flex flex-col space-y-[22px] pb-[5px]">
									<div className="w-full h-fit flex">
										<div className="flex-1 flex flex-col space-y-[8px]">
											<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
												Indemnity Agreement
											</div>
										</div>
										<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
											What is this?
										</div>
									</div>
									<FormItem condensed>
										<FormQuestionLabel condensed>
											How should this indemnity agreement be signed?
										</FormQuestionLabel>
										<FormDropdown
											condensed
											control={formMethods.control}
											name="indemnityAgreement.signatureMethod"
											options={(
												[
													{ value: "electronic", label: "Electronic Signatures" },
													{ value: "physical", label: "Wet Signatures" },
												] as const
											).filter((option) =>
												request.bondIssuance.indemnityAgreement.allowedSignatureMethods.includes(
													option.value,
												),
											)}
											placeholder="Select one"
											errorMessage="Required"
										/>
									</FormItem>
									{indemnityAgreementSignatureMethod === "physical" && (
										<InfoCard>
											By selecting "Wet signatures", you are responsible for collecting signatures
											from indemnitors, creating a scanned copy of the signed agreement, and sending
											the scanned copy to your inRev account representative
										</InfoCard>
									)}
								</div>
							}
							wrapperClassName="w-full"
							contentClassName="w-full h-fit min-h-fit flex flex-col space-y-[35px] pb-[50px]"
							hideBody={
								indemnityAgreementSignatureMethod === "" ||
								indemnityAgreementSignatureMethod === "physical"
							}
						>
							{indemnityAgreementSignatureMethod === "electronic" && (
								<>
									{companyIndemnitors.map((company, index) => (
										<FormSection
											key={index}
											header={
												<div className="flex items-center space-x-[10px]">
													<span className="text-gray-400 text-[13px]">{index + 1}.</span>
													<span className="text-gray-900 text-[14px]">{company.name}</span>
												</div>
											}
											headerClassName="bg-gray-100 px-[10px] py-[8px] rounded-sm"
										>
											{indemnityAgreementSignatureMethod === "electronic" && (
												<FormItem condensed>
													<FormItemLabel condensed>Signer Email</FormItemLabel>
													<FormInput
														control={formMethods.control}
														name={`indemnityAgreement.companySigners.${index}.signerEmail`}
														className="bg-white"
														condensed
													/>
												</FormItem>
											)}
										</FormSection>
									))}
									{individualIndemnitors.map((individual, index) => (
										<FormSection
											key={index}
											header={
												<div className="flex items-center space-x-[10px]">
													<span className="text-gray-400 text-[13px]">
														{companyIndemnitors.length + index + 1}.
													</span>
													<span className="text-gray-900 text-[14px]">{individual.name}</span>
												</div>
											}
											headerClassName="bg-gray-100 px-[10px] py-[8px] rounded-sm"
										>
											<FormItem condensed>
												<FormItemLabel condensed>Email</FormItemLabel>
												<FormInput
													control={formMethods.control}
													name={`indemnityAgreement.individualSigners.${index}.signerEmail`}
													className="bg-white"
													condensed
												/>
											</FormItem>
										</FormSection>
									))}
								</>
							)}
						</StackedCardGrayBody>
					)}
					<StackedCardGrayBody
						header={
							<div className="w-full h-fit flex flex-col space-y-[22px] pb-[5px]">
								<div className="w-full h-fit flex">
									<div className="flex-1 flex flex-col space-y-[8px]">
										<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
											Bond Form
										</div>
									</div>
								</div>
								<FormItem condensed>
									<FormQuestionLabel condensed>
										How should this bond form be issued?
									</FormQuestionLabel>
									<FormDropdown
										condensed
										control={formMethods.control}
										name="bondForm.issuanceMethod"
										options={(
											[
												{ value: "electronic", label: "Electronic" },
												{ value: "physical", label: "inRev Issue - Wet Signatures, Raised Seals" },
												{ value: "agent", label: "Agent Issue" },
											] as const
										).filter((option) =>
											request.bondIssuance.bondForm.allowedIssuanceMethods.includes(option.value),
										)}
										placeholder="Select one"
										errorMessage="Required"
									/>
								</FormItem>
								{bondFormIssuanceMethod === "agent" && (
									<InfoCard>
										By selecting "Agent Issue", you are responsible for filling out the bond form,
										applying your physical seal, and sending the bond form to your client
									</InfoCard>
								)}
							</div>
						}
						contentClassName="space-y-[40px] pb-[50px]"
						hideBody={bondFormIssuanceMethod === "" || bondFormIssuanceMethod === "agent"}
					>
						<FormItemGroup condensed>
							<FormItem condensed>
								<FormItemLabel condensed>Project Description</FormItemLabel>
								<span className="!my-[5px] text-[14px] text-gray-500 italic">
									As it should appear on the bond form
								</span>
								<FormInput
									control={formMethods.control}
									name={
										request.contractSuretyType === "bid"
											? `bondForm.data.projectDescription`
											: `bondForm.data.contractDescription`
									}
									className="bg-white"
									condensed
								/>
							</FormItem>
							{request.contractSuretyType === "final" && (
								<FormItem condensed>
									<FormItemLabel condensed>Contract Date</FormItemLabel>
									<FormDatePicker
										control={formMethods.control}
										name={`bondForm.data.contractDate`}
										className="bg-white"
										condensed
									/>
								</FormItem>
							)}
						</FormItemGroup>
						<FormSection header="Obligee">
							<FormItemGroup condensed>
								<FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>Name</FormItemLabel>
										<FormInput
											control={formMethods.control}
											name={`bondForm.data.obligees.0.name`}
											className="bg-white"
											condensed
										/>
									</FormItem>
									<FormItem condensed className="w-[50px] max-w-[225px]">
										<FormItemLabel condensed>Role</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name={"bondForm.data.obligees.0.role"}
											buttonClassName="bg-white"
											options={[
												{ value: "project_owner", label: "Project Owner" },
												{ value: "lender", label: "Lender" },
												{ value: "gc", label: "General Contractor" },
												{ value: "other", label: "Other" },
											]}
											placeholder="Select one"
											condensed
										/>
									</FormItem>
								</FormRow>
								<FormItem condensed>
									<FormItemLabel condensed>Address</FormItemLabel>
									<FormAddressAutocomplete
										control={obligeeAddressValidation.addressFieldControl}
										className="bg-white"
										condensed
									/>
								</FormItem>
							</FormItemGroup>
						</FormSection>
						<FormSection
							header="Signer"
							subHeader="If available"
							subHeaderClassName="text-gray-500 italic"
						>
							<FormItemGroup condensed>
								<FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>First Name</FormItemLabel>
										<FormInput
											control={formMethods.control}
											name={`bondForm.data.principalSigner.firstName`}
											className="bg-white"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Last Name</FormItemLabel>
										<FormInput
											control={formMethods.control}
											name={`bondForm.data.principalSigner.lastName`}
											className="bg-white"
											condensed
										/>
									</FormItem>
									<FormItem className="w-[100px] max-w-[100px]" condensed>
										<FormItemLabel condensed>Suffix</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bondForm.data.principalSigner.suffix"
											options={Object.values(NameSuffix).map((suffix) => ({
												value: suffix,
												label: suffix,
											}))}
											buttonClassName="h-[46px] text-[16px] bg-white"
											condensed
										/>
									</FormItem>
								</FormRow>
								<FormItem condensed>
									<FormItemLabel condensed>Title</FormItemLabel>
									<FormInput
										control={formMethods.control}
										name={`bondForm.data.principalSigner.title`}
										className="bg-white"
										condensed
									/>
								</FormItem>
							</FormItemGroup>
						</FormSection>
					</StackedCardGrayBody>
					{bondFormIssuanceMethod !== "" && (
						<Button type="submit" color="light-blue" filled rounded className="w-[165px]">
							Issue Bond
						</Button>
					)}
				</div>
				{showSubmissionConfirmation && (
					<ConfirmationModal
						onConfirm={onSubmitConfirm}
						onCancel={onSubmitCancel}
						message="Are you sure you want to issue this bond?"
					/>
				)}
				{issueBondIsLoading && (
					<Modal>
						<div
							className={
								"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
							}
						>
							<div className="flex items-center space-x-[10px]">
								<SpinnerCheck spinning={true} className="bg-violet-700" />
								<div className="flex-1">Issuing your bond</div>
							</div>
						</div>
					</Modal>
				)}
			</form>
		</SubmittedBondRequestLayout>
	);
};
