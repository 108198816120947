import type { Types } from "@inrev/inrev-common";
import type { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import { _AccountDraftHistorySection } from "../../../../agent/account/status/draft/sections/AccountDraftHistorySection";

type AdminAccountReUnderwriteHistorySectionProps = {
	account: AdminSuretyAccount;
	draft: Types.SuretyAccount.Draft.Contract.Type;
	sections: { name: string; label: string }[];
};

export const AdminAccountReUnderwriteHistorySection = ({
	draft,
	sections,
}: AdminAccountReUnderwriteHistorySectionProps) => {
	return <_AccountDraftHistorySection sections={sections} draft={draft} />;
};
