import { Address, getValidatedAddress } from "@inrev/inrev-common";
import { path } from "rambda";
// import { assocPath } from "rambda";
import { useContext, useEffect, useState } from "react";
import { FieldError, FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";
import { ApiError } from "./domain/shared/types";
import { GlobalErrorMessageModalContext } from "./providers/GlobalErrorHandlingProvider";
import { formatAddress } from "./utils";
import { useRequest } from "./utils/request";

export const getFromValidatedAddressCache = (address: string): Address | undefined => {
	const cachedResponse = sessionStorage.getItem(address);
	if (cachedResponse !== null) return JSON.parse(cachedResponse);
};

export const setValidatedAddressCache = (addressKey: string, addressValue: Address) => {
	sessionStorage.setItem(addressKey, JSON.stringify(addressValue));
};

export type AddressFormFieldControl = {
	value: string;
	onChange?: (value: string) => void;
	onBlur?: () => void;
	error?: { message: string };
};

// export const useAddressValidation = <TFieldValues extends FieldValues>(
//     formMethods: UseFormReturn<TFieldValues, any, any>,
//     fieldPath: Path<TFieldValues>,
//     defaultRawAddress: string,
//     options?: {
//         validateOnFirstBlur?: boolean,
//     }
// ) => {
//     let formMethods: UseFormReturn<TFieldValues, any, any> | undefined;
//     const [rawAddress, setRawAddress] = useState(defaultRawAddress ?? '');
//     const [{ validatedAddress, validateAddressIsLoading, validateAddressAttempted }, setLoadingState] =
//         useState<{
//             validatedAddress: ValidAddress | undefined,
//             validateAddressIsLoading: boolean,
//             validateAddressAttempted: boolean
//         }>({
//             validatedAddress: undefined,
//             validateAddressIsLoading: false,
//             validateAddressAttempted: false
//         });
//     const [error, setError] = useState<AddressFormFieldControl["error"]>();

//     const validateAddress = async () => {
//         setLoadingState({ validatedAddress, validateAddressAttempted: true, validateAddressIsLoading: true });
//         const response = await getValidatedAddress(rawAddress);
//         if (response === undefined) {
//             setError({ message: "Invalid address" });
//         }
//         setLoadingState({ validatedAddress: response, validateAddressAttempted: true, validateAddressIsLoading: false });
//     }

//     const onAddressFieldBlur = () => () => {
//         if (validateAddressAttempted || options?.validateOnFirstBlur) {
//             validateAddress();
//         }
//     }

//     const resetAddressValidation = () => {
//         setRawAddress('');
//         setLoadingState({ validatedAddress: undefined, validateAddressAttempted: false, validateAddressIsLoading: false });
//     }

//     const addressFieldControl = {
//         value: rawAddress,
//         onChange: setRawAddress,
//         onBlur: onAddressFieldBlur,
//         error
//     };

//     const addressValidationResolver = <
//         TFieldValues extends FieldValues,
//         TContext extends any = any,
//         TOptions extends ResolverOptions<TFieldValues> = ResolverOptions<TFieldValues>
//     >(resolver: Resolver<TFieldValues, TContext>): Resolver<TFieldValues, TContext> => {
//         return (async (values: TFieldValues, context: TContext, options: TOptions) => {
//             const response = await getValidatedAddress(rawAddress);
//             if (response === undefined) {
//                 return { values, errors: assocPath(fieldPath, formMethods.formState.errors) }
//             } else {
//                 return await resolver(values, context, options);
//             }
//         }) as Resolver<TFieldValues, TContext>
//     }

//     useEffect(() => {
//         if (validatedAddress !== undefined) {
//             formMethods.clearErrors(fieldPath);
//             formMethods.setValue(fieldPath, validatedAddress as any);
//         } else if (validateAddressAttempted) {
//             formMethods.setError(fieldPath, { message: "Invalid address" });
//         }
//     }, [validatedAddress]);

//     return { validatedAddress, validateAddressIsLoading, validateAddressAttempted, validateAddress, addressValidationResolver, addressFieldControl, resetAddressValidation };
// }

export type UseAddressValidationReturn = {
	validatedAddress: Address | undefined;
	validateAddressIsLoading: boolean;
	validateAddressAttempted: boolean;
	// initAddressValidation: (formMethods: UseFormReturn<TFieldValues, any, any>, fieldPath: Path<TFieldValues>, disableValidation?: boolean) => void,
	validateAddress: () => Promise<void>;
	// addressValidationResolver: <
	//     TFieldValues extends FieldValues,
	//     TContext extends any = any,
	// >(resolver: Resolver<TFieldValues, TContext>) => Resolver<TFieldValues, TContext>,
	addressFieldControl: AddressFormFieldControl;
	setAddressValue: (value: string) => void;
	resetAddressValidation: () => void;
};

export const useAddressValidation = <TFieldValues extends FieldValues>(
	defaultRawAddress: string,
	formMethods: UseFormReturn<TFieldValues, any, any>,
	fieldPath: Path<TFieldValues>,
	options?: {
		validateOnFirstBlur?: boolean;
	},
): UseAddressValidationReturn => {
	const [rawAddress, setRawAddress] = useState(defaultRawAddress ?? "");
	const [
		{ validatedAddress, validateAddressIsLoading, validateAddressAttempted },
		setLoadingState,
	] = useState<{
		validatedAddress: Address | undefined;
		validateAddressIsLoading: boolean;
		validateAddressAttempted: boolean;
	}>({
		validatedAddress: undefined,
		validateAddressIsLoading: false,
		validateAddressAttempted: false,
	});
	const [error, setError] = useState<AddressFormFieldControl["error"]>();

	const validateAddress = async () => {
		setLoadingState({
			validatedAddress,
			validateAddressAttempted: true,
			validateAddressIsLoading: true,
		});
		const response = await getValidatedAddress(rawAddress);
		if (response === undefined) {
			setError({ message: "Invalid address" });
		}
		setLoadingState({
			validatedAddress: response,
			validateAddressAttempted: true,
			validateAddressIsLoading: false,
		});
	};

	const onAddressFieldBlur = () => {
		if (validateAddressAttempted || options?.validateOnFirstBlur) {
			validateAddress();
		}
	};

	const resetAddressValidation = () => {
		setRawAddress("");
		setLoadingState({
			validatedAddress: undefined,
			validateAddressAttempted: false,
			validateAddressIsLoading: false,
		});
	};

	const addressFieldControl = {
		value: rawAddress,
		onChange: setRawAddress,
		onBlur: onAddressFieldBlur,
		error: error,
	};

	useEffect(() => {
		formMethods.setValue(fieldPath, rawAddress as PathValue<TFieldValues, Path<TFieldValues>>, {
			shouldDirty: true,
		});
	}, [rawAddress]);

	useEffect(() => {
		if (validatedAddress !== undefined) {
			setError(undefined);
			formMethods.clearErrors(fieldPath);
			formMethods.setValue(fieldPath, formatAddress(validatedAddress) as any, {
				shouldDirty: true,
			});
		} else if (validateAddressAttempted) {
			formMethods.setError(fieldPath, { message: "Invalid address" });
		}
	}, [validatedAddress]);

	useEffect(() => {
		const fieldErrorMessage = path<FieldError | undefined>(
			fieldPath,
			formMethods.formState.errors,
		)?.message;
		if (fieldErrorMessage !== undefined) {
			setError({ message: fieldErrorMessage });
		}
	}, [formMethods.formState.errors]);

	return {
		validatedAddress,
		validateAddressIsLoading,
		validateAddressAttempted,
		validateAddress,
		addressFieldControl,
		setAddressValue: setRawAddress,
		resetAddressValidation,
	};
};

export const useGetFileUrl = () => {
	const { get } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (args: {
			baseUrl: string;
			asDownload: boolean;
			queryKey: QueryKey;
		}): Promise<string> => {
			const cachedData = queryClient.getQueryData<string>(args.queryKey);
			if (!!cachedData) return cachedData;
			return await get(`${args.baseUrl}?download=${args.asDownload ?? false}`, undefined, "text");
		},
		onSuccess: (data, { asDownload, queryKey }) => {
			if (asDownload) {
				const a: HTMLAnchorElement = document.createElement("a");
				a.href = data;
				a.style.display = "none";
				a.download = data.slice(data.lastIndexOf("/") + 1);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}
			queryClient.setQueryData(queryKey, data);
			queryClient.setQueryDefaults(queryKey, {
				staleTime: 60000,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				refetchOnReconnect: false,
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		getFileUrl: mutation.mutate,
		fileUrl: mutation.data,
		fileUrlError: mutation.error,
		fileUrlLoading: mutation.isLoading,
	};
};

export const useGetFileBuffer = () => {
	const { get } = useRequest();

	const getFileBuffer = (url: string, defaultFileName: string, asDownload: boolean = true) => {
		return get(url, undefined, "response")
			.then((r) => {
				const disposition = r.headers.get("Content-Disposition");
				let filename = defaultFileName;
				if (disposition && disposition.indexOf("attachment") !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) {
						filename = decodeURIComponent(matches[1]);
					}
				}
				return r.blob().then((blob) => ({ blob, filename }));
			})
			.then(({ blob, filename }) => {
				if (asDownload) {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					document.body.appendChild(link);
					link.click();
					link.parentNode?.removeChild(link);
					return;
				}
				return window.URL.createObjectURL(blob);
			});
	};

	const query = (url: string, defaultFileName: string, queryKey: QueryKey) => {
		const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

		return useQuery({
			queryKey: queryKey,
			queryFn: async () => await getFileBuffer(url, defaultFileName),
			retry: false,
			staleTime: 60000,
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			onError: (error: ApiError) => {
				console.error(error);
				triggerErrorModal(error);
			},
		});
	};

	return query;
};
