import type { Types } from "@inrev/inrev-common";
import type { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import type { SuretyAccountDraftSectionName } from "../../../../agent/account/status/draft/AccountDraftView";
import { _AccountDraftSummarySection } from "../../../../agent/account/status/draft/sections/AccountDraftSummarySection";

type AdminAccountReUnderwriteSummarySectionProps = {
	account: AdminSuretyAccount;
	draft: Types.SuretyAccount.Draft.Contract.Type;
	sections: { name: SuretyAccountDraftSectionName; label: string }[];
};

export const AdminAccountReUnderwriteSummarySection = ({
	draft,
	sections,
}: AdminAccountReUnderwriteSummarySectionProps) => {
	return <_AccountDraftSummarySection sections={sections} draft={draft} />;
};
