import { zodResolver } from "@hookform/resolvers/zod";
import {
	CompanyContactId,
	ContractSuretyBondFormType,
	ContractSuretyType,
	Dtos,
	SuretyAccountId,
	stripEmpty,
} from "@inrev/inrev-common";
import { createContext, useEffect, useMemo, useState } from "react";
import { FieldErrors, FieldPath, FormProvider, Resolver, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { Modal } from "../../../../../components/layout/Modal";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { SpinnerCheck } from "../../../../../components/ui/SpinnerCheck";
import { PrincipalPreview, SuretyAccountPreview } from "../../../../../domain/agent/account/types";
import {
	useAwaitedUpdateBondRequestDraft,
	useFetchBondFormTemplates,
	useSubmitBondRequest,
	useUpdateBondRequestDraft,
} from "../../../../../domain/agent/request/api";
import {
	BondFormTemplate,
	BondRequest,
	BondRequestDraftData,
	BondRequestDraftSchema,
} from "../../../../../domain/agent/request/types";
import { getBondRequestDataValidationSchema } from "../../../../../domain/agent/request/validation";
import { useFetchAccountPreviews } from "../../../../../domain/shared/previews/api";
import { formatAddress } from "../../../../../utils";
import { stripEmptyResolver, useAutoSave } from "../../../../../utils/form";
import { DraftBidToFinalBondRequestView } from "./bid-to-final/DraftBidToFinalBondRequestView";
import { DraftBondRequestBondSection } from "./sections/DraftBondRequestBondSection";
import { DraftBondRequestFinancialsSection } from "./sections/DraftBondRequestFinancialsSection";
import { DraftBondRequestHistorySection } from "./sections/DraftBondRequestHistorySection";
import { DraftBondRequestPrincipalSection } from "./sections/DraftBondRequestPrincipalSection";
import { DraftBondRequestSummarySection } from "./sections/DraftBondRequestSummarySection";

type DraftBondRequestViewProps = {
	request: DraftBondRequest;
};
type DraftBondRequest = BondRequest & Extract<BondRequest, { status: "draft" }>;
export type DraftBondRequestSectionName = keyof BondRequestDraftSchema | "summary";

const getSectionsFromDraft = (
	schema: DraftBondRequest["draft"]["schema"],
	errors: FieldErrors<BondRequestDraftData>,
): { name: DraftBondRequestSectionName; label: string; error?: boolean }[] => {
	const sections: { name: DraftBondRequestSectionName; label: string }[] = (
		Object.entries(schema) as [keyof typeof schema, (typeof schema)[keyof typeof schema]][]
	)
		.sort((a, b) => a[1]!.index - b[1]!.index)
		.map((entry) => ({
			name: entry[0]!,
			label: entry[1]!.sectionLabel,
			error: errors[entry[0]!] !== undefined,
		}));

	return [...sections, { name: "summary", label: "Summary" }];
};
const draftBondRequestFormResolver: Resolver<
	BondRequestDraftData,
	{ draftSchema: BondRequestDraftSchema }
> = async (values, context, options) => {
	if (!context) throw new Error();
	return zodResolver(getBondRequestDataValidationSchema(context.draftSchema))(
		values,
		context.draftSchema,
		options,
	);
};

const getPrincipalPreviewsMapFromAccountPreviews = (
	accountPreviews: SuretyAccountPreview[],
): Record<CompanyContactId, PrincipalPreview> => {
	const principalPreviewsMap: Record<CompanyContactId, PrincipalPreview> = {};
	accountPreviews
		.filter((a) => a.status !== "draft")
		.forEach((account) => {
			const searchValues: string[] = [
				...account.companies.flatMap((company) => [company.name, formatAddress(company.address)]),
				...account.individuals.map((individual) => individual.name),
			];
			if (account.displayName) searchValues.push(account.displayName);
			account.companies.forEach((company) => {
				principalPreviewsMap[company.id] = {
					...company,
					companyContactId: company.id,
					accountId: account.id,
					accountDisplayName: account.displayName,
					searchValues,
				};
			});
		});
	return principalPreviewsMap;
};

export const DraftBondRequestContext = createContext<DraftBondRequest>(undefined!);
export const DraftBondRequestSectionsContext = createContext<
	{ name: DraftBondRequestSectionName; label: string }[]
>(undefined!);
export const DraftBondRequestPrincipalPreviewsContext = createContext<
	Record<CompanyContactId, PrincipalPreview>
>(undefined!);

const getFieldPathsToAwait = (
	bondType: ContractSuretyType | "",
): FieldPath<BondRequestDraftData>[] => {
	if (bondType === ContractSuretyType.bid_to_final) return [];
	return [
		"principal.newPrincipal",
		"principal.principalAccountId",
		"principal.exposureSize",
		"principal.companies",
		"principal.individuals",
		"bond.type",
		"bond.bondAmount",
		"bond.bidAmount",
		"bond.bondAmountPercentOfBid",
		"financials.statementDate",
		"financials.preparationMethod",
	];
};

export const getBondFormTemplates = (
	templates: BondFormTemplate[],
	bondType?: ContractSuretyType,
) => {
	if (bondType === ContractSuretyType.final || bondType === ContractSuretyType.bid_to_final) {
		return templates.filter((t) => ["payment", "performance", "pnp"].includes(t.type));
	} else if (bondType === ContractSuretyType.bid) {
		return templates.filter((t) => t.type === ContractSuretyBondFormType.bid);
	} else return [];
};

export const bondFormTypesAndLabels = {
	pnp: "Performance & Payment",
	performance: "Performance",
	payment: "Payment",
	bid: "Bid",
	supply: "Supply",
};

export const DraftBondRequestView = ({ request }: DraftBondRequestViewProps) => {
	const { accountPreviews } = useFetchAccountPreviews();
	const principalPreviewsMap = useMemo(() => {
		return getPrincipalPreviewsMapFromAccountPreviews(accountPreviews || []);
	}, [accountPreviews]);
	const [section, setSection] = useState<DraftBondRequestSectionName | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentSection = useMemo(
		() => searchParams.get("section") as DraftBondRequestSectionName | null,
		[searchParams],
	);
	const formMethods = useForm<
		BondRequestDraftData,
		{ draftSchema: BondRequestDraftSchema },
		Dtos.SuretyQuote.Submit.Request["data"]
	>({
		reValidateMode: "onBlur",
		defaultValues: request.draft.data,
		context: { draftSchema: request.draft.schema },
		resolver: stripEmptyResolver(draftBondRequestFormResolver),
	});
	const newPrincipal = formMethods.watch("principal.newPrincipal");
	const newPrincipalName = formMethods.watch("principal.company.name");
	const principalAccountId = formMethods.watch("principal.principalAccountId");
	const principalCompanyId = formMethods.watch("principal.principalCompanyId");
	const bondType = formMethods.watch("bond.type");
	const bondFormTemplates = useFetchBondFormTemplates();
	const filteredBondFormTepmplates = useMemo(() => {
		return getBondFormTemplates(bondFormTemplates, stripEmpty(bondType));
	}, [bondFormTemplates, bondType]);
	const [principalName, setPrincipalName] = useState<string | undefined>(undefined);
	const sections = useMemo(
		() => getSectionsFromDraft(request.draft.schema, formMethods.formState.errors),
		[request.draft.schema, formMethods.formState],
	);
	const { updateBondRequestDraft } = useUpdateBondRequestDraft(request.id);
	const { awaitUpdateBondRequestDraft, awaitUpdateBondRequestDraftIsLoading } =
		useAwaitedUpdateBondRequestDraft(request.id);
	const { submitBondRequest, submitBondRequestIsLoading } = useSubmitBondRequest(request.id);
	const fieldPathsToAwait = useMemo(
		() => getFieldPathsToAwait(request.draft.data.bond.type),
		[request.draft.data.bond.type],
	);
	useAutoSave(
		updateBondRequestDraft,
		awaitUpdateBondRequestDraft,
		formMethods,
		request.draft.data,
		request.draft,
		fieldPathsToAwait,
	);
	// const [aiProcessing, setAIProcessing] = useState<boolean>(false);

	useEffect(() => {
		setSection(currentSection);
		if (currentSection === null || !sections.some((section) => section.name === currentSection)) {
			setSearchParams({ section: sections[0].name }, { replace: true });
		}
	}, [currentSection]);

	useEffect(() => {
		if (Object.keys(principalPreviewsMap).length > 0) {
			if (principalCompanyId === "") {
				if (!newPrincipal && principalName !== undefined) {
					setPrincipalName(undefined);
				}
				if (principalAccountId !== "") {
					formMethods.setValue("principal.principalAccountId", "", { shouldDirty: true });
				}
			} else if (principalAccountId === "" && accountPreviews) {
				const principalPreview = principalPreviewsMap[principalCompanyId];
				if (!principalPreview)
					throw new Error(
						`Could not find principal preview for principal with id ${principalCompanyId}`,
					);
				const accountId = principalPreview.accountId;
				if (!accountId)
					throw new Error(`Could not find account for principal with id ${principalCompanyId}`);
				formMethods.setValue("principal.principalAccountId", accountId as SuretyAccountId);
				setPrincipalName(principalPreview.name);
			}
		}
	}, [principalCompanyId, accountPreviews]);

	useEffect(() => {
		if (newPrincipal) {
			setPrincipalName(stripEmpty(newPrincipalName));
		}
	}, [newPrincipalName]);

	useEffect(() => {
		if (newPrincipal) {
			setPrincipalName(stripEmpty(newPrincipalName));
		} else if (
			stripEmpty(principalCompanyId) &&
			principalPreviewsMap[principalCompanyId as CompanyContactId] !== undefined
		) {
			setPrincipalName(principalPreviewsMap[principalCompanyId as CompanyContactId].name);
		} else {
			setPrincipalName(undefined);
		}
	}, [newPrincipal, principalCompanyId]);

	const onSubmit = (data: Dtos.SuretyQuote.Submit.Request["data"]) => {
		submitBondRequest({ data, draftData: formMethods.getValues() });
		// setAIProcessing(true);
		// setTimeout(() => {
		//     submitBondRequest({ data, draftData: formMethods.getValues() });
		//     setAIProcessing(false);
		// }, 4000);
	};

	if (!accountPreviews) return <LoadingModal />;
	else
		return (
			<DraftBondRequestContext.Provider value={request}>
				<DraftBondRequestSectionsContext.Provider value={sections}>
					<DraftBondRequestPrincipalPreviewsContext.Provider value={principalPreviewsMap}>
						<FormProvider {...formMethods}>
							<form
								id="bond_request"
								onSubmit={formMethods.handleSubmit(onSubmit)}
								className="w-full h-full"
							>
								{request.draft.data.bond.type === ContractSuretyType.bid_to_final && (
									<DraftBidToFinalBondRequestView
										request={request}
										section={section}
										sections={sections}
										bondFormTypesAndLabels={bondFormTypesAndLabels}
										bondFormTemplates={filteredBondFormTepmplates}
										principalName={principalName}
									/>
								)}
								{request.draft.data.bond.type !== ContractSuretyType.bid_to_final && (
									<WorkflowLayout
										title={
											<div className="flex items-center space-x-[8px]">
												<span>
													{`${bondType === ContractSuretyType.bid ? "Bid " : bondType === ContractSuretyType.final ? "Final " : ""}`}
													Bond Request
												</span>
												{principalName !== undefined ? (
													<>
														<span className="text-gray-300">|</span>
														<span className="text-gray-500 text-[13px]">{principalName}</span>
													</>
												) : (
													<></>
												)}
											</div>
										}
									>
										{section === "principal" && (
											<DraftBondRequestPrincipalSection sections={sections} />
										)}
										{section === "financials" && (
											<DraftBondRequestFinancialsSection sections={sections} />
										)}
										{section === "history" && (
											<DraftBondRequestHistorySection sections={sections} />
										)}
										{section === "bond" && (
											<DraftBondRequestBondSection
												sections={sections}
												bondFormTypesAndLabels={bondFormTypesAndLabels}
												bondFormTemplates={filteredBondFormTepmplates}
											/>
										)}
										{section === "summary" && (
											<DraftBondRequestSummarySection
												sections={sections}
												bondFormTypesAndLabels={bondFormTypesAndLabels}
												bondFormTemplates={filteredBondFormTepmplates}
											/>
										)}
									</WorkflowLayout>
								)}
							</form>
							{awaitUpdateBondRequestDraftIsLoading && <LoadingModal />}
							{submitBondRequestIsLoading && (
								<Modal>
									<div
										className={
											"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
										}
									>
										{/* <div className="flex items-center space-x-[10px]">
                                        <SpinnerCheck spinning={aiProcessing} className="bg-violet-700" />
                                        <div className="flex-1">
                                            AI is analyzing your request
                                        </div>
                                    </div>
                                    {submitBondRequestIsLoading && */}
										<div className="flex items-center space-x-[10px]">
											<SpinnerCheck spinning={submitBondRequestIsLoading} />
											<div className="flex-1 text-gray-800">Underwriting</div>
										</div>
										{/* } */}
									</div>
								</Modal>
							)}
						</FormProvider>
					</DraftBondRequestPrincipalPreviewsContext.Provider>
				</DraftBondRequestSectionsContext.Provider>
			</DraftBondRequestContext.Provider>
		);
};
