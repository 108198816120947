import { Dtos, FinancialStatementId, SuretyAccountId } from "@inrev/inrev-common";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { AdminSuretyAccount } from "../account/types";

export const useAdminUpsertFinancialStatement = (accountId: SuretyAccountId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["upsertFinancialStatement"],
		mutationFn: async ({
			data,
			financialStatementId,
		}: {
			data: Dtos.Admin.FinancialStatement.Create.Request;
			financialStatementId?: FinancialStatementId;
		}) => {
			await post(
				`/v2/admin/surety/financial-statements${financialStatementId ? `/${financialStatementId}` : ""}`,
				data,
				"none",
			);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ["suretyAccounts", accountId],
			});
			await queryClient.refetchQueries({
				queryKey: ["suretyAccounts", accountId],
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		upsertFinancialStatement: mutation.mutate,
		upsertFinancialStatementIsLoading: mutation.isLoading,
		upsertFinancialStatementSuccess: mutation.isSuccess,
	};
};

export const useAdminDeleteFinancialStatement = (accountId: SuretyAccountId) => {
	const queryClient = useQueryClient();
	const { _delete } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["deleteFinancialStatement"],
		mutationFn: async (statementId: FinancialStatementId) => {
			await _delete(`/v2/admin/surety/financial-statements/${statementId}`, undefined, "none");
		},
		onMutate: (statementId: FinancialStatementId) => {
			const previousData = queryClient.getQueryData<AdminSuretyAccount>([
				"suretyAccounts",
				accountId,
			]);
			if (previousData) {
				const newData: AdminSuretyAccount = {
					...previousData,
					financialStatements: previousData.financialStatements.filter(
						(statement) => statement.id !== statementId,
					),
					contract: {
						...previousData.contract,
						underwriting: {
							...previousData.contract.underwriting,
							stale: true,
						},
					},
				};
				queryClient.setQueryData<AdminSuretyAccount>(["suretyAccounts", accountId], newData);
			}
			return previousData;
		},
		onError: (error: ApiError, _statementId, previousData) => {
			if (previousData) {
				queryClient.setQueryData(["suretyAccounts", accountId], previousData);
			}
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		deleteFinancialStatement: mutation.mutate,
		deleteFinancialStatementIsLoading: mutation.isLoading,
		deleteFinancialStatementSuccess: mutation.isSuccess,
	};
};
