import { CompanyContactId, FileType } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useGetFileUrl } from "../../../../../../api";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { FormItem } from "../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../components/layout/form/FormRow";
import { FormSection } from "../../../../../../components/layout/form/FormSection";
import { ActionCard } from "../../../../../../components/ui/ActionCard";
import { LineItemStackedCard } from "../../../../../../components/ui/LineItemStackedCard";
import { FileUpload } from "../../../../../../components/ui/Upload";
import { FormCurrencyLineItem } from "../../../../../../components/ui/form/FormCurrencyLineItem";
import { FormDatePicker } from "../../../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNoLineItem } from "../../../../../../components/ui/form/FormYesNoLineItem";
import { financialsPreparationMethodOptions } from "../../../../../../constants";
import { BondRequestDraftData } from "../../../../../../domain/agent/request/types";
import { useScrollToId } from "../../../../../../utils";
import {
	DraftBondRequestContext,
	DraftBondRequestPrincipalPreviewsContext,
} from "../DraftBondRequestView";

type DraftBondRequestFinancialsSectionProps = {
	sections: { name: string; label: string }[];
};

export const DraftBondRequestFinancialsSection = (
	props: DraftBondRequestFinancialsSectionProps,
) => {
	const request = useContext(DraftBondRequestContext);
	const principalPreviews = useContext(DraftBondRequestPrincipalPreviewsContext);
	const { control, watch, formState } = useFormContext<BondRequestDraftData>();
	const { getFileUrl } = useGetFileUrl();
	const domReadyRef = useScrollToId();
	const filesController = useController({
		control,
		name: "financials.files",
	});
	const companyDraftId = watch("financials.companyDraftId");
	const companyName = useMemo(() => {
		if (companyDraftId !== "") {
			const company = principalPreviews[companyDraftId as CompanyContactId];
			if (!company) console.error(new Error(`Company with draftId ${companyDraftId} not found`));
			return company?.name;
		}
	}, [principalPreviews]);
	const principalCompany = watch("principal.company");
	const principalCompanies = watch("principal.companies");
	const companyDraftIdOptions = useMemo(() => {
		if (request.draft.schema.financials?.readonly?.companyDraftId && companyName !== undefined) {
			return [{ value: companyDraftId, label: companyName }];
		}
		let options: { value: string; label: string }[] = [
			{
				value: principalCompany.draftId,
				label: principalCompany.name === "" ? "The principal" : principalCompany.name,
			},
		];
		if (principalCompanies.length) {
			options = [
				...options,
				...principalCompanies.map((company) => ({ value: company.draftId, label: company.name })),
			];
		}
		return options;
	}, [
		principalCompany,
		principalCompanies,
		request.draft.schema.financials?.readonly?.companyDraftId,
	]);
	const statementDate = watch("financials.statementDate");
	const formattedStatementDate = useMemo(() => {
		if (statementDate === "") return "";
		else return `Dated ${DateTime.fromISO(statementDate).toFormat("MM/dd/yyyy")}`;
	}, [statementDate]);
	const formattedSectionSubtext = useMemo(() => {
		return (
			<>
				{companyName && <div className="mb-[2px]">For {companyName}</div>}
				<div>{formattedStatementDate}</div>
			</>
		);
	}, [companyName, formattedStatementDate]);

	return (
		<ShrinkingHeaderSectionNavLayout headerTitle="Financials" sections={props.sections}>
			{request.draft.data.financials.outdatedFinancials && (
				<ActionCard className="mt-[20px] mb-[10px]">
					<div className="flex flex-col gap-y-[6px] w-full h-fit">
						<span className="text-[15px] text-gray-800 font-medium">
							The latest FYE financial statement is out of date.
						</span>
						<span className="text-[14px] text-gray-700 font-normal">
							Please provide the most recent FYE financial statement below as soon as it is
							available.
						</span>
					</div>
				</ActionCard>
			)}
			<div
				ref={domReadyRef}
				className="flex flex-col pt-[18px] pb-[125px] min-h-fit space-y-[40px]"
			>
				<FormItemGroup>
					<FormItem
						schemaInclude={
							request.draft.schema.financials?.include.companyDraftId ||
							request.draft.schema.financials?.readonly?.companyDraftId
						}
						id="companyDraftId"
					>
						<FormQuestionLabel marker>
							Which company do you have FYE financials for?
						</FormQuestionLabel>
						<FormDropdown
							name="financials.companyDraftId"
							control={control}
							placeholder="Select one"
							options={companyDraftIdOptions}
							readonly={request.draft.schema.financials?.readonly?.companyDraftId}
						/>
					</FormItem>
					<FormRow
						schemaInclude={
							(request.draft.schema.financials?.include.statementDate &&
								request.draft.schema.financials?.include.preparationMethod) ||
							(request.draft.schema.financials?.readonly?.statementDate &&
								request.draft.schema.financials?.readonly?.preparationMethod)
						}
					>
						<FormItem
							schemaInclude={
								request.draft.schema.financials?.include.statementDate ||
								request.draft.schema.financials?.readonly?.statementDate
							}
							className="max-w-fit shrink-0"
							id="statementDate"
						>
							<FormItemLabel marker>Statement Date</FormItemLabel>
							<FormDatePicker
								name="financials.statementDate"
								control={control}
								defaultMonth={DateTime.now().minus({ years: 1 }).endOf("year")}
								readonly={request.draft.schema.financials?.readonly?.statementDate}
							/>
						</FormItem>
						<FormItem
							schemaInclude={
								request.draft.schema.financials?.include.preparationMethod ||
								request.draft.schema.financials?.readonly?.preparationMethod
							}
							id="preparationMethod"
						>
							<FormItemLabel marker>Preparation Method</FormItemLabel>
							<FormDropdown
								name="financials.preparationMethod"
								control={control}
								placeholder="Select one"
								options={financialsPreparationMethodOptions}
								readonly={request.draft.schema.financials?.readonly?.preparationMethod}
							/>
						</FormItem>
					</FormRow>
				</FormItemGroup>
				<div className="w-full h-fit flex flex-col space-y-[35px]">
					<LineItemStackedCard
						schemaInclude={
							request.draft.schema.financials?.include.balanceSheet ||
							request.draft.schema.financials?.readonly?.balanceSheet
						}
						className="w-full h-fit"
						id="balanceSheet"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Balance Sheet
									</div>
									<div className="text-[14px] text-gray-600 leading-[18px]">
										{formattedSectionSubtext}
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
					>
						<FormCurrencyLineItem
							label="Cash"
							marker
							name="financials.balanceSheet.corporateCash"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.corporateCash ||
								request.draft.schema.financials?.readonly?.balanceSheet?.corporateCash
							}
							id="corporateCash"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.corporateCash}
						/>
						<FormCurrencyLineItem
							label="Accounts Receivable (< 90 days)"
							marker
							name="financials.balanceSheet.accountsReceivable"
							control={control}
							tooltip="<table width='500'><tr><td>Subtract Accounts Receivable older than 90 days. If an Aging Schedule is not available, subtract 10% of Accounts Receivable.</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.accountsReceivable ||
								request.draft.schema.financials?.readonly?.balanceSheet?.accountsReceivable
							}
							id="accountsReceivable"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.accountsReceivable}
						/>
						<FormCurrencyLineItem
							label="Underbillings"
							marker
							name="financials.balanceSheet.underbillings"
							control={control}
							tooltip="<table width='500'><tr><td>This current asset is typically listed as “Underbillings” or “Costs in Excess of Billings”. This can be found in the Assets section of the balance sheet or as a total on the WIP.</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.underbillings ||
								request.draft.schema.financials?.readonly?.balanceSheet?.underbillings
							}
							id="underbillings"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.underbillings}
						/>
						<FormCurrencyLineItem
							label="Current Assets"
							marker
							name="financials.balanceSheet.currentAssets"
							control={control}
							tooltip="<strong>Subtract the following:</strong>
                            <ul>
                               <li>Accounts Receivable older than 90 days. If an Aging Schedule is not available, subtract 10% of Accounts Receivable</li>
                               <li>50% of Inventory</li>
                               <li>Pre-Paid Expenses</li>
                               <li>Other Current Assets</li>
                               <li>Receivables and/or Loans due from owners/shareholders/affiliates</li>
                            </ul>
                            "
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.currentAssets ||
								request.draft.schema.financials?.readonly?.balanceSheet?.currentAssets
							}
							id="currentAssets"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.currentAssets}
						/>
						<FormCurrencyLineItem
							label="Total Assets"
							marker
							name="financials.balanceSheet.totalAssets"
							control={control}
							tooltip="<strong>Subtract the following:</strong>
                            <ul>
                                <li>Receivables and/or Loans due from owners/shareholders/affiliates</li>
                                <li>Goodwill</li>
                                <li>Intangibles</li>
                            </ul>"
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.totalAssets ||
								request.draft.schema.financials?.readonly?.balanceSheet?.totalAssets
							}
							id="totalAssets"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.totalAssets}
						/>
						<FormCurrencyLineItem
							label="Accounts Payable"
							marker
							name="financials.balanceSheet.accountsPayable"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.accountsPayable ||
								request.draft.schema.financials?.readonly?.balanceSheet?.accountsPayable
							}
							id="accountsPayable"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.accountsPayable}
						/>
						<FormCurrencyLineItem
							label="Credit Cards Payable"
							marker
							name="financials.balanceSheet.creditCardsPayable"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.creditCardsPayable ||
								request.draft.schema.financials?.readonly?.balanceSheet?.creditCardsPayable
							}
							id="creditCardsPayable"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.creditCardsPayable}
						/>
						<FormCurrencyLineItem
							label="Revolving Line of Credit"
							marker
							name="financials.balanceSheet.bankLinePayable"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.bankLinePayable ||
								request.draft.schema.financials?.readonly?.balanceSheet?.bankLinePayable
							}
							id="bankLinePayable"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.bankLinePayable}
						/>
						<FormCurrencyLineItem
							label="Accrued Expenses"
							marker
							name="financials.balanceSheet.accruedExpenses"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.accruedExpenses ||
								request.draft.schema.financials?.readonly?.balanceSheet?.accruedExpenses
							}
							id="accruedExpenses"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.accruedExpenses}
						/>
						<FormCurrencyLineItem
							label="Overbillings"
							marker
							name="financials.balanceSheet.overbillings"
							control={control}
							tooltip="<table width='500'><tr><td>This current liability is typically listed as “Overbillings” or “Billings in Excess of Costs”. It can also be found as a total on  a percentage of completion WIP.  If no Overbillings are listed, put “0”.</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.overbillings ||
								request.draft.schema.financials?.readonly?.balanceSheet?.overbillings
							}
							id="overbillings"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.overbillings}
						/>
						<FormCurrencyLineItem
							label="Current Portion of Long Term Debt"
							marker
							name="financials.balanceSheet.currentPortionOfLtd"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.currentPortionOfLtd ||
								request.draft.schema.financials?.readonly?.balanceSheet?.currentPortionOfLtd
							}
							id="currentPortionOfLtd"
							readonly={
								request.draft.schema.financials?.readonly?.balanceSheet?.currentPortionOfLtd
							}
						/>
						<FormCurrencyLineItem
							label="Current Liabilities"
							marker
							name="financials.balanceSheet.currentLiabilities"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.currentLiabilities ||
								request.draft.schema.financials?.readonly?.balanceSheet?.currentLiabilities
							}
							id="currentLiabilities"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.currentLiabilities}
						/>
						<FormCurrencyLineItem
							label="Term Loan Debt"
							marker
							name="financials.balanceSheet.termLoanDebt"
							control={control}
							tooltip="<table width='500'><tr><td>The balance of repayments owed to financial institutions for loans issued. Unlike revolving credit lines, these loans have a specified amount and  repayment schedule</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.termLoanDebt ||
								request.draft.schema.financials?.readonly?.balanceSheet?.termLoanDebt
							}
							id="termLoanDebt"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.termLoanDebt}
						/>
						<FormCurrencyLineItem
							label="Total Liabilities"
							marker
							name="financials.balanceSheet.totalLiabilities"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.balanceSheet?.totalLiabilities ||
								request.draft.schema.financials?.readonly?.balanceSheet?.totalLiabilities
							}
							id="totalLiabilities"
							readonly={request.draft.schema.financials?.readonly?.balanceSheet?.totalLiabilities}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Income Statement
									</div>
									<div className="text-[14px] text-gray-600 leading-[18px]">
										{formattedSectionSubtext}
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={
							request.draft.schema.financials?.include.incomeStatement ||
							request.draft.schema.financials?.readonly?.incomeStatement
						}
						id="incomeStatement"
					>
						<FormCurrencyLineItem
							label="Revenue"
							marker
							name="financials.incomeStatement.revenue"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.incomeStatement?.revenue ||
								request.draft.schema.financials?.readonly?.incomeStatement?.revenue
							}
							id="revenue"
							readonly={request.draft.schema.financials?.readonly?.incomeStatement?.revenue}
						/>
						<FormCurrencyLineItem
							label="General Administrative Expense"
							marker
							name="financials.incomeStatement.gaExpense"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.incomeStatement?.gaExpense ||
								request.draft.schema.financials?.readonly?.incomeStatement?.gaExpense
							}
							id="gaExpense"
							readonly={request.draft.schema.financials?.readonly?.incomeStatement?.gaExpense}
						/>
						<FormYesNoLineItem
							label="Profitable YTD"
							marker
							name="financials.incomeStatement.profitableYtd"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.incomeStatement?.profitableYtd ||
								request.draft.schema.financials?.readonly?.incomeStatement?.profitableYtd
							}
							id="profitableYtd"
							readonly={request.draft.schema.financials?.readonly?.incomeStatement?.profitableYtd}
						/>
						<FormYesNoLineItem
							label="Profitable Last FYE"
							marker
							name="financials.incomeStatement.profitableLastFye"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.incomeStatement?.profitableLastFye ||
								request.draft.schema.financials?.readonly?.incomeStatement?.profitableLastFye
							}
							id="profitableLastFye"
							readonly={
								request.draft.schema.financials?.readonly?.incomeStatement?.profitableLastFye
							}
						/>
						<FormYesNoLineItem
							label="Profitable year prior to last fiscal year"
							marker
							name="financials.incomeStatement.profitableYearPriorToLastFiscalYear"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.incomeStatement
									?.profitableYearPriorToLastFiscalYear ||
								request.draft.schema.financials?.readonly?.incomeStatement
									?.profitableYearPriorToLastFiscalYear
							}
							id="profitableYearPriorToLastFiscalYear"
							readonly={
								request.draft.schema.financials?.readonly?.incomeStatement
									?.profitableYearPriorToLastFiscalYear
							}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Work In Progress
									</div>
									<div className="text-[14px] text-gray-600 leading-[18px]">
										{formattedSectionSubtext}
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={
							request.draft.schema.financials?.include.wip ||
							request.draft.schema.financials?.readonly?.wip
						}
						id="wip"
					>
						<FormCurrencyLineItem
							label="Backlog Cost-to-Complete"
							marker
							name="financials.wip.projectBacklogCost"
							control={control}
							tooltip="<table width='500'><tr><td><strong>Exclude</strong> the bond amount you're applying for.<br/><br/>This is the balance of cost left to complete on all jobs in progress. It is usually listed on the Work in Progress statement as “Cost to Complete” or “CTC”</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.wip?.projectBacklogCost ||
								request.draft.schema.financials?.readonly?.wip?.projectBacklogCost
							}
							id="projectBacklogCost"
							readonly={request.draft.schema.financials?.readonly?.wip?.projectBacklogCost}
						/>
						<FormCurrencyLineItem
							label="Gross Profit in the Backlog"
							marker
							name="financials.wip.projectBacklogGrossProfit"
							control={control}
							tooltip="<table width='500'><tr><td>This is the total gross profit to be earned on all uncompleted work (total estimated gross profit for all projects - gross profit earned to date for all projects). It can be found on the Work in Progress statement and is usually listed as “Gross Profit Remaining”</td></tr></table>"
							schemaInclude={
								request.draft.schema.financials?.include.wip?.projectBacklogGrossProfit ||
								request.draft.schema.financials?.readonly?.wip?.projectBacklogGrossProfit
							}
							id="projectBacklogGrossProfit"
							readonly={request.draft.schema.financials?.readonly?.wip?.projectBacklogGrossProfit}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Bank Details
									</div>
									<div className="text-[14px] text-gray-600 leading-[18px]">
										{formattedSectionSubtext}
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={
							request.draft.schema.financials?.include.bankDetails ||
							request.draft.schema.financials?.readonly?.bankDetails
						}
						id="bankDetails"
					>
						<FormCurrencyLineItem
							label="Revolving Line of Credit Limit"
							marker
							name="financials.bankDetails.blocSize"
							control={control}
							schemaInclude={
								request.draft.schema.financials?.include.bankDetails?.blocSize ||
								request.draft.schema.financials?.readonly?.bankDetails?.blocSize
							}
							id="blocSize"
							readonly={request.draft.schema.financials?.readonly?.bankDetails?.blocSize}
						/>
					</LineItemStackedCard>
				</div>
				<FormSection
					topPadding
					header="Financial Documents"
					marker
					subHeader="Please upload the following documents"
					schemaInclude={request.draft.schema.financials?.include.files !== undefined}
					id="documents"
				>
					<FileUpload
						value={filesController.field.value}
						onChange={filesController.field.onChange}
						onBlur={filesController.field.onBlur}
						onDownload={(fileId) =>
							getFileUrl({
								baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
								queryKey: [`downloadQuoteDraftFile`, fileId, true],
								asDownload: true,
							})
						}
						allowedTypesAndLabels={{
							[FileType.fye_company_balance_sheet]: "FYE Balance Sheet",
							[FileType.fye_company_income_statement]: "FYE Income Statement",
						}}
						requiredTypes={[
							FileType.fye_company_balance_sheet,
							FileType.fye_company_income_statement,
						]}
						maxFiles={
							request.draft.schema.financials?.readonly?.files !== undefined ? 0 : undefined
						}
						maxTypesPerFile={2}
						showErrors={formState.isSubmitted}
						className="w-[450px]"
						preventDelete={request.draft.schema.financials?.readonly?.files !== undefined}
					/>
				</FormSection>
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};
