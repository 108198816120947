import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { FormItem } from "../../../../../../components/layout/form/FormItem";
import { Tooltip } from "../../../../../../components/ui/Tooltip";
import { FormCurrencyInput } from "../../../../../../components/ui/form/FormCurrencyInput";
import { FormNAICSCodeSelect } from "../../../../../../components/ui/form/FormNAICSCodeSelect";
import { FormQuestionLabel } from "../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../../components/ui/form/FormYesNo";
import { BondRequestDraftData } from "../../../../../../domain/agent/request/types";
import { useScrollToId } from "../../../../../../utils";
import { DraftBondRequestContext } from "../DraftBondRequestView";

type DraftBondRequestHistorySectionProps = {
	sections: { name: string; label: string }[];
};

export const DraftBondRequestHistorySection = (props: DraftBondRequestHistorySectionProps) => {
	const request = useContext(DraftBondRequestContext);
	const { control, formState } = useFormContext<BondRequestDraftData>();
	const domReadyRef = useScrollToId();

	return (
		<ShrinkingHeaderSectionNavLayout headerTitle="History" sections={props.sections}>
			<div
				ref={domReadyRef}
				className="flex flex-col pt-[18px] pb-[125px] min-h-fit space-y-[45px]"
			>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.naicsCodes ||
						request.draft.schema.history?.readonly?.naicsCodes
					}
					id="naicsCodes"
				>
					<FormQuestionLabel marker>
						What type of work does the account specialize in?
					</FormQuestionLabel>
					<FormNAICSCodeSelect
						name="history.naicsCodes"
						control={control}
						selectText="Select specialties"
						editText="Edit Specialties"
						searchPlaceholder="Search specialties..."
						error={formState.errors.history?.naicsCodes}
						readonly={request.draft.schema.history?.readonly?.naicsCodes}
						title={"Select Account Specialties"}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.largestProjectCompleted ||
						request.draft.schema.history?.readonly?.largestProjectCompleted
					}
					id="largestProjectCompleted"
				>
					<FormQuestionLabel marker>
						What is the value of the largest project completed by the account?
					</FormQuestionLabel>
					<FormCurrencyInput
						name="history.largestProjectCompleted"
						control={control}
						allowNegativeValue={false}
						className="w-[300px]"
						readonly={request.draft.schema.history?.readonly?.largestProjectCompleted}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.failedToCompleteAProject ||
						request.draft.schema.history?.readonly?.failedToCompleteAProject
					}
					id="failedToCompleteAProject"
				>
					<FormQuestionLabel marker>
						Has the account, its owners, or subsidiaries ever failed to complete a project?
					</FormQuestionLabel>
					<FormYesNo
						name="history.failedToCompleteAProject"
						control={control}
						readonly={request.draft.schema.history?.readonly?.failedToCompleteAProject}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.lostAPaymentSuit ||
						request.draft.schema.history?.readonly?.lostAPaymentSuit
					}
					id="lostAPaymentSuit"
				>
					<FormQuestionLabel marker>
						Has the account, its owners, or subsidiaries ever lost a payment suit?
					</FormQuestionLabel>
					<FormYesNo
						name="history.lostAPaymentSuit"
						control={control}
						readonly={request.draft.schema.history?.readonly?.lostAPaymentSuit}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.causedSuretyLoss ||
						request.draft.schema.history?.readonly?.causedSuretyLoss
					}
					id="causedSuretyLoss"
				>
					<FormQuestionLabel marker>
						Has the account, its owners, or subsidiaries ever caused a loss to a surety?
					</FormQuestionLabel>
					<FormYesNo
						name="history.causedSuretyLoss"
						control={control}
						readonly={request.draft.schema.history?.readonly?.causedSuretyLoss}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.constructionProjectGrossLoss ||
						request.draft.schema.history?.readonly?.constructionProjectGrossLoss
					}
					id="constructionProjectGrossLoss"
				>
					<FormQuestionLabel marker>
						In the past 3 years, has the account, its owners, or subsidiaries experienced a gross
						loss on any project greater than 10% of that project's contract value?
						<Tooltip
							id="history.constructionProjectGrossLoss"
							className="ml-[10px]"
							content="For example, a gross loss of at least $10,000 on a $100,000 project"
						/>
					</FormQuestionLabel>
					<FormYesNo
						name="history.constructionProjectGrossLoss"
						control={control}
						readonly={request.draft.schema.history?.readonly?.constructionProjectGrossLoss}
					/>
				</FormItem>
				<FormItem
					schemaInclude={
						request.draft.schema.history?.include.currentConstructionProjectGrossLoss ||
						request.draft.schema.history?.readonly?.currentConstructionProjectGrossLoss
					}
					id="currentConstructionProjectGrossLoss"
				>
					<FormQuestionLabel marker>
						Does the account, its owners, or subsidiaries expect a gross loss on a project they are
						currently working on?
					</FormQuestionLabel>
					<FormYesNo
						name="history.currentConstructionProjectGrossLoss"
						control={control}
						readonly={request.draft.schema.history?.readonly?.currentConstructionProjectGrossLoss}
					/>
				</FormItem>
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};
