import { formatToDollar } from "@inrev/inrev-common";
import { ReactNode } from "react";
import { HiExclamationTriangle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { cn } from "../../../components/lib/utils";
import { getStatusCardByStatus } from "../../../components/ui/StatusCard";
import { contractSuretyTypeToLabelMap } from "../../../constants";
import { AdminBondRequest } from "../../../domain/admin/request/types";
import { UseFollowersReturn } from "../../../domain/shared/followers/types";
import { AdminFollowerControls } from "./AdminFollowerControls";

type AdminBondRequestCardHeaderProps = {
	request: AdminBondRequest;
	actionRequired?: boolean;
	actionButtons?: ReactNode;
	followersControl: UseFollowersReturn;
};

export const AdminBondRequestCardHeader = ({
	request,
	actionRequired,
	actionButtons,
	followersControl,
}: AdminBondRequestCardHeaderProps) => {
	return (
		<div className="w-full h-fit flex space-x-[50px] px-[40px] pt-[30px] pb-[25px]">
			<div className="flex-1 flex flex-col space-y-[25px]">
				<div className="flex flex-col space-y-[20px]">
					<div className="flex flex-col space-y-[10px]">
						<div className="flex items-start space-x-[15px]">
							{actionRequired ? (
								<HiExclamationTriangle className="text-[22px] fill-inrev-yellow mt-[7px]" />
							) : (
								<></>
							)}
							<span className="text-[24px] text-gray-800 font-semibold leading-[32px]">
								{(request.contractSuretyType === "bid"
									? formatToDollar(request.bidAmount)
									: formatToDollar(request.bondAmount)) +
									` ${contractSuretyTypeToLabelMap[request.contractSuretyType]} Bond Request`}
							</span>
						</div>
						<div className="flex flex-col space-y-[12px] pl-[2px] text-[15px] font-normal text-gray-800 leading-[18px]">
							{request.account.primaryCompanyContactId === request.principal.companyContactId && (
								<span>
									for{" "}
									<span className="cursor-pointer hover:underline text-inrev-light-blue font-medium">
										{<Link to={`/accounts/${request.account.id}`}>{request.principal.name}</Link>}
									</span>
								</span>
							)}
							<div className="flex flex-col space-y-[1px] text-[13px] text-gray-600 font-regular italic">
								<span>Account: {request.account.displayName}</span>
								<span>Agency: {request.agencyDisplayName}</span>
								{request.agentName && <span>Agent: {request.agentName}</span>}
							</div>
						</div>
					</div>
					<AdminFollowerControls
						{...followersControl}
						manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this bond request"
						agencyId={request.agencyId}
					/>
				</div>
			</div>
			<div>
				<div
					className={cn(
						"flex flex-col items-end min-w-[165px] mb-[15px]",
						actionButtons ? "space-y-[20px]" : "space-y-[10px]",
					)}
				>
					{getStatusCardByStatus(request.status)}
					<div className="w-[165px] flex flex-col space-y-[5px]">
						{actionButtons && actionButtons}
					</div>
				</div>
			</div>
		</div>
	);
};
