import { zodResolver } from "@hookform/resolvers/zod";
import { CarrierName, Dtos } from "@inrev/inrev-common";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { cn } from "../../../components/lib/utils";
import { Button } from "../../../components/ui/Button";
import { DropdownProps } from "../../../components/ui/Dropdown";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { FormPercentInput } from "../../../components/ui/form/FormPercentInput";
import { useAdminUpdateBondRequestStatus } from "../../../domain/admin/request/api";
import {
	AdminBondRequest,
	AdminUpdateBondRequestFormData,
} from "../../../domain/admin/request/types";
import { carrierDisplayNameMap } from "../../../domain/common";
import { stripEmptyResolver } from "../../../utils/form";
import {
	AdminApproveBondFormFormFields,
	getDefaultFormData as getApproveBondFormDefaultFormData,
} from "./AdminApproveBondFormForAgentIssueForm";

type AdminUpdateBondRequestFormProps = {
	request: AdminBondRequest;
	onSubmit?: () => void;
	className?: string;
};

const getDefaultFormData = (
	request: AdminBondRequest,
	shouldApproveBondFormForAgentIssue: boolean,
): AdminUpdateBondRequestFormData => ({
	suretyType: request.suretyType,
	contractSuretyType: request.contractSuretyType,
	status: request.status === "approved" || request.status === "declined" ? request.status : "",
	carrierName: request.carrier?.name ?? "",
	rate: request.rate?.toString() ?? request.account.rate?.toString() ?? "",
	finalBondRate:
		"finalBondContingentRate" in request ? request.finalBondContingentRate?.toString() ?? "" : "",
	agencyCommissionPercentage: request.agencyCommissionPercentage?.toString() ?? "",
	reviewNote: request.reviewNote ?? "",
	...(() => {
		if (shouldApproveBondFormForAgentIssue) {
			return {
				bondForm: getApproveBondFormDefaultFormData(request),
			};
		} else {
			return {};
		}
	})(),
});

export const AdminUpdateBondRequestForm = ({
	request,
	onSubmit,
	className,
}: AdminUpdateBondRequestFormProps) => {
	const { updateBondRequestStatus, updateBondRequestStatusIsLoading } =
		useAdminUpdateBondRequestStatus(request.id);
	const canApprove = useMemo(() => {
		return request.account.underwritingStatus === "approved" || request.status === "approved";
	}, [request.account.underwritingStatus, request.status]);
	const shouldApproveBondFormForAgentIssue =
		request.bondForm.source === "upload" && !request.bondForm.approvedBeforeIssuance;

	const formMethods = useForm<
		AdminUpdateBondRequestFormData,
		any,
		Dtos.Admin.SuretyQuote.UpdateStatus.Request
	>({
		defaultValues: getDefaultFormData(request, shouldApproveBondFormForAgentIssue),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Admin.SuretyQuote.UpdateStatus.Request.schema)),
	});

	const handleSubmit = (data: Dtos.Admin.SuretyQuote.UpdateStatus.Request) => {
		updateBondRequestStatus(data);
	};

	useEffect(() => {
		if (onSubmit && !updateBondRequestStatusIsLoading && formMethods.formState.submitCount > 0) {
			onSubmit();
		}
	}, [updateBondRequestStatusIsLoading]);

	const status = formMethods.watch("status");
	const statusOptions = useMemo(() => {
		const options: DropdownProps["options"] = [{ value: "declined", label: "Declined" }];
		if (canApprove) {
			options.unshift({ value: "approved", label: "Approved" });
		}
		return options;
	}, [request.account.underwritingStatus]);

	const carrier = formMethods.watch("carrierName");
	useEffect(() => {
		if (carrier && !request.agencyCommissionPercentage) {
			const carrierRelationship = request.carrierRelationships.find((cr) => cr.name === carrier);
			formMethods.setValue(
				"agencyCommissionPercentage",
				carrierRelationship ? carrierRelationship.agencyCommissionPercentage.toString() : "",
			);
		}
	}, [carrier, formMethods.setValue]);

	return (
		<div className={cn("px-[40px] pb-[30px]", className)}>
			<form>
				<FormItemGroup>
					<FormItem>
						<FormItemLabel>Status</FormItemLabel>
						<FormDropdown
							name="status"
							control={formMethods.control}
							options={statusOptions}
							placeholder="Select one"
							condensed
						/>
						{!canApprove && (
							<div className="flex items-center space-x-[8px] text-[13px] text-gray-500 mt-[5px] italic">
								<HiOutlineExclamationTriangle className="text-[16px] text-yellow-500 stroke-[2] fill-yellow-50" />
								<span>Cannot approve request until account is approved</span>
							</div>
						)}
					</FormItem>
					{status === "approved" && (
						<>
							<FormItem>
								<FormItemLabel>Carrier</FormItemLabel>
								<FormDropdown
									name="carrierName"
									control={formMethods.control}
									options={Object.keys(carrierDisplayNameMap).map((carrierName) => ({
										value: carrierName,
										label: carrierDisplayNameMap[carrierName as CarrierName],
									}))}
									placeholder="Select one"
									condensed
								/>
							</FormItem>
							<FormRow>
								<FormItem>
									<FormItemLabel>
										{request.contractSuretyType === "bid" ? "Final Bond Rate" : "Rate"}
									</FormItemLabel>
									<FormPercentInput
										name={request.contractSuretyType === "bid" ? "finalBondRate" : "rate"}
										control={formMethods.control}
										condensed
										precision={3}
									/>
								</FormItem>
								<FormItem>
									<FormItemLabel>Agency Commission</FormItemLabel>
									<FormPercentInput
										name="agencyCommissionPercentage"
										control={formMethods.control}
										condensed
									/>
								</FormItem>
							</FormRow>
							{shouldApproveBondFormForAgentIssue && (
								<AdminApproveBondFormFormFields
									formMethods={formMethods}
									fieldPaths={{
										bondFormType: "bondForm.bondFormType",
										cancellable: "bondForm.cancellable",
										continuous: "bondForm.continuous",
									}}
									request={request}
								/>
							)}
						</>
					)}
					<div className="flex w-full justify-end pt-[10px]">
						<ConfirmationModalClickWrapper
							message="Are you sure?"
							onConfirm={formMethods.handleSubmit(handleSubmit)}
						>
							<Button
								color={status === "approved" ? "green" : status === "declined" ? "red" : "gray"}
								className="px-[25px] py-[12px]"
								filled
								disabled={status === ""}
							>
								{status === "approved" ? "Approve & " : status === "declined" ? "Decline & " : ""}
								Save
							</Button>
						</ConfirmationModalClickWrapper>
					</div>
				</FormItemGroup>
			</form>
			{updateBondRequestStatusIsLoading && <LoadingModal />}
		</div>
	);
};
