import { forwardRef, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useNumericFormat } from "react-number-format";
import { formatNegativesAndLeadingZeros } from "../../utils";
import { Input, InputProps } from "./Input";

type PercentInputProps = Omit<InputProps, "value" | "type" | "prefix"> & {
	defaultValue: string | undefined;
	precision?: number;
	min?: number;
	max?: number;
	className?: string;
	condensed?: true;
	compact?: true;
	error?: FieldError;
};

export const PercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
	(props: PercentInputProps) => {
		const { format } = useNumericFormat({
			displayType: "input",
			decimalScale: props.precision ?? 0,
			allowLeadingZeros: false,
			valueIsNumericString: true,
		});
		const [internalValue, setInternalValue] = useState<string>(
			(() => {
				let value = props.defaultValue ?? "";
				const parsed = parseFloat(value);
				if (Number.isNaN(parsed)) return "";
				else return (parsed * 100).toString();
			})(),
		);

		useEffect(() => {
			const length = internalValue.length;
			if (
				internalValue !== "" &&
				internalValue[length - 1] !== "-" &&
				internalValue[length - 1] !== "."
			) {
				const parsed = parseFloat(internalValue);
				const formatted = (+(
					format?.(formatNegativesAndLeadingZeros(parsed.toString(), props.precision) ?? "") ?? ""
				)).toString();
				if (Number.isNaN(parsed)) {
					props.onChange?.({ target: { value: "" } } as any);
				} else if (formatted !== internalValue) {
					setInternalValue(formatted);
				} else {
					props.onChange?.({ target: { value: (parseFloat(formatted) / 100).toString() } } as any);
				}
			}
		}, [internalValue]);

		useEffect(() => {
			let value = props.defaultValue ?? "";
			const parsed = parseFloat(value);
			if (Number.isNaN(parsed)) value = "";
			else
				value = (+(
					format?.(formatNegativesAndLeadingZeros((parsed * 100).toString()) ?? "") ?? ""
				)).toString();
			if (value !== internalValue) setInternalValue(value);
		}, [props.defaultValue]);

		if (format === undefined) return <></>;
		return (
			<Input
				{...props}
				value={internalValue}
				onChange={(e) => {
					const preFormatted = formatNegativesAndLeadingZeros(e.target.value) ?? "";
					const length = preFormatted.length;
					if (preFormatted[length - 1] === "-" || preFormatted[length - 1] === ".")
						setInternalValue(preFormatted);
					else {
						const parsed = parseFloat(preFormatted);
						const clamped = Math.max(
							props.min ?? -Infinity,
							Math.min(props.max ?? Infinity, parsed),
						);
						if (Number.isNaN(parsed)) setInternalValue(format?.(preFormatted ?? ""));
						else if (parsed !== clamped) setInternalValue(clamped.toString());
						else setInternalValue(format?.(preFormatted ?? ""));
					}
				}}
				onBlur={(e) => {
					const parsed = parseFloat(internalValue);
					let value: string;
					if (Number.isNaN(parsed)) value = "";
					else value = parsed.toString();
					setInternalValue(value);
					props.onBlur?.(e);
				}}
				align="right"
				postfix="%"
			/>
		);
	},
);
