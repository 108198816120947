import { formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { SuretyBondPreview } from "../../../domain/agent/bond/types";
import { bondTypeLabelMap } from "../../../types";
import { formatEmpty } from "../../../utils";

export const bondTableColumns: ColumnDef<SuretyBondPreview>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 100,
		filterFn: "equalsString",
		cell: (cell) => getStatusTagByStatus(cell.row.original.status),
	},
	{
		id: "bondNumber",
		accessorKey: "number",
		header: "Bond Number",
		size: 100,
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		accessorFn: ({ principalName }) => formatEmpty(principalName),
		header: "Principal",
		size: 180,
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
		size: 200,
	},
	{
		id: "accountName",
		accessorKey: "accountName",
		accessorFn: ({ accountName }) => formatEmpty(accountName),
		header: "Account",
		size: 100,
	},
	{
		id: "effectiveDate",
		accessorKey: "effectiveDate",
		accessorFn: ({ effectiveDate }) => DateTime.fromISO(effectiveDate).toJSDate(),
		cell: (props) =>
			DateTime.fromISO(props.row.original.effectiveDate).toLocaleString(DateTime.DATE_MED),
		header: "Effective Date",
		size: 150,
		enableGlobalFilter: false,
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		accessorFn: ({ amount }) => formatEmpty(formatToDollar(amount)),
	},
	{
		id: "rate",
		accessorKey: "rate",
		header: "Rate",
		size: 130,
		accessorFn: ({ rate }) => formatToPercent(rate),
	},
	{
		id: "premium",
		accessorKey: "premium",
		header: "Premium",
		size: 130,
		accessorFn: ({ premium }) => formatToDollar(premium, true),
	},
	{
		id: "type",
		accessorKey: "type",
		header: "Type",
		size: 77,
		accessorFn: ({ type }) => bondTypeLabelMap[type],
	},
];
