import { Comments, CommentsProps } from "../ui/Comments";

type CommentsSectionProps = CommentsProps & {
	headerEnd?: React.ReactNode;
};

export const CommentsSection = (props: CommentsSectionProps) => {
	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">Comments</span>
				{!!props.headerEnd && props.headerEnd}
			</div>
			<Comments {...props} />
		</div>
	);
};
