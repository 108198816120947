import { formatToDollar } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { HiOutlineBuildingOffice2, HiOutlineCalendar } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { FollowerControls } from "../../../components/ui/FollowerControls";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { contractSuretyTypeToLabelMap } from "../../../constants";
import { SubmittedBondRequest } from "../../../domain/agent/request/types";
import { useFetchAgentPreviews } from "../../../domain/agent/user/api";
import { useFollowers } from "../../../domain/shared/followers/api";

type BondRequestCardHeaderProps = {
	request: SubmittedBondRequest;
	actionRequired?: boolean;
	actionButtons?: ReactNode;
};

export const BondRequestCardHeader = ({ request, actionButtons }: BondRequestCardHeaderProps) => {
	const navigate = useNavigate();
	const followersControl = useFollowers("quotes", request.id);

	return (
		<div className="w-full h-fit flex space-x-[50px] px-[40px] py-[30px]">
			<div className="flex-1 flex flex-col space-y-[30px]">
				<div className="flex flex-col space-y-[26.5px]">
					<div className="flex flex-col space-y-[16px]">
						{getStatusTagByStatus(request.status)}
						<div className="flex flex-col space-y-[10px]">
							<span className="text-[24px] text-gray-800 font-semibold leading-[32px]">
								{(request.contractSuretyType === "bid"
									? formatToDollar(request.bidAmount)
									: formatToDollar(request.bondAmount)) +
									` ${contractSuretyTypeToLabelMap[request.contractSuretyType]} Bond Request`}
							</span>
							<div className="flex flex-col space-y-[7px] pl-[2px] text-[14.5px] font-[425] text-gray-700 leading-[18px]">
								<span className="cursor-pointer">
									for{" "}
									<span
										className="hover:underline cursor-pointer"
										onClick={() => navigate(`/accounts/${request.account.id}`)}
									>
										{request.principal.name}
									</span>
								</span>
								<div className="flex flex-col space-y-[6px] text-gray-700">
									{request.account.primaryCompanyContactId !==
										request.principal.companyContactId && (
										<div className="flex items-center space-x-[5px]">
											<HiOutlineBuildingOffice2 className="text-[16px] stroke-[1.75]" />
											<span
												className="cursor-pointer hover:underline"
												onClick={() => navigate(`/accounts/${request.account.id}`)}
											>
												{request.account.displayName ?? request.account.primaryCompanyName}
											</span>
										</div>
									)}
									<div className="flex items-center space-x-[7px]">
										<HiOutlineCalendar className="text-[17px] stroke-[1.75]" />
										<span>
											Submitted{" "}
											{DateTime.fromISO(request.submittedAt).toLocaleString(DateTime.DATE_MED)}
										</span>
									</div>
									<div className="flex space-x-[15px] !mt-[28px]">
										<div className="h-full w-[2.5px] min-w-[2.5px] rounded-full bg-gray-200"></div>
										<div className="text-[13px] text-gray-600 leading-[22px] font-[425] italic">
											{request.description}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FollowerControls
						{...followersControl}
						useFetchAgentPreviews={useFetchAgentPreviews}
						notFollowingLabel="Follow this request"
						manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this bond request"
					/>
				</div>
			</div>
			<div>
				<div className="w-[165px] flex flex-col space-y-[5px]">
					{actionButtons && actionButtons}
				</div>
			</div>
		</div>
	);
};
