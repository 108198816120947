import { FileType, type SuretyAccountId, Types } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useGetFileUrl } from "../../../../../../api";
import { ShrinkingHeaderSectionNavLayout } from "../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { FormItem } from "../../../../../../components/layout/form/FormItem";
import { FormRow } from "../../../../../../components/layout/form/FormRow";
import { FormSection } from "../../../../../../components/layout/form/FormSection";
import { LineItemStackedCard } from "../../../../../../components/ui/LineItemStackedCard";
import { FileUpload } from "../../../../../../components/ui/Upload";
import { FormCurrencyLineItem } from "../../../../../../components/ui/form/FormCurrencyLineItem";
import { FormDatePicker } from "../../../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNoLineItem } from "../../../../../../components/ui/form/FormYesNoLineItem";
import { financialsPreparationMethodOptions } from "../../../../../../constants";
import { useScrollToId } from "../../../../../../utils";
import { SuretyAccountDraftContext } from "../AccountDraftView";

type _AccountDraftFinancialsSectionProps = {
	sections: { name: string; label: string }[];
	accountId: SuretyAccountId;
	draft: Types.SuretyAccount.Draft.Type;
};

export const _AccountDraftFinancialsSection = ({
	sections,
	accountId,
	draft,
}: _AccountDraftFinancialsSectionProps) => {
	const { control, watch, formState } = useFormContext<Types.SuretyAccount.Draft.Data>();
	const domReadyRef = useScrollToId();
	const filesController = useController({
		control,
		name: "financials.files",
	});
	const companies = watch("details.companies");
	const companyDraftIdOptions = useMemo(() => {
		return companies.map((company) => ({ value: company.draftId, label: company.name }));
	}, [companies]);
	const { getFileUrl } = useGetFileUrl();

	return (
		<ShrinkingHeaderSectionNavLayout headerTitle="Financials" sections={sections}>
			<div
				ref={domReadyRef}
				className="flex flex-col pt-[18px] pb-[125px] min-h-fit space-y-[40px]"
			>
				<FormItem
					schemaInclude={draft.schema.financials?.include.companyDraftId}
					id="companyDraftId"
				>
					<FormQuestionLabel marker>
						Which company do you have FYE financials for?
					</FormQuestionLabel>
					<FormDropdown
						name="financials.companyDraftId"
						control={control}
						placeholder="Select one"
						options={companyDraftIdOptions}
						readonly={draft.schema.financials?.readonly?.companyDraftId}
					/>
				</FormItem>
				<FormRow
					schemaInclude={
						draft.schema.financials?.include.statementDate &&
						draft.schema.financials?.include.preparationMethod
					}
				>
					<FormItem
						schemaInclude={draft.schema.financials?.include.statementDate}
						className="max-w-fit shrink-0"
						id="statementDate"
					>
						<FormItemLabel marker>Statement Date</FormItemLabel>
						<FormDatePicker
							name="financials.statementDate"
							control={control}
							defaultMonth={DateTime.now().minus({ years: 1 }).endOf("year")}
							readonly={draft.schema.financials?.readonly?.statementDate}
						/>
					</FormItem>
					<FormItem
						schemaInclude={draft.schema.financials?.include.preparationMethod}
						id="preparationMethod"
					>
						<FormItemLabel marker>Preparation Method</FormItemLabel>
						<FormDropdown
							name="financials.preparationMethod"
							control={control}
							placeholder="Select one"
							options={financialsPreparationMethodOptions}
							readonly={draft.schema.financials?.readonly?.preparationMethod}
						/>
					</FormItem>
				</FormRow>
				<div className="w-full h-fit flex flex-col space-y-[35px]">
					<LineItemStackedCard
						schemaInclude={draft.schema.financials?.include.balanceSheet}
						className="w-full h-fit"
						id="balanceSheet"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Balance Sheet
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
					>
						<FormCurrencyLineItem
							label="Cash"
							marker
							name="financials.balanceSheet.corporateCash"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.corporateCash}
							id="corporateCash"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.corporateCash}
						/>
						<FormCurrencyLineItem
							label="Accounts Receivable (< 90 days)"
							marker
							name="financials.balanceSheet.accountsReceivable"
							control={control}
							tooltip="<table width='500'><tr><td>Subtract Accounts Receivable older than 90 days. If an Aging Schedule is not available, subtract 10% of Accounts Receivable.</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.balanceSheet?.accountsReceivable}
							id="accountsReceivable"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.accountsReceivable}
						/>
						<FormCurrencyLineItem
							label="Underbillings"
							marker
							name="financials.balanceSheet.underbillings"
							control={control}
							tooltip="<table width='500'><tr><td>This current asset is typically listed as “Underbillings” or “Costs in Excess of Billings”. This can be found in the Assets section of the balance sheet or as a total on the WIP.</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.balanceSheet?.underbillings}
							id="underbillings"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.underbillings}
						/>
						<FormCurrencyLineItem
							label="Current Assets"
							marker
							name="financials.balanceSheet.currentAssets"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.currentAssets}
							id="currentAssets"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.currentAssets}
						/>
						<FormCurrencyLineItem
							label="Total Assets"
							marker
							name="financials.balanceSheet.totalAssets"
							control={control}
							tooltip="<strong>Subtract the following:</strong>
                            <ul>
                                <li>Receivables and/or Loans due from owners/shareholders/affiliates</li>
                                <li>Goodwill</li>
                                <li>Intangibles</li>
                            </ul>"
							schemaInclude={draft.schema.financials?.include.balanceSheet?.totalAssets}
							id="totalAssets"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.totalAssets}
						/>
						<FormCurrencyLineItem
							label="Accounts Payable"
							marker
							name="financials.balanceSheet.accountsPayable"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.accountsPayable}
							id="accountsPayable"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.accountsPayable}
						/>
						<FormCurrencyLineItem
							label="Credit Cards Payable"
							marker
							name="financials.balanceSheet.creditCardsPayable"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.creditCardsPayable}
							id="creditCardsPayable"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.creditCardsPayable}
						/>
						<FormCurrencyLineItem
							label="Revolving Line of Credit"
							marker
							name="financials.balanceSheet.bankLinePayable"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.bankLinePayable}
							id="bankLinePayable"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.bankLinePayable}
						/>
						<FormCurrencyLineItem
							label="Accrued Expenses"
							marker
							name="financials.balanceSheet.accruedExpenses"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.accruedExpenses}
							id="accruedExpenses"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.accruedExpenses}
						/>
						<FormCurrencyLineItem
							label="Overbillings"
							marker
							name="financials.balanceSheet.overbillings"
							control={control}
							tooltip="<table width='500'><tr><td>This current liability is typically listed as “Overbillings” or “Billings in Excess of Costs”. It can also be found as a total on  a percentage of completion WIP.  If no Overbillings are listed, put “0”.</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.balanceSheet?.overbillings}
							id="overbillings"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.overbillings}
						/>
						<FormCurrencyLineItem
							label="Current Portion of Long Term Debt"
							marker
							name="financials.balanceSheet.currentPortionOfLtd"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.currentPortionOfLtd}
							id="currentPortionOfLtd"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.currentPortionOfLtd}
						/>
						<FormCurrencyLineItem
							label="Current Liabilities"
							marker
							name="financials.balanceSheet.currentLiabilities"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.currentLiabilities}
							id="currentLiabilities"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.currentLiabilities}
						/>
						<FormCurrencyLineItem
							label="Term Loan Debt"
							marker
							name="financials.balanceSheet.termLoanDebt"
							control={control}
							tooltip="<table width='500'><tr><td>The balance of repayments owed to financial institutions for loans issued. Unlike revolving credit lines, these loans have a specified amount and  repayment schedule</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.balanceSheet?.termLoanDebt}
							id="termLoanDebt"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.termLoanDebt}
						/>
						<FormCurrencyLineItem
							label="Total Liabilities"
							marker
							name="financials.balanceSheet.totalLiabilities"
							control={control}
							schemaInclude={draft.schema.financials?.include.balanceSheet?.totalLiabilities}
							id="totalLiabilities"
							readonly={draft.schema.financials?.readonly?.balanceSheet?.totalLiabilities}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Income Statement
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={draft.schema.financials?.include.incomeStatement}
						id="incomeStatement"
					>
						<FormCurrencyLineItem
							label="Revenue"
							marker
							name="financials.incomeStatement.revenue"
							control={control}
							schemaInclude={draft.schema.financials?.include.incomeStatement?.revenue}
							id="revenue"
							readonly={draft.schema.financials?.readonly?.incomeStatement?.revenue}
						/>
						<FormCurrencyLineItem
							label="General Administrative Expense"
							marker
							name="financials.incomeStatement.gaExpense"
							control={control}
							schemaInclude={draft.schema.financials?.include.incomeStatement?.gaExpense}
							id="gaExpense"
							readonly={draft.schema.financials?.readonly?.incomeStatement?.gaExpense}
						/>
						<FormYesNoLineItem
							label="Profitable YTD"
							marker
							name="financials.incomeStatement.profitableYtd"
							control={control}
							schemaInclude={draft.schema.financials?.include.incomeStatement?.profitableYtd}
							id="profitableYtd"
							readonly={draft.schema.financials?.readonly?.incomeStatement?.profitableYtd}
						/>
						<FormYesNoLineItem
							label="Profitable Last FYE"
							marker
							name="financials.incomeStatement.profitableLastFye"
							control={control}
							schemaInclude={draft.schema.financials?.include.incomeStatement?.profitableLastFye}
							id="profitableLastFye"
							readonly={draft.schema.financials?.readonly?.incomeStatement?.profitableLastFye}
						/>
						<FormYesNoLineItem
							label="Profitable year prior to last fiscal year"
							marker
							name="financials.incomeStatement.profitableYearPriorToLastFiscalYear"
							control={control}
							schemaInclude={
								draft.schema.financials?.include.incomeStatement
									?.profitableYearPriorToLastFiscalYear
							}
							id="profitableYearPriorToLastFiscalYear"
							readonly={
								draft.schema.financials?.readonly?.incomeStatement
									?.profitableYearPriorToLastFiscalYear
							}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Work In Progress
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={draft.schema.financials?.include.wip}
						id="wip"
					>
						<FormCurrencyLineItem
							label="Backlog Cost-to-Complete"
							marker
							name="financials.wip.projectBacklogCost"
							control={control}
							tooltip="<table width='500'><tr><td>This is the balance of cost left to complete on all jobs in progress. It is usually listed on the Work in Progress statement as “Cost to Complete” or “CTC”</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.wip?.projectBacklogCost}
							id="projectBacklogCost"
							readonly={draft.schema.financials?.readonly?.wip?.projectBacklogCost}
						/>
						<FormCurrencyLineItem
							label="Gross Profit in the Backlog"
							marker
							name="financials.wip.projectBacklogGrossProfit"
							control={control}
							tooltip="<table width='500'><tr><td>This is the total gross profit to be earned on all uncompleted work (total estimated gross profit for all projects - gross profit earned to date for all projects). It can be found on the Work in Progress statement and is usually listed as “Gross Profit Remaining”</td></tr></table>"
							schemaInclude={draft.schema.financials?.include.wip?.projectBacklogGrossProfit}
							id="projectBacklogGrossProfit"
							readonly={draft.schema.financials?.readonly?.wip?.projectBacklogGrossProfit}
						/>
					</LineItemStackedCard>
					<LineItemStackedCard
						className="w-full h-fit"
						header={
							<div className="w-full h-fit flex">
								<div className="flex-1 flex flex-col space-y-[8px]">
									<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
										Bank Details
									</div>
								</div>
								<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
									Where do I find this?
								</div>
							</div>
						}
						schemaInclude={draft.schema.financials?.include.bankDetails}
						id="bankDetails"
					>
						<FormCurrencyLineItem
							label="Revolving Line of Credit Limit"
							marker
							name="financials.bankDetails.blocSize"
							control={control}
							schemaInclude={draft.schema.financials?.include.bankDetails?.blocSize}
							id="blocSize"
							readonly={draft.schema.financials?.readonly?.bankDetails?.blocSize}
						/>
					</LineItemStackedCard>
				</div>
				<FormSection
					topPadding
					header="Financial Documents"
					marker
					subHeader="Please upload the following documents"
					schemaInclude={draft.schema.financials?.include.files !== undefined}
					id="documents"
				>
					<FileUpload
						value={filesController.field.value}
						onChange={filesController.field.onChange}
						onBlur={filesController.field.onBlur}
						onDownload={(fileId) =>
							getFileUrl({
								baseUrl: `/v2/surety/accounts/draft/${accountId}/files/${fileId}`,
								queryKey: [`downloadAccountDraftFile`, fileId, true],
								asDownload: true,
							})
						}
						allowedTypesAndLabels={{
							[FileType.fye_company_balance_sheet]: "FYE Balance Sheet",
							[FileType.fye_company_income_statement]: "FYE Income Statement",
						}}
						requiredTypes={[
							FileType.fye_company_balance_sheet,
							FileType.fye_company_income_statement,
						]}
						maxTypesPerFile={2}
						showErrors={formState.isSubmitted}
						className="w-[450px]"
					/>
				</FormSection>
			</div>
		</ShrinkingHeaderSectionNavLayout>
	);
};

type AccountDraftFinancialsSectionProps = {
	sections: { name: string; label: string }[];
};

export const AccountDraftFinancialsSection = ({ sections }: AccountDraftFinancialsSectionProps) => {
	const account = useContext(SuretyAccountDraftContext);
	return (
		<_AccountDraftFinancialsSection
			sections={sections}
			accountId={account.id}
			draft={account.draft}
		/>
	);
};
