import { formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { ReactNode, useMemo, useState } from "react";
import { QueryKey } from "react-query";
import { Card } from "../../../components/ui/Card";
import { PDFViewerControl, PDFViewerModal } from "../../../components/ui/PDFViewerModal";
import { AdminBondFormDto } from "../../../domain/admin/bond-form/types";
import { formatAddress, formatFromISODate, formatNameOption } from "../../../utils";
import { bondFormTypesAndLabels } from "../../agent/request/status/draft/DraftBondRequestView";
import { AdminButton } from "./AdminButton";
import { AdminDataList, AdminDataListProps } from "./AdminDataList";

type AdminBondFormCardProps = {
	bondForm: AdminBondFormDto;
	bondFormViewUrl: string;
	bondFormViewQueryKey: QueryKey;
	bottomContent?: ReactNode;
};

const sourceMap = {
	library: "Selected from the library",
	upload: "Uploaded by the agent",
};

export const AdminBondFormCard = ({
	bondForm,
	bondFormViewUrl,
	bondFormViewQueryKey,
	bottomContent,
}: AdminBondFormCardProps) => {
	const [pdfViewerControl, setPdfViewerControl] = useState<PDFViewerControl | undefined>();

	const data = useMemo(() => {
		const data: AdminDataListProps["data"] = [];
		data.push({ label: "Issuance Method", value: bondForm.issuanceMethod });
		data.push({ label: "Source", value: sourceMap[bondForm.source] });
		data.push({ label: "Bond Type", value: bondFormTypesAndLabels[bondForm.bondFormType] });
		data.push({ label: "Bond Number", value: bondForm.fillData.bondNumber });
		data.push({
			label: "Effective Date",
			value: formatFromISODate(bondForm.fillData.effectiveDate),
		});
		if (bondForm.bondFormType === "bid") {
			data.push({ label: "Bid Date", value: bondForm.fillData.bidDate });
			data.push({
				label: "Bond Amount",
				value:
					bondForm.fillData.bidBondAmountType === "percent"
						? `${formatToPercent(bondForm.fillData.bondAmountPercentOfBid)} of the bid amount`
						: formatToDollar(bondForm.fillData.bondAmount),
			});
		} else {
			data.push({ label: "Contract Date", value: bondForm.fillData.contractDate });
			data.push({ label: "Bond Amount", value: formatToDollar(bondForm.fillData.bondAmount) });
		}
		data.push({ label: "Obligee Name", value: bondForm.fillData.obligees?.[0].name });
		if (bondForm.fillData.obligees?.[0].address) {
			data.push({
				label: "Obligee Address",
				value: formatAddress(bondForm.fillData.obligees[0].address),
			});
		} else {
			data.push({ label: "Obligee Address" });
		}
		data.push({
			label: "Principal Signer Name",
			value: formatNameOption(bondForm.fillData.principalSigner),
		});
		data.push({ label: "Principal Signer Title", value: bondForm.fillData.principalSigner?.title });

		return data;
	}, [bondForm]);

	return (
		<Card>
			<div className="w-full px-[33px] pt-[26px] pb-[25px] flex flex-col space-y-[22px]">
				<div className="flex items-center">
					<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">Bond Form</div>
					<div className="flex flex-1 justify-end space-x-[10px]">
						<AdminButton
							onClick={() =>
								setPdfViewerControl({
									fetchUrl: bondFormViewUrl,
									queryKey: bondFormViewQueryKey,
								})
							}
						>
							View
						</AdminButton>
					</div>
				</div>
				<div className="flex flex-col space-y-[35px] divide-y divide-dashed divide-gray-300">
					<AdminDataList data={data} />
					{bottomContent}
				</div>
			</div>
			<PDFViewerModal
				control={pdfViewerControl}
				onClose={() => setPdfViewerControl(undefined)}
				header="View Bond Form"
			/>
		</Card>
	);
};
