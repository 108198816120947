import { Dtos } from "@inrev/inrev-common";
import { useMemo, useState } from "react";
import { HiMiniCheck, HiOutlineUsers } from "react-icons/hi2";
import { UseFollowersReturn } from "../../domain/shared/followers/types";
import { formatName } from "../../utils";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { Modal } from "../layout/Modal";
import { ModalItemWithHeader } from "../layout/ModalItemWithHeader";
import { cn } from "../lib/utils";

type FollowButtonProps = {
	notFollowingLabel: string;
	isFollowing: boolean | undefined;
	isOnlyFollower: boolean;
	onClick: () => void;
};

export type ManageFollowersModalProps = Omit<FollowerControlsProps, "notFollowingLabel"> & {
	onClose: () => void;
};

export type FollowerControlsProps = UseFollowersReturn & {
	notFollowingLabel: string;
	manageFollowersSubtext: string;
	useFetchAgentPreviews: () => {
		agentPreviews: Dtos.Agent.Preview.Get.Response[] | undefined;
		agentPreviewsLoading: boolean;
		agentPreviewsError: unknown;
	};
};

const _FollowButton = ({
	notFollowingLabel,
	isFollowing,
	onClick,
}: Omit<FollowButtonProps, "isOnlyFollower">) => (
	<div
		className={cn(
			"flex items-center justify-center w-fit h-[26px] min-h-[26px] min-w-[50px] leading-[13px] space-x-[5.5px] px-[10px] text-[13px] font-semibold text-gray-600 border border-gray-200 bg-gray-100 hover:text-gray-700 rounded-full cursor-pointer",
			isFollowing === undefined
				? "animate-pulse"
				: isFollowing
					? "border-gray-500"
					: "border-gray-200 text-gray-600 bg-gray-50 hover:bg-gray-100 hover:border-gray-300",
		)}
		onClick={onClick}
	>
		{!!isFollowing && <HiMiniCheck className="text-gray-500 stroke-[1] ml-[-1px] mt-[.5px]" />}
		<span>{isFollowing !== undefined ? (isFollowing ? "Following" : notFollowingLabel) : ""}</span>
	</div>
);

export const FollowButton = ({ isOnlyFollower, ...props }: FollowButtonProps) => {
	if (isOnlyFollower)
		return (
			<ConfirmationModalClickWrapper
				shouldConfirm={isOnlyFollower}
				message="At least one follower is required."
				confirmButtonText="Okay"
				confirmationButtonClassName="bg-gray-100 text-gray-700 hover:bg-gray-200/60 hover:text-gray-800"
				className="items-center font-medium"
				cancelButton={false}
			>
				<_FollowButton {...props} />
			</ConfirmationModalClickWrapper>
		);
	else return <_FollowButton {...props} />;
};

export const ManageFollowersModal = ({
	manageFollowersSubtext,
	onClose,
	useFetchAgentPreviews,
	followers,
	createAgentFollower,
	removeAgentFollower,
}: ManageFollowersModalProps) => {
	const { agentPreviews } = useFetchAgentPreviews();
	const isFollowingArr = useMemo(() => {
		if (!!agentPreviews && !!followers) {
			return agentPreviews.map((agent) =>
				followers.some((follower) => follower.authzId === agent.authzId),
			);
		}
	}, [agentPreviews, followers]);

	return (
		<Modal onClickOutside={() => onClose()}>
			<ModalItemWithHeader
				onClose={() => onClose()}
				header={
					<div className="flex flex-col space-y-[8px] mb-[4px]">
						<span className="font-[550]">Manage Followers</span>
						<span className="text-[12.5px] text-gray-600 font-normal max-w-[90%]">
							{manageFollowersSubtext}
						</span>
					</div>
				}
				className="w-[500px] h-fit max-h-[85vh]"
				bodyClassName="overflow-y-auto"
			>
				<div className="flex flex-col w-full divide-y-[1px] text-[14.5px] divide-gray-100/80">
					{(() => {
						if (!!!followers || !!!isFollowingArr || !!!agentPreviews) {
							return (
								<div className="flex items-center px-[8px] text-[12px] text-gray-600 italic h-[32px] m-[12px] bg-gray-100 animate-pulse rounded-md">
									Loading followers..
								</div>
							);
						} else {
							return agentPreviews.map((agent, index) => (
								<div
									key={agent.id}
									className="flex items-center justify-between space-x-[5px] font-normal text-gray-700 px-[24px] py-[18px]"
								>
									<span>{formatName(agent)}</span>
									<FollowButton
										notFollowingLabel="Set as follower"
										isFollowing={isFollowingArr[index]}
										isOnlyFollower={isFollowingArr[index] && followers.length === 1}
										onClick={() => {
											if (isFollowingArr[index]) {
												removeAgentFollower(agent);
											} else {
												createAgentFollower(agent);
											}
										}}
									/>
								</div>
							));
						}
					})()}
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

export const FollowerControls = (props: FollowerControlsProps) => {
	const [manageFollowersModalOpen, setManageFollowersModalOpen] = useState<boolean>(false);

	if (props.isFollowing === undefined || !!!props.followers)
		return (
			<span className="pt-[6px]">
				<div className="w-[90px] h-[24px] rounded-full bg-gray-100 animate-pulse"></div>
			</span>
		);
	return (
		<div className="flex items-center space-x-[6px] pt-[6px]">
			<FollowButton
				notFollowingLabel={props.notFollowingLabel}
				isFollowing={props.isFollowing}
				isOnlyFollower={props.isFollowing && props.followers.length === 1}
				onClick={() => {
					if (props.isFollowing === undefined) return;
					props.isFollowing
						? props.removeAgentFollower(undefined)
						: props.createAgentFollower(undefined);
				}}
			/>
			{!!props.followers && (
				<div
					className="flex items-center justify-center w-fit h-[26px] min-h-[26px] space-x-[5.5px] px-[10px] text-[13px] font-[550] text-gray-700 border border-gray-400 bg-white hover:text-gray-800 hover:border-gray-600 rounded-full cursor-pointer"
					onClick={() => setManageFollowersModalOpen(true)}
				>
					<span>{props.followers.length}</span>
					<HiOutlineUsers className="text-[13px] text-gray-600 stroke-[1.75] mt-[1px]" />
				</div>
			)}
			{manageFollowersModalOpen && (
				<ManageFollowersModal {...props} onClose={() => setManageFollowersModalOpen(false)} />
			)}
		</div>
	);
};
