import * as Sentry from "@sentry/react";
import { useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { AppLoadingProvider } from "./providers/AppLoadingProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { GlobalErrorMessageModalProvider } from "./providers/GlobalErrorHandlingProvider";

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 60000 * 5,
				cacheTime: 60000 * 5,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
			},
		},
	});

	const renderCount = useRef(0);
	useEffect(() => {
		renderCount.current = renderCount.current + 1;
		console.log("App renders: ", renderCount.current);
	});

	return (
		<BrowserRouter>
			<AuthProvider>
				<AppLoadingProvider>
					<GlobalErrorMessageModalProvider>
						<QueryClientProvider client={queryClient}>
							<AppRouter />
						</QueryClientProvider>
					</GlobalErrorMessageModalProvider>
				</AppLoadingProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default Sentry.withProfiler(App);
