"use client";
import { formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { getStatusTagByStatus } from "../../../components/ui/StatusTag";
import { RowActionButtons } from "../../../components/ui/row-action-buttons/RowActionButtons";
import { SuretyAccountPreview } from "../../../domain/agent/account/types";
import { formatEmpty } from "../../../utils";
import { AccountsActionButtons } from "./AccountsActionButtons";

export const accountsTableColumns: ColumnDef<SuretyAccountPreview>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
		meta: { noTruncate: true },
	},
	{
		id: "displayName",
		accessorKey: "displayName",
		accessorFn: ({ displayName }) => formatEmpty(displayName),
		header: "Name",
		sortingFn: "textCaseSensitive",
	},
	{
		id: "rate",
		accessorFn: ({ contract }) => contract?.bondingLine?.rate ?? 0,
		cell: (props) => formatEmpty(formatToPercent(props.row.original.contract?.bondingLine?.rate)),
		enableGlobalFilter: false,
		header: "Rate",
	},
	{
		id: "singleLimit",
		accessorFn: ({ contract }) => contract?.bondingLine?.singleLimit ?? 0,
		cell: (props) =>
			formatEmpty(formatToDollar(props.row.original.contract?.bondingLine?.singleLimit, true)),
		enableGlobalFilter: false,
		header: "Single Limit",
	},
	{
		id: "aggregateLimit",
		accessorFn: ({ contract }) => contract?.bondingLine?.aggregateLimit ?? 0,
		cell: (props) =>
			formatEmpty(formatToDollar(props.row.original.contract?.bondingLine?.aggregateLimit, true)),
		enableGlobalFilter: false,
		header: "Aggregate Limit",
	},
	{
		id: "totalExposure",
		accessorFn: ({ contract }) => contract?.exposure.total ?? 0,
		cell: (props) => formatEmpty(formatToDollar(props.row.original.contract?.exposure.total, true)),
		enableGlobalFilter: false,
		header: "Total Exposure",
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		accessorFn: ({ submittedAt }) =>
			submittedAt !== undefined ? DateTime.fromISO(submittedAt).toJSDate() : undefined,
		cell: (props) =>
			props.row.original.submittedAt !== undefined
				? DateTime.fromISO(props.row.original.submittedAt).toLocaleString(DateTime.DATE_MED)
				: "--",
		enableGlobalFilter: false,
		header: "Submitted",
	},
	{
		id: "archived",
		accessorKey: "archived",
		enableGlobalFilter: false,
		enableSorting: false,
	},
	{
		id: "action_menu",
		cell: (props) => (
			<RowActionButtons>
				<AccountsActionButtons account={props.row.original} />
			</RowActionButtons>
		),
		maxSize: 0,
		enableResizing: false,
		enableSorting: false,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: false,
		enablePinning: true,
		enableGrouping: false,
		enableMultiSort: false,
	},
];
