import { Icon } from "../../Icon";

type MainNavItemProps = {
	icon: "windows" | "clipboard" | "shield" | "building";
	label: string;
	active: boolean;
	onClick: () => void;
};

export const MainNavItem = (props: MainNavItemProps) => {
	return (
		<div
			onClick={props.onClick}
			className={`w-full h-[45px] shrink-0 relative flex gap-x-0 md:gap-x-[6px] items-center cursor-pointer select-none ${!props.active ? "hover:bg-[#161F44]" : ""}`}
		>
			<div
				className={`h-full absolute left-0 top-0 bottom-0 md:relative md:h-[38px] w-1 min-w-1 rounded-none md:rounded-full bg-inrev-light-blue ${!props.active ? "opacity-0" : ""}`}
			></div>
			<div
				className={`group flex-1 h-full flex justify-center md:justify-normal items-center gap-x-2.5 px-0 md:px-2.5 ml-[-4px] md:ml-0 bg-[#161F44] ${!props.active ? "bg-opacity-0" : ""}`}
			>
				<Icon type={props.icon} className=" fill-[#BDC6EA]" opacity={props.active ? 1 : 0.35} />
				<div
					className={`hidden md:block text-[14px] font-semibold text-white ${!props.active ? "opacity-[.85]" : ""} group-hover:opacity-100`}
				>
					{props.label}
				</div>
			</div>
		</div>
	);
};
