import { NAICSCode } from "@inrev/inrev-common";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { NAICSCodeSelect, NAICSCodeSelectProps } from "../NAICSCodeSelect";

type FormNAICSCodeSelectProps<TFieldValues extends FieldValues> = Omit<
	NAICSCodeSelectProps,
	"value" | "onChange" | "subsetFilters" | "defaultSubsetIndex" | "allNAICSCodesLabel"
> & {
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	subsetFilters?: [
		{ label: string; subset: NAICSCode[] },
		...{ label: string; subset: NAICSCode[] }[],
	];
	defaultSubsetIndex?: number;
	allNAICSCodesLabel?: string;
} & (
		| {
				subsetFilters: [
					{ label: string; subset: NAICSCode[] },
					...{ label: string; subset: NAICSCode[] }[],
				];
				defaultSubsetIndex?: number;
				allNAICSCodesLabel: string;
		  }
		| {
				subsetFilters?: undefined;
				defaultSubsetIndex?: undefined;
				allNAICSCodesLabel?: undefined;
		  }
	);

export const FormNAICSCodeSelect = <TFieldValues extends FieldValues>({
	control,
	name,
	...props
}: FormNAICSCodeSelectProps<TFieldValues>) => (
	<Controller
		control={control}
		name={name}
		render={({ field, fieldState }) => (
			<NAICSCodeSelect {...props} {...field} error={fieldState.error} />
		)}
	/>
);
