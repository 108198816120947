import { Dtos } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { HiExclamationTriangle } from "react-icons/hi2";
import { SetRequired } from "type-fest";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { useAdminUpdateSuretyAccountStatus } from "../../../../domain/admin/account/api";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { AdminReviewReasonList } from "../../shared/AdminReviewReasonList";
import { AdminAccountBondingLineSectionLayout } from "./AdminAccountBondingLineSectionLayout";
import { AdminUpdateAccountStatusFormCard } from "./AdminUpdateAccountStatusForm";

type AdminAccountReviewCardProps = {
	account: SetRequired<AdminSuretyAccount, "contract">;
	className?: string;
};

export const AdminAccountReviewCard = ({ account, className }: AdminAccountReviewCardProps) => {
	const { updateSuretyAccountStatus, updateSuretyAccountStatusIsLoading } =
		useAdminUpdateSuretyAccountStatus(account.id, account.contract?.underwriting.id);
	const handleSubmit = (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => {
		updateSuretyAccountStatus(data);
	};

	return (
		<div className="flex flex-col space-y-[25px] w-full">
			<AdminReviewReasonList reviewReasons={account.contract.underwriting.reviewReasons} />
			<AdminAccountBondingLineSectionLayout
				header="Bonding Lines"
				headerRight={
					<div className="flex items-center space-x-[8px] text-[14px] pr-[10px]">
						<HiExclamationTriangle className="text-[20px] fill-inrev-yellow mt-[1px]" />
						<span>
							Action Required:
							<span className="pl-[5px] w-full text-gray-600 font-normal">
								Review analysis and update bonding lines
							</span>
						</span>
					</div>
				}
				createdAt={DateTime.fromISO(account.contract.underwriting.createdAt)}
				className={className}
			>
				<AdminUpdateAccountStatusFormCard
					account={account}
					showScore={true}
					showBondingLinesWithoutStatus={true}
					handleSubmit={handleSubmit}
				/>
				{updateSuretyAccountStatusIsLoading && <LoadingModal />}
			</AdminAccountBondingLineSectionLayout>
		</div>
	);
};
