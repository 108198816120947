import { zodResolver } from "@hookform/resolvers/zod";
import {
	BidSuretyBondClosedReason,
	ContractSuretyType,
	Dtos,
	FinalContractSuretyBondClosedReason,
	SuretyBondId,
	SuretyBondType,
	SuretyType,
} from "@inrev/inrev-common";
import { ReactNode, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { match } from "ts-pattern";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { Button } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { FormCurrencyInput } from "../../../../components/ui/form/FormCurrencyInput";
import { FormDatePicker } from "../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormTextArea } from "../../../../components/ui/form/FormTextArea";
import { useAdminCloseBond } from "../../../../domain/admin/bond/api";
import { bondTypeLabelMap } from "../../../../types";
import { stripEmptyResolver } from "../../../../utils/form";

type AdminCloseBondModalProps = {
	bond: {
		id: SuretyBondId;
		suretyType: SuretyType;
		contractSuretyType: ContractSuretyType;
		bondType: SuretyBondType;
		amount: number;
		number: string;
	};
	button: ReactNode;
};

type AdminCloseBondFormData = {
	suretyType: SuretyType;
	contractSuretyType: ContractSuretyType;
	closedReason: FinalContractSuretyBondClosedReason | BidSuretyBondClosedReason | "";
	closedNote: string | "";
	actualCompletionDate: string | "";
	actualContractAmount: string | "";
};

const getDefaultFormData = (bond: AdminCloseBondModalProps["bond"]): AdminCloseBondFormData => ({
	suretyType: bond.suretyType,
	contractSuretyType: bond.contractSuretyType,
	closedReason: "",
	closedNote: "",
	actualCompletionDate: "",
	actualContractAmount: "",
});

const getClosedReasonOptions = (suretyBondType: SuretyBondType) => {
	return match(suretyBondType as SuretyBondType)
		.with(SuretyBondType.bid, () => [
			{ value: BidSuretyBondClosedReason.bid_lost, label: "Bid lost" },
			{ value: BidSuretyBondClosedReason.not_awarded, label: "Project was not awarded" },
			{
				value: FinalContractSuretyBondClosedReason.returned_by_obligee,
				label: "Returned by obligee",
			},
			{ value: FinalContractSuretyBondClosedReason.input_error, label: "Input error" },
			{ value: BidSuretyBondClosedReason.other, label: "Other" },
		])
		.with(SuretyBondType.final, () => [
			{ value: FinalContractSuretyBondClosedReason.project_completed, label: "Project completed" },
			{ value: FinalContractSuretyBondClosedReason.expired, label: "Term expired" },
			{ value: FinalContractSuretyBondClosedReason.non_payment, label: "Premium non-payment" },
			{ value: FinalContractSuretyBondClosedReason.replaced, label: "Replaced" },
			{
				value: FinalContractSuretyBondClosedReason.returned_by_obligee,
				label: "Returned by obligee",
			},
			{ value: FinalContractSuretyBondClosedReason.input_error, label: "Input error" },
			{ value: FinalContractSuretyBondClosedReason.other, label: "Other" },
		])
		.otherwise(() => []);
};

export const AdminCloseBondModal = ({ bond, button }: AdminCloseBondModalProps) => {
	const { closeBond, closeBondLoading } = useAdminCloseBond(bond.id);
	const [isOpen, setIsOpen] = useState(false);
	const formMethods = useForm<AdminCloseBondFormData, any, Dtos.Admin.SuretyBond.Close.Request>({
		defaultValues: getDefaultFormData(bond),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Admin.SuretyBond.Close.Request.schema)),
	});
	const closedReasonOtions = useMemo(() => getClosedReasonOptions(bond.bondType), [bond.bondType]);
	const closedReason = formMethods.watch("closedReason");

	const handleSubmit = (data: Dtos.Admin.SuretyBond.Close.Request) => {
		closeBond(data);
		setIsOpen(false);
	};

	return (
		<>
			<span onClick={() => setIsOpen(true)}>{button}</span>
			{isOpen && (
				<Modal onClickOutside={() => setIsOpen(false)}>
					<ModalItemWithHeader
						header={`Close ${bondTypeLabelMap[bond.bondType]} Bond ${bond.number}`}
						className="w-[500px]"
						bodyClassName="p-[30px] pt-[26px]"
						onClose={() => setIsOpen(false)}
					>
						<form>
							<FormItemGroup>
								<FormItem>
									<FormItemLabel condensed>
										<div className="flex w-full items-center justify-between">
											<span>Reason for Closing</span>
											<span className="flex items-center gap-[5px]">
												<HiOutlineExclamationTriangle className="text-inrev-yellow text-[17px] stroke-[2] fill-inrev-yellow/20" />
												<span className="text-[12px] font-semibold">Visible to Agent</span>
											</span>
										</div>
									</FormItemLabel>
									<FormDropdown
										control={formMethods.control}
										name="closedReason"
										options={closedReasonOtions}
										placeholder="Select one"
										condensed
									/>
								</FormItem>
								{closedReason !== "" &&
									closedReason === FinalContractSuretyBondClosedReason.project_completed && (
										<>
											<FormItem>
												<FormItemLabel condensed>Project Completion Date</FormItemLabel>
												<FormDatePicker
													control={formMethods.control}
													name="actualCompletionDate"
													condensed
												/>
											</FormItem>
											<FormItem>
												<FormItemLabel condensed>
													Contract Amount at Project Completion
												</FormItemLabel>
												<FormCurrencyInput
													control={formMethods.control}
													name="actualContractAmount"
													condensed
												/>
											</FormItem>
										</>
									)}
								<FormItem>
									<FormItemLabel condensed>Note</FormItemLabel>
									<FormTextArea
										control={formMethods.control}
										name="closedNote"
										placeholder="Enter note here"
										className="h-[100px]"
									/>
								</FormItem>
							</FormItemGroup>
							<div className="flex justify-end mt-[35px] space-x-[10px]">
								<Button
									onClick={() => setIsOpen(false)}
									color="gray"
									className="w-[80px]"
									filled
									thinFont
								>
									Cancel
								</Button>
								<Button
									color="light-blue"
									className="w-[80px]"
									filled
									thinFont
									onClick={formMethods.handleSubmit(handleSubmit)}
								>
									<div className="flex items-center space-x-[8px]">
										<Icon
											type="shield-slash"
											className="stroke-[1] text-white h-[14px]"
											width={13}
											height={17}
										/>
										<span>Close Bond</span>
									</div>
								</Button>
							</div>
						</form>
					</ModalItemWithHeader>
				</Modal>
			)}
			{closeBondLoading && !isOpen && <LoadingModal />}
		</>
	);
};
