import { zodResolver } from "@hookform/resolvers/zod";
import {
	City,
	Email,
	NameSuffix,
	StreetAddress,
	Types,
	USStateOrTerritory,
	ZipCode,
	validAddressSchema,
	zswitch,
} from "@inrev/inrev-common";
import { useEffect, useMemo, useState } from "react";
import { UseFieldArrayReturn, useForm } from "react-hook-form";
import { useCustomCompareMemo } from "use-custom-compare";
import { v4 } from "uuid";
import { z } from "zod";
import { useAddressValidation } from "../../../../../../../../api";
import { Modal } from "../../../../../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../../../../../components/layout/ModalItemWithHeader";
import { formatAddress, formatName } from "../../../../../../../../utils";
import { stripEmptyResolver } from "../../../../../../../../utils/form";
import { uniqueSSNSchema } from "../../../../../../../../validation";
import { IndividualOwnerForm } from "./IndividualOwnerForm";
import { IndividualSpouseForm } from "./IndividualSpouseForm";

export type DefaultIndividualFormData = Omit<
	Types.SuretyAccount.Draft.Contract.Data.Details.Individual,
	"type" | "ssn" | "married" | "ownsAHome"
> & {
	type: "owner" | "spouse" | "";
	ssn: string;
	married: boolean | "";
	ownsAHome: boolean | "";
};

const getDefaultFormData = (
	type: DefaultIndividualFormData["type"],
): DefaultIndividualFormData => ({
	type,
	draftId: v4(),
	firstName: "",
	middleInitial: "",
	lastName: "",
	suffix: "" as NameSuffix,
	address: {
		street: "" as StreetAddress,
		city: "" as City,
		state: "" as USStateOrTerritory,
		zip: "" as ZipCode,
	},
	email: "" as Email,
	ssn: "",
	married: "",
	spouseDraftId: "",
	ownsAHome: "",
});

const getOwnerSchema = (ssns: string[]) =>
	Types.SuretyQuote.Draft.Contract.Data.Principal.individualSchema.extend({
		type: z.literal("owner"),
		address: validAddressSchema,
		ssn: uniqueSSNSchema(ssns),
	});
export type IndividualOwnerFormData = z.infer<ReturnType<typeof getOwnerSchema>>;

const getSpouseSchema = (ssns: string[]) =>
	zswitch((input) => {
		if (input.type === "owner") return getOwnerSchema(ssns).omit({ married: true });
		return getOwnerSchema(ssns)
			.omit({ type: true, married: true, ssn: true })
			.extend({
				type: z.literal("spouse"),
			});
	});
export type IndividualSpouseFormData = z.infer<ReturnType<typeof getSpouseSchema>>;

type DraftBondRequestNewPrincipalIndividualModalProps = {
	individuals: Types.SuretyQuote.Draft.Contract.Data["principal"]["individuals"];
	individualsFieldArray: UseFieldArrayReturn<
		Types.SuretyQuote.Draft.Contract.Data,
		"principal.individuals",
		"draftId"
	>;
	creditReportPermission: Types.SuretyQuote.Draft.Contract.Data["principal"]["creditReportPermission"];
	creditReportPermissionFieldArray: UseFieldArrayReturn<
		Types.SuretyQuote.Draft.Contract.Data,
		"principal.creditReportPermission",
		"id"
	>;
	selectedIndex?: number;
	onClose: () => void;
};

export const DraftBondRequestNewPrincipalIndividualModal = ({
	individuals,
	individualsFieldArray,
	creditReportPermission,
	creditReportPermissionFieldArray,
	selectedIndex,
	onClose,
}: DraftBondRequestNewPrincipalIndividualModalProps) => {
	const individualSSNs = useMemo(
		() =>
			individuals
				.map((individual) => individual.ssn)
				.filter((ssn) => ssn !== undefined) as string[],
		[individuals],
	);

	const selectedOwner = selectedIndex !== undefined ? individuals[selectedIndex] : undefined;
	const selectedOwnerFilteredSSNs = useMemo(
		() =>
			selectedOwner !== undefined
				? individualSSNs.filter((ssn) => ssn !== selectedOwner.ssn)
				: individualSSNs,
		[selectedOwner, individualSSNs],
	);
	const ownerSchema = useMemo(
		() => getOwnerSchema(selectedOwnerFilteredSSNs),
		[selectedOwnerFilteredSSNs],
	);
	const ownerDefaultValues = selectedOwner ?? getDefaultFormData("owner");
	const ownerForm = useForm<DefaultIndividualFormData, any, IndividualOwnerFormData>({
		defaultValues: ownerDefaultValues,
		resolver: stripEmptyResolver(zodResolver(ownerSchema)),
		reValidateMode: "onBlur",
	});
	const ownerAddressValidation = useAddressValidation<DefaultIndividualFormData>(
		formatAddress(ownerDefaultValues.address),
		ownerForm,
		"address",
	);
	const [validatedOwnerData, setValidatedOwnerData] = useState<
		IndividualOwnerFormData | undefined
	>();
	const ownerSSN = ownerForm.watch("ssn");
	const ownerOwnsAHome = ownerForm.watch("ownsAHome");

	const selectedSpouseIndex = useMemo(
		() =>
			selectedOwner !== undefined
				? individuals.findIndex((individual) => individual.draftId === selectedOwner.spouseDraftId)
				: undefined,
		[selectedOwner],
	);
	const selectedSpouse =
		selectedSpouseIndex !== undefined ? individuals[selectedSpouseIndex] : undefined;
	const selectedSpouseFilteredSSNs = useMemo(
		() =>
			selectedSpouse !== undefined
				? individualSSNs.filter((ssn) => ssn !== selectedSpouse.ssn)
				: individualSSNs,
		[selectedSpouse, individualSSNs],
	);
	const spouseSchema = useCustomCompareMemo(
		() => getSpouseSchema([...selectedSpouseFilteredSSNs, ...(ownerSSN && [ownerSSN])]),
		[{ ownerSSN }],
		(prev, next) => {
			return prev[0].ownerSSN === next[0].ownerSSN || next[0].ownerSSN.length !== 9;
		},
	);
	const spouseDefaultValues = selectedSpouse ?? getDefaultFormData("");
	const spouseForm = useForm<DefaultIndividualFormData, any, IndividualSpouseFormData>({
		defaultValues: spouseDefaultValues,
		resolver: stripEmptyResolver(zodResolver(spouseSchema)),
		reValidateMode: "onBlur",
	});
	const spouseAddressValidation = useAddressValidation<DefaultIndividualFormData>(
		formatAddress(spouseDefaultValues.address),
		spouseForm,
		"address",
	);
	const spouseType = spouseForm.watch("type");
	const spouseSSN = spouseForm.watch("ssn");

	const currentForm = useMemo(
		() => (validatedOwnerData ? "spouse" : "owner"),
		[validatedOwnerData],
	);

	const handleSave = async (
		ownerFormData: IndividualOwnerFormData,
		spouseFormData?: IndividualSpouseFormData,
	) => {
		// Set owner's spouse id
		if (ownerFormData.married) {
			if (!spouseFormData) throw new Error();
			ownerFormData = { ...ownerFormData, spouseDraftId: spouseFormData.draftId };
		}

		// Upsert owner
		if (selectedIndex !== undefined) {
			const selectedOwnerCreditReportPermissionIndex = creditReportPermission.findIndex(
				(permission) => permission.id === ownerFormData.draftId,
			);
			if (selectedOwnerCreditReportPermissionIndex === -1) throw new Error();
			creditReportPermissionFieldArray.update(selectedOwnerCreditReportPermissionIndex, {
				...creditReportPermission[selectedOwnerCreditReportPermissionIndex],
				name: formatName(ownerFormData),
			});
			individualsFieldArray.update(selectedIndex, ownerFormData);
		} else {
			creditReportPermissionFieldArray.append({
				id: ownerFormData.draftId,
				name: formatName(ownerFormData),
				permission: false,
			});
			individualsFieldArray.append(ownerFormData);
		}

		if (spouseFormData) {
			// If spouseFormData exists, we can assume they are married
			const spouseData = { ...spouseFormData, spouseDraftId: ownerFormData.draftId, married: true };

			// Upsert spouse
			if (selectedSpouseIndex !== undefined && selectedSpouse) {
				if (selectedSpouse.type === "owner") {
					const selectedSpouseCreditReportPermissionIndex = creditReportPermission.findIndex(
						(permission) => permission.id === spouseFormData.draftId,
					);
					if (selectedSpouseCreditReportPermissionIndex === -1) throw new Error();
					creditReportPermissionFieldArray.update(selectedSpouseCreditReportPermissionIndex, {
						...creditReportPermission[selectedSpouseCreditReportPermissionIndex],
						name: formatName(spouseFormData),
					});
				}
				individualsFieldArray.update(selectedSpouseIndex, spouseData);
			} else {
				if (spouseData.type === "owner") {
					creditReportPermissionFieldArray.append({
						id: spouseFormData.draftId,
						name: formatName(spouseFormData),
						permission: false,
					});
				}
				individualsFieldArray.append(spouseData);
			}
		} else if (selectedSpouseIndex !== undefined && selectedSpouse) {
			// Here we need to handle the case where a spouse existed at the time of selection, but owner married was changed to false
			if (selectedSpouse.type === "owner") {
				// If the selected spouse is also an owner, we just want to disconnect them instead of deleting
				// Set married to false instead of '' so that there isn't now an error on that individual
				individualsFieldArray.update(selectedSpouseIndex, {
					...selectedSpouse,
					spouseDraftId: "",
					married: false,
				});
			} else {
				individualsFieldArray.remove(selectedSpouseIndex);
			}
		}

		onClose();
	};

	const onOwnerFormSubmit = (data: IndividualOwnerFormData) => {
		if (data.married) setValidatedOwnerData(data);
		else handleSave(data);
	};

	const onSpouseFormSubmit = (data: IndividualSpouseFormData) => {
		if (!validatedOwnerData) throw new Error();
		handleSave(validatedOwnerData, data);
	};

	useEffect(() => {
		if (validatedOwnerData) {
			spouseForm.setValue("ownsAHome", ownerOwnsAHome);
		}
	}, [validatedOwnerData]);

	useEffect(() => {
		if (spouseType === "spouse" && spouseSSN !== "") spouseForm.setValue("ssn", "");
	}, [spouseType]);

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header={currentForm === "owner" ? "New Individual" : "New Individual Spouse"}
				onClose={onClose}
			>
				<div className="max-w-[820px] px-[40px] pt-[25px] pb-[35px]">
					{currentForm === "owner" && (
						<IndividualOwnerForm
							form={ownerForm}
							addressValidation={ownerAddressValidation}
							onSubmit={onOwnerFormSubmit}
						/>
					)}
					{currentForm === "spouse" && (
						<IndividualSpouseForm
							form={spouseForm}
							addressValidation={spouseAddressValidation}
							validatedOwnerData={validatedOwnerData}
							onBack={() => setValidatedOwnerData(undefined)}
							onSubmit={onSpouseFormSubmit}
						/>
					)}
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};
