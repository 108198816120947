import { AgencyId, Dtos } from "@inrev/inrev-common";
import { useQuery } from "react-query";
import { useRequest } from "../../../utils/request";

export const useAdminFetchAgentPreviewsForAgency = (id?: AgencyId) => {
	const { get } = useRequest();
	const { data, error, isLoading } = useQuery({
		queryKey: ["adminAgentPreviews", id],
		queryFn: async () =>
			await get<Dtos.Agent.Preview.Get.Response[]>(`/v2/admin/agencies/${id}/agents`),
		enabled: !!id,
		staleTime: Infinity,
		cacheTime: Infinity,
	});

	return { agentPreviews: data, agentPreviewsError: error, agentPreviewsLoading: isLoading };
};
