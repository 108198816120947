import { stripEmptyDeep } from "@inrev/inrev-common";
import { equals, paths } from "rambda";
import { useEffect, useRef } from "react";
import { FieldPath, FieldValues, Resolver, ResolverOptions, UseFormReturn } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

export const useAutoSave = <TData extends Record<string, any>>(
	updateFn: (data: TData) => void,
	awaitedUpdateFn: (data: TData) => void,
	formMethods: UseFormReturn<TData, any, any>,
	dataSource?: TData,
	resetTrigger?: any,
	awaitList: FieldPath<TData>[] = [],
) => {
	const fieldsToAwait = awaitList.map((path) => formMethods.watch(path));

	const autosave = useRef(
		useDebouncedCallback(
			() => {
				if (formMethods.formState.isDirty) {
					updateFn(formMethods.getValues());
					formMethods.reset(formMethods.getValues(), {
						keepValues: true,
						keepErrors: true,
						keepIsSubmitted: true,
						keepIsValid: true,
						keepSubmitCount: true,
						keepIsSubmitSuccessful: true,
					});
				}
			},
			1000,
			{ leading: false, trailing: true },
		),
	);

	// Autosave when fields in autoSaveFieldPaths are dirty
	useEffect(() => {
		if (!autosave.current.isPending() && formMethods.formState.isDirty) {
			autosave.current();
		}
	}, [formMethods.formState]);

	// awaitUpdate on change of fields that are in fieldsToAwait
	useEffect(() => {
		if (!equals(paths(awaitList, formMethods.formState.defaultValues), fieldsToAwait)) {
			awaitedUpdateFn(formMethods.getValues());
		}
	}, fieldsToAwait);

	useEffect(() => {
		if (dataSource !== undefined) {
			formMethods.reset(dataSource, {
				keepIsSubmitted: true,
				keepSubmitCount: true,
			});
			if (formMethods.formState.isSubmitted) {
				formMethods.trigger();
			}
		}
	}, [resetTrigger]);
};

export const stripEmptyResolver = <
	TFieldValues extends FieldValues,
	TContext extends any = any,
	TOptions extends ResolverOptions<TFieldValues> = ResolverOptions<TFieldValues>,
>(
	resolver: Resolver<TFieldValues, TContext>,
	returnEmptyObject: boolean = true,
): Resolver<TFieldValues, TContext> => {
	return (async (values: TFieldValues, context: TContext, options: TOptions) =>
		await resolver(stripEmptyDeep(values, returnEmptyObject), context, options)) as Resolver<
		TFieldValues,
		TContext
	>;
};
