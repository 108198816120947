import {
	type AttachmentFile,
	type CompanyContactId,
	FileExtension,
	type FinancialStatementId,
	type IndividualContactId,
	SuretyAccountStatus,
	SuretyType,
	formatToDollar,
	formatToPercent,
	formatYesNo,
} from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { type ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { FaRegSnowflake } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import {
	HiArrowPath,
	HiAtSymbol,
	HiOutlineCheckCircle,
	HiOutlineExclamationTriangle,
	HiOutlineMapPin,
	HiOutlinePencilSquare,
	HiOutlineXCircle,
	HiPlus,
	HiXMark,
} from "react-icons/hi2";
import { useIsFetching } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BarList } from "../../../components/charts/BarList";
import { AttachmentsListSection } from "../../../components/layout/AttachmentsListSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { WorkflowLayout } from "../../../components/layout/WorkflowLayout";
import { cn } from "../../../components/lib/utils";
import { Button } from "../../../components/ui/Button";
import { Card } from "../../../components/ui/Card";
import { CardGrayBody } from "../../../components/ui/CardGrayBody";
import { DataTable } from "../../../components/ui/DataTable/DataTable";
import { LineItem } from "../../../components/ui/LineItem";
import { LocalFileUpload } from "../../../components/ui/LocalFileUpload";
import { NotificationCard } from "../../../components/ui/NotificationCard";
import { type PDFViewerControl, PDFViewerModal } from "../../../components/ui/PDFViewerModal";
import { SensitiveData } from "../../../components/ui/SensitiveData";
import { accountAttachmentTypeLabelMap } from "../../../constants";
import {
	useAdminFetchSuretyAccount,
	useAdminUploadCompletedIndemnityAgreement,
} from "../../../domain/admin/account/api";
import type { AdminSuretyAccount } from "../../../domain/admin/account/types";
import { getAccountPrimary } from "../../../domain/admin/account/utils";
import { useAdminDeleteFinancialStatement } from "../../../domain/admin/financial-statement/api.ts";
import { useComments } from "../../../domain/shared/comments/api";
import { useFollowers } from "../../../domain/shared/followers/api";
import type { LocallyUploadedFile } from "../../../types";
import { formatAddress, formatEmpty, formatName } from "../../../utils";
import { AdminDataList } from "../shared/AdminDataList";
import { AdminFollowerControls } from "../shared/AdminFollowerControls";
import { NAICSCodesList } from "../shared/NAICSCodeList";
import { AdminAccountReUnderwriteModal } from "./re-underwrite/AdminAccountReUnderwriteModal.tsx";
import { AdminCompanyModal } from "./shared/AccountCompanyModal.tsx";
import { AdminIndividualModal } from "./shared/AccountIndividualModal.tsx";
import { AdminAccountBondingLineSection } from "./shared/AdminAccountBondingLineSection";
import { AdminAccountBondsTable } from "./shared/AdminAccountBondsTable";
import { AdminAccountHeader } from "./shared/AdminAccountHeader";
import { AdminAccountNotificationStack } from "./shared/AdminAccountNotificationStack";
import { AdminAccountRequestsTable } from "./shared/AdminAccountRequestsTable";
import { AdminAccountReviewCard } from "./shared/AdminAccountReviewCard";
import { AdminFinancialStatementModal } from "./shared/AdminUpsertFinancialStatementModal.tsx";

type _AdminSuretyAccountViewProps = {
	account: AdminSuretyAccount;
};

export const AdminReUnderwriteSuretyAccountModalContext = createContext<ReactNode>(undefined!);

export const _AdminSuretyAccountView = ({ account }: _AdminSuretyAccountViewProps) => {
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"accounts",
		account.id,
		true,
	);
	const followersControl = useFollowers("accounts", account.id, true);
	const financialStatementYears = useMemo(
		() =>
			account.financialStatements.map((statement) => DateTime.fromISO(statement.date).toUTC().year),
		[account.financialStatements],
	);
	const [currentFinancialStatementIndex, setCurrentFinancialStatementIndex] = useState<
		number | undefined
	>(undefined);
	const currentFinancialStatementView:
		| AdminSuretyAccount["financialStatements"][number]
		| undefined =
		currentFinancialStatementIndex !== undefined
			? account.financialStatements[currentFinancialStatementIndex]
			: undefined;
	const [currentWipStatementView, setCurrentWipStatementView] = useState<
		AdminSuretyAccount["wipStatements"][number] | undefined
	>();
	const [attachments, setAttachments] = useState<AttachmentFile[]>(account.attachments);
	const primaryCompany = getAccountPrimary(account);
	const [indemnityAgreementFileUpload, setIndemnityAgreementFileUpload] = useState<
		LocallyUploadedFile[]
	>([]);
	const {
		uploadCompletedIndemnityAgreement,
		uploadCompletedIndemnityAgreementIsLoading,
		uploadCompletedIndemnityAgreementSuccess,
	} = useAdminUploadCompletedIndemnityAgreement(account.id, SuretyType.contract);
	const [pdfViewerControl, setPdfViewerControl] = useState<PDFViewerControl | undefined>(undefined);
	const isAccountFetching = useIsFetching(["suretyAccounts", account.id]);

	const [individualEditorOpen, setIndividualEditorOpen] = useState<boolean>(false);
	const [selectedIndividualId, setSelectedIndividualId] = useState<IndividualContactId | undefined>(
		undefined,
	);

	const [companyEditorOpen, setCompanyEditorOpen] = useState<boolean>(false);
	const [selectedCompanyId, setSelectedCompanyId] = useState<CompanyContactId | undefined>(
		undefined,
	);

	const [financialStatementEditorOpen, setFinancialStatementEditorOpen] = useState<boolean>(false);
	const [selectedFinancialStatementId, setSelectedFinancialStatementId] = useState<
		FinancialStatementId | undefined
	>(undefined);

	const { deleteFinancialStatement, deleteFinancialStatementIsLoading } =
		useAdminDeleteFinancialStatement(account.id);
	const [reUnderwriteFormOpen, setReUnderwriteFormOpen] = useState(false);

	useEffect(() => {
		setAttachments(account.attachments);
	}, [account.attachments]);

	useEffect(() => {
		account.financialStatements.length
			? setCurrentFinancialStatementIndex(0)
			: setCurrentFinancialStatementIndex(undefined);
	}, [account.financialStatements]);

	useEffect(() => {
		if (selectedIndividualId) setIndividualEditorOpen(true);
	}, [selectedIndividualId]);
	useEffect(() => {
		if (!individualEditorOpen) setSelectedIndividualId(undefined);
	}, [individualEditorOpen]);
	useEffect(() => {
		if (selectedCompanyId) setCompanyEditorOpen(true);
	}, [selectedCompanyId]);
	useEffect(() => {
		if (!companyEditorOpen) setSelectedCompanyId(undefined);
	}, [companyEditorOpen]);
	useEffect(() => {
		if (selectedFinancialStatementId) setFinancialStatementEditorOpen(true);
	}, [selectedFinancialStatementId]);
	useEffect(() => {
		if (!financialStatementEditorOpen) {
			setSelectedFinancialStatementId(undefined);
		}
	}, [financialStatementEditorOpen]);

	useEffect(() => {
		if (currentFinancialStatementView !== undefined) {
			setCurrentWipStatementView(
				account.wipStatements.find((wip) => wip.date === currentFinancialStatementView.date),
			);
		}
	}, [currentFinancialStatementView]);

	useEffect(() => {
		if (uploadCompletedIndemnityAgreementSuccess) setIndemnityAgreementFileUpload([]);
	}, [uploadCompletedIndemnityAgreementSuccess]);

	if (account.contract === undefined)
		throw new Error("Property 'contract' on a submitted surety account is undefined");
	return (
		<>
			<AdminReUnderwriteSuretyAccountModalContext.Provider
				value={
					<Button color="light-blue" filled onClick={() => setReUnderwriteFormOpen(true)}>
						<div className="flex items-center space-x-[8px]">
							<HiArrowPath className="text-[16px] text-white stroke-[.5]" />
							<span>Run Model</span>
						</div>
					</Button>
				}
			>
				<WorkflowLayout
					title={account.displayName}
					subHeader={
						account.contract.underwriting.stale ? (
							<div className="flex w-full h-[36px] items-center justify-center space-x-[24px] bg-gray-100 shadow-md border-b border-b-gray-200">
								<span className="text-[13px] font-[500]">
									Updates were made to this account that may impact underwriting
								</span>
								<Button
									color="light-blue"
									filled
									onClick={() => setReUnderwriteFormOpen(true)}
									className="h-[24px] text-[12px]"
								>
									<div className="flex items-center space-x-[8px]">
										<HiArrowPath className="text-[12px] text-white stroke-[.5]" />
										<span>Run Model</span>
									</div>
								</Button>
							</div>
						) : undefined
					}
				>
					<div className="w-[900px] max-w-[900px] min-w-[785px]">
						<div className="flex flex-col space-y-[45px] pb-[125px]">
							<div className="flex flex-col space-y-[35px] mb-[-30px]">
								<AdminAccountNotificationStack account={account} className="pt-[25px]" />
								<AdminAccountHeader
									account={account}
									actionRequired={false}
									followersControl={followersControl}
								/>
								{account.status === SuretyAccountStatus.review && (
									<AdminAccountReviewCard account={account} />
								)}
								{account.status !== SuretyAccountStatus.review && (
									<AdminAccountBondingLineSection account={account} />
								)}
							</div>
							<AdminAccountRequestsTable account={account} />
							<AdminAccountBondsTable account={account} />
							<CardGrayBody
								header="Exposure"
								contentClassName="p-[40px] pt-[25px] space-y-[20px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="text-[16px]">Total: {formatToDollar(account.exposure.total)}</div>
								<div className="flex items-center space-x-[10px]">
									<Card className="p-[15px] shadow-sm">
										<BarList
											data={[
												{
													label: "Bond Requests",
													value: account.exposure.quotes,
												},
												{ label: "Bonds", value: account.exposure.bonds },
											]}
											valueFormatter={(value) => formatEmpty(formatToDollar(value, true))}
											className="text-[14px]"
										/>
									</Card>
									<Card className="p-[15px] shadow-sm">
										<BarList
											data={[
												{
													label: "Bid",
													value: account.exposure.contract.bid.total,
												},
												{
													label: "Final",
													value: account.exposure.contract.final.total,
												},
											]}
											valueFormatter={(value) => formatEmpty(formatToDollar(value, true))}
											className="text-[14px]"
										/>
									</Card>
								</div>
							</CardGrayBody>
							<CardGrayBody
								header="Character"
								contentClassName="p-[40px] pt-[25px] space-y-[35px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="flex flex-col space-y-[15px]">
									<div className="text-[15px] text-gray-500 italic">Company Credit Reports</div>
									<LineItem label="Adjusted FSR" className="px-[20px] pb-[5px]">
										{formatEmpty(account.adjustedFSR)}
									</LineItem>
									<DataTable
										name="admin_account_company_credit_reports"
										columnDefs={[
											{
												id: "timestampStatus",
												header: "Date Pulled",
												size: 140,
												minSize: 140,
												maxSize: 140,
												enableResizing: false,
												cell: (props) => {
													const defined = props.row.original.createdAt !== undefined;
													const createdAtDt = DateTime.fromISO(props.row.original.createdAt);
													const expired = createdAtDt.diffNow("years").years < -1;
													return (
														<div className="flex items-center space-x-[8px]">
															{!defined ? (
																<HiOutlineXCircle className="w-[18px] stroke-[1.75] text-inrev-gray-600" />
															) : expired ? (
																<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
															) : props.row.original.success ? (
																<HiOutlineCheckCircle className="w-[18px] stroke-[1.75] text-inrev-green" />
															) : (
																<HiXMark className="w-[18px] stroke-[1.75] text-inrev-red" />
															)}
															<span>
																{defined
																	? DateTime.fromISO(props.row.original.createdAt).toFormat(
																			"MM/dd/yyyy",
																		)
																	: "--"}
															</span>
														</div>
													);
												},
											},
											{
												id: "companyName",
												accessorKey: "name",
												header: "Company",
												size: 200,
											},
											{
												id: "fsr",
												accessorKey: "fsr",
												header: "FSR",
												size: 75,
												minSize: 75,
											},
											{
												id: "lienBalance",
												accessorKey: "lienBalance",
												header: "Lien",
												size: 100,
												minSize: 100,
												accessorFn: ({ lienBalance }) => formatEmpty(formatToDollar(lienBalance)),
											},
											{
												id: "judgmentBalance",
												accessorKey: "judgmentBalance",
												header: "Judgment",
												size: 100,
												minSize: 100,
												accessorFn: ({ judgmentBalance }) =>
													formatEmpty(formatToDollar(judgmentBalance)),
											},
											{
												id: "bankruptcy",
												accessorKey: "bankruptcy",
												header: "Bankruptcy",
												size: 120,
												minSize: 120,
												accessorFn: ({ bankruptcy }) =>
													bankruptcy !== undefined
														? bankruptcy
															? "Yes"
															: "No"
														: formatEmpty(bankruptcy),
											},
										]}
										data={account.companies
											.filter((c) => c.config.contract.includeInUnderwriting)
											.map((company) => ({
												name: company.name,
												success: company.creditReports[0]?.success,
												fsr: company.creditReports[0]?.experianFsr,
												lienBalance: company.creditReports[0]?.lienBalance,
												judgmentBalance: company.creditReports[0]?.judgmentBalance,
												bankruptcy: company.creditReports[0]?.bankruptcy,
												createdAt: company.creditReports[0]?.createdAt,
											}))}
										tableBodyBorderColor="gray-200"
										tableClassName="shadow-sm"
										expandToRows
									/>
								</div>
								{account.individuals.length > 0 && (
									<div className="flex flex-col space-y-[15px]">
										<div className="text-[15px] text-gray-500 italic">
											Individual Credit Reports
										</div>
										<LineItem label="Average FICO" className="px-[20px] pb-[5px]">
											{formatEmpty(account.averageFICO)}
										</LineItem>
										<DataTable
											name="admin_account_individual_credit_reports"
											columnDefs={[
												{
													id: "timestampStatus",
													header: "Date Pulled",
													size: 140,
													minSize: 140,
													maxSize: 140,
													enableResizing: false,
													cell: (props) => {
														const defined = props.row.original.createdAt !== undefined;
														const createdAtDt = DateTime.fromISO(props.row.original.createdAt);
														const expired = createdAtDt.diffNow("years").years < -1;
														return (
															<div className="flex items-center space-x-[8px]">
																{!defined ? (
																	<HiOutlineXCircle className="w-[18px] stroke-[1.75] text-inrev-gray-600" />
																) : expired ? (
																	<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
																) : props.row.original.success ? (
																	<HiOutlineCheckCircle className="w-[18px] stroke-[1.75] text-inrev-green" />
																) : props.row.original.failureReason === "frozen" ? (
																	<FaRegSnowflake className="w-[18px] stroke-[1.75] text-inrev-light-blue" />
																) : (
																	<HiXMark className="w-[18px] stroke-[1.75] text-inrev-red" />
																)}
																<span>
																	{defined
																		? DateTime.fromISO(props.row.original.createdAt).toFormat(
																				"MM/dd/yyyy",
																			)
																		: "--"}
																</span>
															</div>
														);
													},
												},
												{
													id: "name",
													accessorKey: "name",
													header: "Name",
													size: 200,
												},
												{
													id: "score",
													accessorKey: "score",
													header: "FICO",
													size: 75,
													minSize: 75,
													accessorFn: ({ score }) => formatEmpty(score),
												},
												{
													id: "bankruptcy",
													accessorKey: "bankruptcy",
													header: "Bankruptcy",
													size: 120,
													minSize: 120,
													accessorFn: ({ bankruptcy }) =>
														bankruptcy !== undefined
															? bankruptcy
																? "Yes"
																: "No"
															: formatEmpty(bankruptcy),
												},
											]}
											data={account.individuals
												.filter((i) => i.config.contract.includeInUnderwriting)
												.map((individual) => ({
													name: formatName(individual),
													success: individual.creditReports[0]?.success,
													score: individual.creditReports[0]?.score,
													bankruptcy: individual.creditReports[0]?.bankruptcy,
													createdAt: individual.creditReports[0]?.createdAt,
													failureReason: individual.creditReports[0]?.failureReason,
												}))}
											tableBodyBorderColor="gray-200"
											tableClassName="shadow-sm"
											expandToRows
										/>
									</div>
								)}
							</CardGrayBody>
							<CardGrayBody
								header="Capacity"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
								contentClassName="pt-[20px] pb-[35px] px-[45px] space-y-[12px] text-[14px]"
							>
								<div className="flex flex-col space-y-[10px] !pb-[15px]">
									<span>Specialty</span>
									<NAICSCodesList
										naicsCodes={account.contract.naicsCodes}
										showRatio
										className="!w-full min-w-full bg-white bg-opacity-60"
									/>
								</div>
								<LineItem
									label="Largest Project Completed"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatToDollar(account.contract.largestConstructionProjectCompleted)}
								</LineItem>
								<LineItem
									label="Years in Business or Credit Age (Main)"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{primaryCompany?.creditReports[0]?.yearsInBusinessOrCreditAge}
								</LineItem>
								<LineItem
									label="Failed to Complete a Job"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatYesNo(account.contract.failedToCompleteAConstructionJob)}
								</LineItem>
								<LineItem
									label="Caused a Loss To a Surety"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatYesNo(account.contract.causedSuretyLoss)}
								</LineItem>
								<LineItem
									label={`Current Backlog CTC${account.wipStatements.length ? ` (${DateTime.fromISO(account.wipStatements[0].createdAt).toFormat("MM/dd/yyyy")})` : ""}`}
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{formatEmpty(formatToDollar(account.wipStatements[0]?.projectBacklogCost))}
								</LineItem>
								<LineItem
									label="Projects With a Gross Loss Last 36 Months"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{account.contract.constructionProjectsGrossLossGt10PercentLast36Months !==
									undefined
										? formatYesNo(
												account.contract.constructionProjectsGrossLossGt10PercentLast36Months,
											)
										: "--"}
								</LineItem>
								<LineItem
									label="Current Project With an Expected Gross Loss"
									className="text-[14px]"
									labelClassName="text-[14px]"
								>
									{account.contract.currentConstructionProjectExpectedGrossLoss !== undefined
										? formatYesNo(account.contract.currentConstructionProjectExpectedGrossLoss)
										: "--"}
								</LineItem>
							</CardGrayBody>
							<div className="w-full flex flex-col space-y-[30px]">
								<div className="text-[20px] !my-[-10px] font-medium">Individuals</div>
								<Button
									onClick={() => setIndividualEditorOpen(true)}
									className="ml-[-5px] w-fit"
									color="gray"
									rounded
									filled
									thinFont
								>
									<div className="flex min-w-fit items-center space-x-[5px]">
										<HiPlus className="text-[20px] text-gray-800" />
										<div className="min-w-fit">Add Individual</div>
									</div>
								</Button>
								{individualEditorOpen && (
									<AdminIndividualModal
										account={account}
										selectedIndividualId={selectedIndividualId}
										onClose={() => setIndividualEditorOpen(false)}
									/>
								)}
								<div className="w-full flex gap-[15px] flex-wrap">
									{!account.individuals.length && "None"}
									{!!account.individuals.length &&
										account.individuals.map((individual, index) => (
											<Card
												key={index}
												className="px-[22px] py-[15px] w-fit space-y-[7px] shadow-sm bg-white"
											>
												<div className="justify-between flex items-center space-x-[10px]">
													<span className="text-[15px] font-medium">{formatName(individual)}</span>
													<Button
														color="gray"
														className="border-0 p-[8px] w-fit h-fit my-[-3px]"
														onClick={() => setSelectedIndividualId(individual.id)}
													>
														<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
													</Button>
												</div>
												<div className="flex flex-col">
													<div className="flex items-center space-x-[8px]">
														<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatAddress(individual.address))}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<HiAtSymbol className="text-[16px] stroke-[0.5] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(individual.email)}
														</span>
													</div>
													{individual.ssn && individual.config.contract.includeInUnderwriting && (
														<>
															<div className="flex items-center space-x-[8px] leading-[18px]">
																<span className="text-[13px] font-medium text-gray-500 italic">
																	SSN
																</span>
																<SensitiveData
																	value={individual.ssn}
																	className="text-[14px] text-gray-700"
																/>
															</div>
															<div className="flex items-center space-x-[8px] leading-[18px]">
																<span className="text-[13px] font-medium text-gray-500 italic">
																	OFAC
																</span>
																<span
																	className={cn(
																		"text-[14px]",
																		individual.ofacCheck?.hit ? "text-inrev-red" : "text-gray-700",
																	)}
																>
																	{formatYesNo(individual.ofacCheck?.hit)}
																</span>
																{individual.ofacCheck && (
																	<span className="text-[13px] text-gray-500 italic">
																		(
																		{DateTime.fromISO(
																			individual.ofacCheck.createdAt,
																		).toLocaleString(DateTime.DATE_SHORT)}
																		)
																	</span>
																)}
																{individual.ofacCheck &&
																	DateTime.fromISO(individual.ofacCheck.createdAt).diffNow("years")
																		.years < -1 && (
																		<HiOutlineExclamationTriangle className="text-[18px] stroke-[1.75] text-inrev-yellow" />
																	)}
															</div>
														</>
													)}
													<div className="flex items-center space-x-[8px] pt-[2px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in underwriting?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(individual.config.contract.includeInUnderwriting),
															)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in indemnity?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(individual.config.contract.includeInIndemnity),
															)}
														</span>
													</div>
													{individual.spouse && (
														<div className="flex flex-col space-y-[5px] leading-[18px] my-[10px]">
															<span className="text-[13px] font-medium text-gray-500 italic">
																SPOUSE
															</span>
															<div className="flex flex-col px-[10px] text-[14px] text-gray-700">
																<span>{formatName(individual.spouse)}</span>
																<span>{formatEmpty(formatAddress(individual.spouse.address))}</span>
																<span>{formatEmpty(individual.spouse.email)}</span>
															</div>
														</div>
													)}
												</div>
											</Card>
										))}
								</div>
							</div>
							<div className="w-full flex flex-col space-y-[30px]">
								<div className="text-[20px] !my-[-10px] font-medium">Companies</div>
								<Button
									onClick={() => setCompanyEditorOpen(true)}
									className="ml-[-5px] w-fit"
									color="gray"
									rounded
									filled
									thinFont
								>
									<div className="flex min-w-fit items-center space-x-[5px]">
										<HiPlus className="text-[20px] text-gray-800" />
										<div className="min-w-fit">Add Company</div>
									</div>
								</Button>
								{companyEditorOpen && (
									<AdminCompanyModal
										account={account}
										selectedCompanyId={selectedCompanyId}
										onClose={() => setCompanyEditorOpen(false)}
									/>
								)}
								<div className="w-full flex gap-[20px] flex-wrap">
									{!account.companies.length && "None"}
									{!!account.companies.length &&
										account.companies.map((company, index) => (
											<Card
												key={index}
												className="px-[22px] py-[15px] w-fit space-y-[7px] shadow-sm bg-white shrink-0"
											>
												<div className="justify-between flex items-center space-x-[10px]">
													<span className="text-[15px] font-medium">{company.name}</span>
													<Button
														color="gray"
														className="border-0 p-[8px] w-fit h-fit my-[-3px]"
														onClick={() => setSelectedCompanyId(company.id)}
													>
														<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
													</Button>
												</div>
												<div className="flex flex-col">
													<div className="flex items-center space-x-[8px]">
														<HiOutlineMapPin className="text-[16px] stroke-[1.75] text-gray-500" />
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatAddress(company.address))}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															FEIN
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(company.fein)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in underwriting?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(
																formatYesNo(company.config.contract.includeInUnderwriting),
															)}
														</span>
													</div>
													<div className="flex items-center space-x-[8px]">
														<span className="text-[13px] font-medium text-gray-500 italic">
															Included in indemnity?
														</span>
														<span className="text-[14px] text-gray-700">
															{formatEmpty(formatYesNo(company.config.contract.includeInIndemnity))}
														</span>
													</div>
												</div>
											</Card>
										))}
								</div>
							</div>
							<div className="w-full flex flex-col space-y-[40px]">
								<div className="text-[20px] !my-[-10px] font-medium">Financials</div>
								<CardGrayBody
									header={<div className="!my-[-10px] !mx-[-8px]">YTD</div>}
									contentClassName="py-[20px] px-[30px] space-y-[12px]"
								>
									<div className="flex flex-col space-y-[15px]">
										<LineItem
											label="Profitable YTD"
											className="text-[14px]"
											labelClassName="text-[14px]"
										>
											{formatEmpty(
												formatYesNo(
													(() => {
														if (account.financialStatements.length > 0) {
															if (
																DateTime.fromISO(account.financialStatements[0].date).plus({
																	years: 1,
																}) >= DateTime.now().startOf("day")
															) {
																return account.financialStatements[0].profitable;
															}
														}
														if (
															DateTime.fromISO(account.contract.underwriting.createdAt).year ===
															DateTime.now().year
														) {
															return account.contract.underwriting.input.financials.profitableYtd;
														}
													})(),
												),
											)}
										</LineItem>
									</div>
								</CardGrayBody>
								{account.financialStatements.length > 0 && (
									<div className="flex items-center space-x-[10px]">
										{financialStatementYears.map((year, index) => {
											if (index === currentFinancialStatementIndex) {
												return (
													<div
														key={index}
														className="px-[10px] py-[6px] cursor-pointer bg-gray-100 border border-gray-200 rounded-md"
														onClick={() => setCurrentFinancialStatementIndex(index)}
													>
														{year}
													</div>
												);
											} else {
												return (
													<div
														key={index}
														className="px-[10px] py-[6px] cursor-pointer rounded-md hover:bg-gray-50"
														onClick={() => setCurrentFinancialStatementIndex(index)}
													>
														{year}
													</div>
												);
											}
										})}
									</div>
								)}
								<div className="w-full flex flex-col space-y-[30px]">
									<div className="text-[17px] !my-[-10px] font-medium italic text-gray-500">
										Statements
									</div>
									<Button
										onClick={() => setFinancialStatementEditorOpen(true)}
										className="ml-[-5px] w-fit"
										color="gray"
										rounded
										filled
										thinFont
									>
										<div className="flex min-w-fit items-center space-x-[5px]">
											<HiPlus className="text-[20px] text-gray-800" />
											<div className="min-w-fit">Add Financial Statement</div>
										</div>
									</Button>
									{financialStatementEditorOpen && (
										<AdminFinancialStatementModal
											account={account}
											selectedFinancialStatementId={selectedFinancialStatementId}
											onClose={() => setFinancialStatementEditorOpen(false)}
										/>
									)}
									{/* Financial Statement Display */}
									{account.financialStatements.length > 0 && (
										<div className="flex space-x-[10px]">
											<div className="flex-1">
												<CardGrayBody
													header={
														<div className="justify-between flex items-center space-x-[10px]">
															<span className="text-[15px] font-medium">Financial Statement</span>
															<div className="flex items-right space-x-[8px] w-fit h-fit">
																<Button
																	color="gray"
																	className="border-0 w-fit h-fit"
																	onClick={() => {
																		if (currentFinancialStatementIndex !== undefined) {
																			const currentStatement = account.financialStatements.at(
																				currentFinancialStatementIndex,
																			);
																			if (currentStatement !== undefined)
																				setSelectedFinancialStatementId(currentStatement.id);
																		}
																	}}
																>
																	<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
																</Button>

																<ConfirmationModalClickWrapper
																	message={
																		<div className="w-[375px] text-center break-words">
																			Are you sure you want to delete this financial statement?
																		</div>
																	}
																	confirmButtonText="Delete"
																	className="items-center w-fit h-fit"
																	confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
																	onConfirm={() => {
																		if (currentFinancialStatementIndex !== undefined) {
																			const currentStatement = account.financialStatements.at(
																				currentFinancialStatementIndex,
																			);
																			if (currentStatement !== undefined)
																				deleteFinancialStatement(currentStatement.id);
																		}
																	}}
																>
																	<HiOutlineTrash className="text-[20px] stroke-[2px] text-red-400 hover:text-red-600 cursor-pointer w-fit h-fit" />
																</ConfirmationModalClickWrapper>
															</div>
														</div>
													}
													contentClassName="py-[25px] px-[30px] space-y-[30px]"
												>
													<div className="flex flex-col space-y-[15px] flex-1">
														<div className="text-[15px] text-gray-500 italic">
															Statement Details
														</div>
														<div className="flex flex-col space-y-[12px]">
															<LineItem
																label="Statement Preparation Method"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(currentFinancialStatementView?.preparationMethod)}
															</LineItem>
															<LineItem
																label="Statement Date"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	currentFinancialStatementView?.date !== undefined
																		? DateTime.fromISO(currentFinancialStatementView.date)
																				.toUTC()
																				.toLocaleString(DateTime.DATE_MED)
																		: undefined,
																)}
															</LineItem>
														</div>
													</div>
													<div className="flex flex-col space-y-[15px] flex-1">
														<div className="text-[15px] text-gray-500 italic">Assets</div>
														<div className="flex flex-col space-y-[12px]">
															<LineItem
																label="Cash"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.corporateCash),
																)}
															</LineItem>
															<LineItem
																label="A/Rs less than 90 days"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.accountsReceivable),
																)}
															</LineItem>
															<LineItem
																label="Underbillings"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.underbillings),
																)}
															</LineItem>
															<LineItem
																label="Current Assets"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.currentAssets),
																)}
															</LineItem>
															<LineItem
																label="Total Assets"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.totalAssets),
																)}
															</LineItem>
														</div>
													</div>
													<div className="flex flex-col space-y-[15px] flex-1">
														<div className="text-[15px] text-gray-500 italic">Liabilities</div>
														<div className="flex flex-col space-y-[12px]">
															<LineItem
																label="Accounts Payable"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.accountsPayable),
																)}
															</LineItem>
															<LineItem
																label="Credit Cards Balance"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.creditCardsPayable),
																)}
															</LineItem>
															<LineItem
																label="BLOC Balance"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.bankLinePayable),
																)}
															</LineItem>
															<LineItem
																label="Accrued Expenses"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.accruedExpenses),
																)}
															</LineItem>
															<LineItem
																label="Overbillings"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.overbillings),
																)}
															</LineItem>
															<LineItem
																label="Current Portion of LTD"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(
																		currentFinancialStatementView?.currentPortionOfLtd,
																	),
																)}
															</LineItem>
															<LineItem
																label="Current Liabilities"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.currentLiabilities),
																)}
															</LineItem>
															<LineItem
																label="Term Loan Debt"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.termLoanDebt),
																)}
															</LineItem>
															<LineItem
																label="Total Liabilities"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.totalLiabilities),
																)}
															</LineItem>
														</div>
													</div>
													<div className="flex flex-col space-y-[15px] flex-1">
														<div className="text-[15px] text-gray-500 italic">Income Statement</div>
														<div className="flex flex-col space-y-[12px]">
															<LineItem
																label="Revenue"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.revenue),
																)}
															</LineItem>
															<LineItem
																label="GA Expense"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatToDollar(currentFinancialStatementView?.gaExpense),
																)}
															</LineItem>
															<LineItem
																label="Profitable"
																className="text-[14px]"
																labelClassName="text-[14px]"
															>
																{formatEmpty(
																	formatYesNo(currentFinancialStatementView?.profitable),
																)}
															</LineItem>
														</div>
													</div>
													<div className="flex flex-col space-y-[15px] flex-1">
														<div className="text-[15px] text-gray-500 italic">Bank Details</div>
														<div className="flex flex-col space-y-[12px]"></div>
														<LineItem
															label="BLOC Size"
															className="text-[14px]"
															labelClassName="text-[14px]"
														>
															{formatEmpty(formatToDollar(currentFinancialStatementView?.blocSize))}
														</LineItem>
														<LineItem
															label="BLOC Available"
															className="text-[14px]"
															labelClassName="text-[14px]"
														>
															{formatEmpty(
																formatToDollar(
																	currentFinancialStatementView?.calculations.blocAvailable,
																),
															)}
														</LineItem>
													</div>
												</CardGrayBody>
											</div>
										</div>
									)}
								</div>
								<div className="flex flex-col space-y-[15px] flex-1">
									<CardGrayBody
										header={<div className="!my-[-10px] !mx-[-8px]">Work In Progress</div>}
										contentClassName="py-[20px] px-[30px] space-y-[12px]"
									>
										<LineItem
											label="Backlog (CTC)"
											className="text-[14px]"
											labelClassName="text-[14px]"
										>
											{formatEmpty(formatToDollar(currentWipStatementView?.projectBacklogCost))}
										</LineItem>
										<LineItem
											label="Gross Profit In Backlog"
											className="text-[14px]"
											labelClassName="text-[14px]"
										>
											{formatEmpty(
												formatToDollar(currentWipStatementView?.projectBacklogGrossProfit),
											)}
										</LineItem>
									</CardGrayBody>
								</div>
							</div>
							<_MetricsSection currentFinancialStatementView={currentFinancialStatementView} />
							<CardGrayBody
								header={
									<div className="flex items-center space-x-[10px]">
										<div className="flex-1">Indemnity</div>
										<div className="flex items-center space-x-[5px] text-[14px] text-gray-500 italic">
											{account.contract.indemnity.upToDate && (
												<>
													<HiOutlineCheckCircle className="text-[18px] stroke-[1.75] text-inrev-green" />
													<span>Up to date</span>
												</>
											)}
											{!account.contract.indemnity.upToDate && (
												<>
													<HiXMark className="text-[18px] stroke-[1.75] text-inrev-red" />
													<span>Not up to date</span>
												</>
											)}
										</div>
									</div>
								}
								contentClassName="p-[40px] pt-[25px] space-y-[35px]"
								headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
							>
								<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
									<div className="text-[15px] text-gray-500 italic">Required Indemnitors</div>
									<div className="flex flex-col space-y-[5px]">
										{account.contract.indemnity.requiredIndemnitors.companies.map(
											(indemnitor, index) => (
												<Card
													key={index}
													className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
												>
													<span className="text-[14px] flex-1">{indemnitor.name}</span>
												</Card>
											),
										)}
										{account.contract.indemnity.requiredIndemnitors.individuals.map(
											(indemnitor, index) => (
												<Card
													key={index}
													className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
												>
													<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
														<span>{indemnitor.name}</span>
														{indemnitor.email && (
															<span className="text-gray-500 font-medium">{indemnitor.email}</span>
														)}
													</div>
												</Card>
											),
										)}
									</div>
								</div>
								{!!(
									account.contract.indemnity.indemnityWaivers.companies.length ||
									account.contract.indemnity.indemnityWaivers.individuals.length
								) && (
									<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
										<div className="text-[15px] text-gray-500 italic">Waived Indemnitors</div>
										<div className="flex flex-col space-y-[5px]">
											{account.contract.indemnity.indemnityWaivers.companies.map(
												(waiver, index) => (
													<Card
														key={index}
														className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
													>
														<span className="text-[14px] flex-1">{waiver.name}</span>
													</Card>
												),
											)}
											{account.contract.indemnity.indemnityWaivers.individuals.map(
												(indemnitor, index) => (
													<Card
														key={index}
														className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
													>
														<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
															<span>{indemnitor.name}</span>
															{indemnitor.email && (
																<span className="text-gray-500 font-medium">
																	{indemnitor.email}
																</span>
															)}
														</div>
													</Card>
												),
											)}
										</div>
									</div>
								)}
								<div className="flex flex-col space-y-[10px] w-full auto-rows-min">
									<div className="flex items-center justify-between space-x-[15px]">
										<div className="text-[15px] text-gray-500 italic">Indemnity Agreement</div>
										{!!account.contract.indemnity.indemnityAgreements.length && (
											<Button
												color="gray"
												filled
												thinFont
												onClick={() =>
													setPdfViewerControl({
														fetchUrl: `/v2/admin/surety/accounts/${account.id}/indemnity-agreement/${SuretyType.contract}`,
														queryKey: [
															"indemnityAgreementFileUrl",
															account.id,
															SuretyType.contract,
														],
													})
												}
											>
												View
											</Button>
										)}
									</div>
									{!!account.contract.indemnity.indemnityAgreements.length &&
										account.contract.indemnity.indemnityAgreements[0].status === "pending" &&
										account.contract.indemnity.indemnityAgreements[0].signatureMethod ===
											"physical" && (
											<NotificationCard
												type="action"
												text="Upload copy of completed physical indemnity agreement"
											/>
										)}
									{!account.contract.indemnity.indemnityAgreements.length && (
										<span className="!mt-[10px] text-[15px] text-gray-800">None</span>
									)}
									{!!account.contract.indemnity.indemnityAgreements.length && (
										<Card className="flex flex-col space-y-[20px] px-[15px] py-[10px] pb-[20px] shadow-sm">
											<AdminDataList
												data={[
													{
														label: "Status",
														value: account.contract.indemnity.indemnityAgreements[0].status,
													},
													{
														label: "Signature Method",
														value:
															account.contract.indemnity.indemnityAgreements[0].signatureMethod,
													},
													{
														label: "Indemnitors",
														value: (
															<div className="w-fit flex flex-col spac-y-[2px] text-[14px]">
																{account.contract.indemnity.indemnityAgreements[0].indemnitors.companies.map(
																	(indemnitor, index) => (
																		<div
																			key={index}
																			className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
																		>
																			<span className="text-left">{indemnitor.name}</span>
																			{indemnitor.signerEmail && (
																				<span className="text-gray-500 font-medium text-right">
																					{indemnitor.signerEmail}
																				</span>
																			)}
																		</div>
																	),
																)}
																{account.contract.indemnity.indemnityAgreements[0].indemnitors.individuals.map(
																	(indemnitor, index) => (
																		<div
																			key={index}
																			className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
																		>
																			<span className="text-left">{indemnitor.name}</span>
																			{indemnitor.signerEmail && (
																				<span className="text-gray-500 font-medium text-right">
																					{indemnitor.signerEmail}
																				</span>
																			)}
																		</div>
																	),
																)}
															</div>
														),
													},
												]}
											/>
											{account.contract.indemnity.indemnityAgreements.length && (
												<div className="flex flex-col items-end space-y-[20px]">
													<LocalFileUpload
														value={indemnityAgreementFileUpload}
														allowedTypesAndLabels={{
															indemnity_agreement: "Completed Indemnity Agreement",
														}}
														maxFiles={1}
														allowedExtensions={[FileExtension.pdf]}
														onChange={setIndemnityAgreementFileUpload}
													/>
													<ConfirmationModalClickWrapper
														message="Are you sure?"
														subMessage="The system will issue bonds for any request in this account that is only pending an indemnity agreement"
														shouldConfirm={!!indemnityAgreementFileUpload.length}
														onConfirm={() =>
															uploadCompletedIndemnityAgreement(indemnityAgreementFileUpload[0])
														}
													>
														<Button
															color="light-blue"
															thinFont
															filled
															className="w-fit"
															disabled={!indemnityAgreementFileUpload.length}
														>
															Upload
														</Button>
													</ConfirmationModalClickWrapper>
													{uploadCompletedIndemnityAgreementIsLoading && <LoadingModal />}
													{deleteFinancialStatementIsLoading && <LoadingModal />}
												</div>
											)}
										</Card>
									)}
								</div>
							</CardGrayBody>
							<CommentsSection
								comments={comments}
								commentsLoading={commentsLoading}
								createComment={createComment}
								createCommentLoading={createCommentLoading}
								headerEnd={
									<AdminFollowerControls
										{...followersControl}
										manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this account"
										agencyId={account.agency.id}
									/>
								}
							/>
							<AttachmentsListSection
								attachments={attachments}
								upload={{
									url: `/v2/admin/surety/accounts/${account.id}/attachments`,
									onChange: (val) => setAttachments(val),
									allowedTypesAndLabels: accountAttachmentTypeLabelMap,
									invalidateQueryKeys: [["adminSuretyAccounts", account.id]],
								}}
								download={{
									baseUrl: `/v2/admin/surety/accounts/${account.id}/attachments`,
									baseQueryKey: "adminAccountAttachments",
								}}
								typeLabelMap={accountAttachmentTypeLabelMap}
							/>
						</div>
					</div>
					<PDFViewerModal
						control={pdfViewerControl}
						onClose={() => setPdfViewerControl(undefined)}
						header="View File"
					/>
					{isAccountFetching > 0 && <LoadingModal />}
				</WorkflowLayout>
			</AdminReUnderwriteSuretyAccountModalContext.Provider>
			{reUnderwriteFormOpen && (
				<AdminAccountReUnderwriteModal
					account={account}
					onClose={() => setReUnderwriteFormOpen(false)}
				/>
			)}
		</>
	);
};

const _MetricsSection = ({
	currentFinancialStatementView,
}: {
	currentFinancialStatementView: AdminSuretyAccount["financialStatements"][number] | undefined;
}) => {
	return (
		<div className="w-full flex flex-col space-y-[30px]">
			<div className="text-[17px] !my-[-10px] font-medium italic text-gray-500">Metrics</div>
			<div className="flex space-x-[10px]">
				<div className="flex flex-col space-y-[15px] flex-1">
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Liquidity</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem
							label="Cash / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.corporateCashToTotalAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Working Capital / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.workingCapitalToTotalAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Net Cash / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatementView?.calculations.netCashToTotalAssets),
							)}
						</LineItem>
						<LineItem
							label="Receivables Turnover"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(currentFinancialStatementView?.calculations.accountsReceivableTurnover)}{" "}
							{currentFinancialStatementView?.calculations.accountsReceivableTurnover ? "Days" : ""}
						</LineItem>
						<LineItem
							label="Underbillings / Working Capital"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.underbillingsToWorkingCapital,
								),
							)}
						</LineItem>
						<LineItem label="BLOC % Available" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatementView?.calculations.blocAvailability),
							)}
						</LineItem>
					</CardGrayBody>
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Leverage</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem
							label="Total Liabilities / Equity"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.totalLiabilitiesToEquity,
								),
							)}
						</LineItem>
						<LineItem
							label={
								<span>
									Working Capital / Current Assets
									<br />
									(Compression)
								</span>
							}
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.workingCapitalToCurrentAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Current Debt / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.currentDebtToTotalAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Bank Debt / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatementView?.calculations.bankDebtToTotalAssets),
							)}
						</LineItem>
					</CardGrayBody>
				</div>
				<div className="flex flex-col space-y-[15px] flex-1">
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Underwriting</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem label="Cash / Backlog" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.corporateCashToBacklogCost,
								),
							)}
						</LineItem>
						<LineItem
							label="Working Capital / Backlog"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.workingCapitalToBacklogCost,
								),
							)}
						</LineItem>
						<LineItem label="Equity / Backlog" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatementView?.calculations.equityToBacklogCost),
							)}
						</LineItem>
						<LineItem label="G&A / Equity" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatementView?.calculations.gaExpenseToEquity),
							)}
						</LineItem>
						<LineItem
							label="Backlog Gross Profit / G&A"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatementView?.calculations.backlogGrossProfitToGaExpense,
								),
							)}
						</LineItem>
					</CardGrayBody>
				</div>
			</div>
		</div>
	);
};

export const AdminSuretyAccountView = () => {
	const { id } = useParams();
	const { suretyAccount, suretyAccountIsLoading, suretyAccountError } = useAdminFetchSuretyAccount(
		id!,
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (suretyAccountError) {
			navigate("/accounts");
		}
	}, [suretyAccountError]);

	if (suretyAccountIsLoading || suretyAccount === undefined) return <LoadingModal />;
	else {
		return <_AdminSuretyAccountView account={suretyAccount} />;
	}
};
