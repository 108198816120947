import { ReactNode, UIEvent, forwardRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../lib/utils";
import { Button, ButtonProps } from "../ui/Button";
import { ShrinkingHeader } from "./ShrinkingHeader";

type ShrinkingHeaderSectionNavLayoutProps = {
	children: ReactNode;
	headerTitle: string;
	sections: { name: string; label: string; error?: boolean }[];
	headerButton?: boolean;
	headerButtonContent?: ReactNode;
	headerButtonProps?: ButtonProps;
};

export const ShrinkingHeaderSectionNavLayout = forwardRef<
	HTMLDivElement,
	ShrinkingHeaderSectionNavLayoutProps
>((props: ShrinkingHeaderSectionNavLayoutProps, ref) => {
	const [shrunk, setShrunk] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentSection = searchParams.get("section");
	const currentSectionIndex = props.sections.findIndex(
		(section) => section.name === currentSection,
	);

	const handleScroll = (event: UIEvent<HTMLDivElement>) => {
		const newScrollTop = event.currentTarget.scrollTop;
		if (newScrollTop >= 25) {
			if (!shrunk) setShrunk(true);
		} else if (newScrollTop === 0 && shrunk) setShrunk(false);
	};

	const handleSectionClick = (name: string) => {
		if (name !== currentSection) setSearchParams({ section: name });
	};

	const handleNextButtonClick = () => {
		setSearchParams({ section: props.sections[currentSectionIndex + 1].name });
	};

	const handleBackButtonClick = () => {
		setSearchParams({ section: props.sections[currentSectionIndex - 1].name });
	};

	const showNextButton = () => currentSectionIndex !== props.sections.length - 1;
	const showBackButton = () => currentSectionIndex !== 0;
	const showSubmitButton = () => currentSectionIndex === props.sections.length - 1;

	return (
		<div
			ref={ref}
			onScroll={handleScroll}
			className={`transition-all ease-linear overflow-y-auto w-full grid grid-cols-[1fr_minmax(min-content,_710px)_minmax(50px,_75px)_130px_1fr] px-[65px]`}
			style={{ gridTemplateRows: `${shrunk ? 55 : 85}px 1fr` }}
		>
			<div className="row-span-2"></div>
			<ShrinkingHeader
				shrunk={shrunk}
				title={props.headerTitle}
				button={props.headerButton}
				buttonContent={props.headerButtonContent}
				buttonProps={props.headerButtonProps}
			/>
			<div className="row-span-2"></div>
			<div className="row-span-2 sticky top-0 left-0 pt-[30px] flex flex-col space-y-[15px]">
				<div className="w-full bg-gray-50 rounded-[6px] p-[8px] min-h-fit">
					{props.sections.map((section) => (
						<div
							key={section.name}
							className={cn(
								"w-full flex items-center cursor-pointer hover:bg-gray-100 text-[14px] h-[34px] px-[10px] select-none",
								section.name === currentSection
									? "bg-gray-100 text-gray-900 font-medium"
									: "text-gray-600 font-normal",
								section.error ? "text-red-500" : undefined,
							)}
							onClick={() => handleSectionClick(section.name)}
						>
							{section.label}
						</div>
					))}
				</div>
				<div className="flex flex-col w-full space-y-[5px] min-h-fit">
					{showNextButton() && (
						<Button tabIndex={2} color="light-blue" filled onClick={() => handleNextButtonClick()}>
							Next
						</Button>
					)}
					{showSubmitButton() && (
						<Button tabIndex={2} type="submit" color="green" filled>
							Submit
						</Button>
					)}
					{showBackButton() && (
						<Button
							tabIndex={2}
							color="gray"
							filled
							className="hover:brightness-100"
							onClick={() => handleBackButtonClick()}
						>
							Back
						</Button>
					)}
				</div>
			</div>
			<div className="row-span-2"></div>
			<div className="w-full h-full px-[15px]">{props.children}</div>
		</div>
	);
});
