import { AttachmentFile, formatToDollar, formatToPercent } from "@inrev/inrev-common";
import { useContext, useState } from "react";
import { AttachmentsListSection } from "../../../../../components/layout/AttachmentsListSection";
import { CommentsSection } from "../../../../../components/layout/CommentsSection";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { accountAttachmentTypeLabelMap } from "../../../../../constants";
import { SubmittedSuretyAccount } from "../../../../../domain/agent/account/types";
import { SuretyAccountCommentsContext } from "../../AccountView";
import { AccountBondsTable } from "../../shared/AccountBondsTable";
import { AccountHeader } from "../../shared/AccountHeader";
import { AccountRequestsTable } from "../../shared/AccountRequestsTable";
import { AccountIndemnityAgreementSection } from "./AccountIndemnityAgreementSection";

type AccountUnderwrittenViewProps = {
	account: SubmittedSuretyAccount;
};

export const AccountUnderwrittenView = ({ account }: AccountUnderwrittenViewProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(account.attachments);
	const { comments, commentsLoading, createComment, createCommentLoading } = useContext(
		SuretyAccountCommentsContext,
	);

	return (
		<WorkflowLayout title={account.displayName}>
			<div className="w-[785px] max-w-[785px]">
				<div className="flex flex-col space-y-[40px] pb-[125px]">
					<AccountHeader account={account} />
					<div className="flex flex-col space-y-[52px]">
						{account.contract.bondingLine !== undefined && (
							<div className="flex flex-col w-full space-y-[20px]">
								<span className="text-[20px] text-gray-900 font-medium">Terms</span>
								<div className="flex flex-col w-full space-y-[30px]">
									<div className="flex items-center space-x-[15px]">
										<div className="flex items-center bg-gray-100 rounded-md px-[20px] h-[54px] w-[140px]">
											<span className="flex-1 text-[14px] text-gray-800 font-medium">Rate</span>
											<span className="text-[24px] text-gray-700 font-light">
												{formatToPercent(account.contract.bondingLine.rate)}
											</span>
										</div>
										<div className="flex-1 flex items-center bg-gray-100 rounded-md px-[20px] h-[54px] w-[140px]">
											<span className="flex-1 text-[14px] text-gray-800 font-medium">
												Single Limit
											</span>
											{!!account.contract.bondingLine.singleLimit && (
												<span className="text-[24px] text-gray-700 font-light">
													{formatToDollar(account.contract.bondingLine.singleLimit)}
												</span>
											)}
											{!!!account.contract.bondingLine.singleLimit && (
												<span className="text-[16px] text-gray-700 font-light italic">
													Per Request
												</span>
											)}
										</div>
										<div className="flex-1 flex items-center bg-gray-100 rounded-md px-[20px] h-[54px] w-[140px]">
											<span className="flex-1 text-[14px] text-gray-800 font-medium">
												Aggregate Limit
											</span>
											{!!account.contract.bondingLine.aggregateLimit && (
												<span className="text-[24px] text-gray-700 font-light">
													{formatToDollar(account.contract.bondingLine.aggregateLimit)}
												</span>
											)}
											{!!!account.contract.bondingLine.aggregateLimit && (
												<span className="text-[16px] text-gray-700 font-light italic">
													Per Request
												</span>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						<AccountIndemnityAgreementSection account={account} />
						<AccountRequestsTable account={account} />
						<AccountBondsTable account={account} />
						<CommentsSection
							comments={comments}
							commentsLoading={commentsLoading}
							createComment={createComment}
							createCommentLoading={createCommentLoading}
						/>
						<AttachmentsListSection
							attachments={attachments}
							preventDelete
							upload={{
								url: `/v2/surety/accounts/${account.id}/attachments`,
								onChange: (val) => setAttachments(val),
								allowedTypesAndLabels: accountAttachmentTypeLabelMap,
								invalidateQueryKeys: [["suretyAccounts", account.id]],
							}}
							download={{
								baseUrl: `/v2/surety/accounts/${account.id}/attachments`,
								baseQueryKey: "accountAttachments",
							}}
							typeLabelMap={accountAttachmentTypeLabelMap}
						/>
					</div>
				</div>
			</div>
		</WorkflowLayout>
	);
};
