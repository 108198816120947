import { AgencyId, Dtos } from "@inrev/inrev-common";
import { useContext } from "react";
import { useQuery } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";

export const useAdminFetchAgencyPreviews = () => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["adminAgencyPreviews"],
		queryFn: async () =>
			await get<Dtos.Admin.Agency.Get.Preview.Response[]>(`/v2/admin/agencies/previews`),
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { agencyPreviews: data, agencyPreviewsError: error, agencyPreviewsLoading: isLoading };
};

export const useAdminFetchAgencyAccountPreviews = (id?: AgencyId) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: id !== undefined ? ["adminAgencyAccountPreviews", id] : undefined,
		queryFn: async () =>
			await get<Dtos.SuretyAccount.Preview.Get.Response[]>(`/v2/admin/agencies/${id}/accounts`),
		enabled: !!id,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		agencyAccountPreviews: data,
		agencyAccountPreviewsError: error,
		agencyAccountPreviewsLoading: isLoading,
	};
};
