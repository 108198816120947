import type { Types } from "@inrev/inrev-common";
import type { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import { _AccountDraftFinancialsSection } from "../../../../agent/account/status/draft/sections/AccountDraftFinancialsSection";

type AdminAccountReUnderwriteFinancialsSectionProps = {
	account: AdminSuretyAccount;
	draft: Types.SuretyAccount.Draft.Contract.Type;
	sections: { name: string; label: string }[];
};

export const AdminAccountReUnderwriteFinancialsSection = ({
	account,
	draft,
	sections,
}: AdminAccountReUnderwriteFinancialsSectionProps) => {
	return (
		<_AccountDraftFinancialsSection sections={sections} accountId={account.id} draft={draft} />
	);
};
