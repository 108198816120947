import { Dtos } from "@inrev/inrev-common";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Button } from "../../../../components/ui/Button";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { useAdminUpdateSuretyAccountStatus } from "../../../../domain/admin/account/api";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { AdminDataList } from "../../shared/AdminDataList";
import { AdminReviewReasonList } from "../../shared/AdminReviewReasonList";
import { AdminReUnderwriteSuretyAccountModalContext } from "../AdminAccountView";
import { AdminEditableAccountBondingLineFormCard } from "./AdminAccountBondingLine";
import { AdminAccountBondingLineSectionLayout } from "./AdminAccountBondingLineSectionLayout";
import { AdminUpdateAccountStatusModal } from "./AdminUpdateAccountStatusModal";

type AdminAccountBondingLineSectionProps = {
	account: AdminSuretyAccount;
	className?: string;
};

export const AdminAccountBondingLineSection = ({
	account,
	className,
}: AdminAccountBondingLineSectionProps) => {
	const { updateSuretyAccountStatus, updateSuretyAccountStatusIsLoading } =
		useAdminUpdateSuretyAccountStatus(account.id, account.contract?.underwriting.id);
	const handleStatusUpdateFormSubmit = (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => {
		updateSuretyAccountStatus(data);
	};
	const [editFormOpen, setEditFormOpen] = useState(false);
	const ReUnderwriteModalButton = useContext(AdminReUnderwriteSuretyAccountModalContext);

	return (
		<AdminAccountBondingLineSectionLayout
			header="Bonding Lines"
			headerRight={
				<Button
					color="gray"
					className="border-0 p-[8px] w-fit h-fit my-[-3px]"
					onClick={() => setEditFormOpen(true)}
				>
					<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
				</Button>
			}
			createdAt={DateTime.fromISO(account.contract.underwriting.createdAt)}
			className={className}
		>
			<div className="flex flex-col space-y-[15px] flex-[2]">
				<AdminDataList
					data={[
						{ label: "Status", value: getStatusTagByStatus(account.contract.underwriting.status) },
						{
							label: "Score",
							value:
								account.contract.underwriting.score === undefined ? (
									"--"
								) : (
									<div className="w-fit h-fit px-[8px] py-[4px] border-[2px] rounded border-gray-600 bg-white text-gray-800">
										{account.contract.underwriting.score}
									</div>
								),
						},
					]}
					className="mt-[-15px] mb-[-10px]"
					rowClassName="py-[12px]"
				/>
				<div className="flex flex-col space-y-[25px]">
					<AdminReviewReasonList
						reviewReasons={account.contract.underwriting.reviewReasons}
						buttonClassName="text-[14px] text-gray-800"
					/>
					<div className="flex flex-col space-y-[10px]">
						<AdminEditableAccountBondingLineFormCard
							accountId={account.id}
							carrierName="inrev"
							rate={account.contract.bondingLine?.rate}
							singleLimit={account.contract.bondingLine?.singleLimit}
							aggregateLimit={account.contract.bondingLine?.aggregateLimit}
							autoUpdate={account.contract.bondingLine?.autoUpdate ?? false}
							isPublished={account.contract.bondingLine?.isPublished ?? false}
							exposure={account.exposure.contract.total}
						/>
						{(() => {
							const bondingLine = account.contract.carrierBondingLines.find(
								(bondingLine) => bondingLine.carrierName === "lexington-national-for-accelerant",
							);

							return (
								<AdminEditableAccountBondingLineFormCard
									accountId={account.id}
									carrierName="lexington-national-for-accelerant"
									rate={bondingLine?.rate}
									singleLimit={bondingLine?.singleLimit}
									aggregateLimit={bondingLine?.aggregateLimit}
									autoUpdate={bondingLine?.autoUpdate ?? false}
									exposure={bondingLine?.exposure.total ?? 0}
								/>
							);
						})()}
						{(() => {
							const bondingLine = account.contract.carrierBondingLines.find(
								(bondingLine) => bondingLine.carrierName === "accelerant",
							);

							return (
								<AdminEditableAccountBondingLineFormCard
									accountId={account.id}
									carrierName="accelerant"
									rate={bondingLine?.rate}
									singleLimit={bondingLine?.singleLimit}
									aggregateLimit={bondingLine?.aggregateLimit}
									autoUpdate={bondingLine?.autoUpdate ?? false}
									exposure={bondingLine?.exposure.total ?? 0}
								/>
							);
						})()}
					</div>
				</div>
			</div>
			<div className="flex flex-col space-y-[20px] flex-1">
				{ReUnderwriteModalButton}
				<div className="flex flex-col space-y-[10px]">
					<span className="text-gray-800 font-medium text-[14px]">Note</span>
					<div className="w-full flex-1 rounded-md bg-white overflow-y-auto px-[15px] py-[10px] text-[14px] text-gray-800 h-fit min-h-[175px] grow-0">
						{account.contract.underwriting.reviewNote}
					</div>
				</div>
			</div>
			{editFormOpen && (
				<AdminUpdateAccountStatusModal
					account={account}
					handleSubmit={handleStatusUpdateFormSubmit}
					onClose={() => setEditFormOpen(false)}
				/>
			)}
			{updateSuretyAccountStatusIsLoading && <LoadingModal />}
		</AdminAccountBondingLineSectionLayout>
	);
};
