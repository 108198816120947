import { Transition } from "@headlessui/react";
import {
	AttachmentFile,
	SuretyQuoteStatus,
	defaultAttachmentTypeLabelMap,
} from "@inrev/inrev-common";
import { ReactNode, useContext, useState } from "react";
import { AttachmentsListSection } from "../../../components/layout/AttachmentsListSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { BondRequest } from "../../../domain/agent/request/types";
import { BondRequestCardHeader } from "./BondRequestCardHeader";
import { BondRequestCardLayout } from "./BondRequestCardLayout";
import { BondRequestTermsAndConditions } from "./BondRequestTermsAndConditions";
import { BondRequestCommentsContext } from "./SubmittedBondRequestLayoutWrapper";

type SubmittedBondRequestLayoutProps = {
	request: BondRequest & Exclude<BondRequest, { status: "draft" | "review" | "bound" }>;
	banner?: ReactNode;
	actionButtons?: ReactNode;
	defaultTermsAndConditionsOpen?: boolean;
	children?: ReactNode;
};

export const SubmittedBondRequestLayout = ({
	request,
	banner,
	actionButtons,
	defaultTermsAndConditionsOpen,
	children,
}: SubmittedBondRequestLayoutProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(request.attachments);
	const [termsAndConditionsOpen, _setTermsAndConditionsOpen] = useState<boolean>(
		defaultTermsAndConditionsOpen ?? false,
	);
	const { comments, commentsLoading, createComment, createCommentLoading } = useContext(
		BondRequestCommentsContext,
	);

	return (
		<>
			<BondRequestCardLayout banner={banner}>
				<BondRequestCardHeader request={request} actionButtons={actionButtons} />
				{request.status !== SuretyQuoteStatus.declined && (
					<Transition
						show={termsAndConditionsOpen}
						as="div"
						enter="transition-all ease-in duration-[90ms]"
						enterFrom="h-0 opacity-50"
						enterTo="h-fit h-min-fit opacity-100"
						leave="transition-all ease-in duration-75"
						leaveFrom="opacity-0 h-fit min-h-fit"
						leaveTo="opacity-0 h-0"
					>
						<BondRequestTermsAndConditions request={request} />
					</Transition>
				)}
			</BondRequestCardLayout>
			{children}
			<CommentsSection
				comments={comments}
				commentsLoading={commentsLoading}
				createComment={createComment}
				createCommentLoading={createCommentLoading}
			/>
			<AttachmentsListSection
				attachments={attachments}
				typeLabelMap={defaultAttachmentTypeLabelMap}
				preventDelete
				upload={{
					url: `/v2/surety/quotes/${request.id}/attachments`,
					onChange: (val) => setAttachments(val),
					allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
					invalidateQueryKeys: [
						["bondRequests", request.id],
						["suretyAccounts", request.account.id],
					],
				}}
				download={{
					baseUrl: `/v2/surety/quotes/${request.id}/attachments`,
					baseQueryKey: "bondRequestAttachments",
				}}
			/>
		</>
	);
};
