import { FileExtension } from "@inrev/inrev-common";
import { HiOutlineDocument } from "react-icons/hi2";

export const CustomFileIcon = ({ extension }: { extension: FileExtension }) => (
	<div className="relative w-fit h-fit select-none">
		<HiOutlineDocument className="text-[25px] text-gray-700" />
		{[FileExtension.pdf, FileExtension.docx, FileExtension.xlsx].includes(extension as any) && (
			<div
				className={`
                absolute top-[11px] left-[2px] right-[2px] flex items-center justify-center leading-[10px] h-[10px] text-white text-[10px] font-medium
                ${extension === FileExtension.pdf ? "bg-red-500" : extension === FileExtension.docx ? "bg-blue-600" : extension === FileExtension.xlsx ? "bg-green-700" : ""}
            `}
			>
				{extension}
			</div>
		)}
	</div>
);
