import { useClose } from "@headlessui/react";
import { ReactNode } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { cn } from "../lib/utils";
import { Menu } from "./Menu";

type MoreActionsMenuProps = {
	items: (
		| {
				el: ReactNode;
				icon?: undefined;
				label?: undefined;
				onClick?: undefined;
				confirmationMessage?: undefined;
				className?: undefined;
		  }
		| {
				el?: undefined;
				icon?: React.ReactNode;
				label: string;
				onClick: () => void;
				confirmationMessage?: string;
				className?: string;
		  }
	)[];
	className?: string;
	panelClassName?: string;
};

const MoreActionsMenuItem = ({
	item,
	onClick,
}: { item: MoreActionsMenuProps["items"][number]; onClick?: () => void }) => {
	if (!!item.el) return item.el;
	return (
		<div
			className="flex items-center rounded-md hover:bg-gray-200/55 space-x-[8px] px-[8px] py-[5.5px] text-[13px] font-medium text-gray-700 cursor-pointer"
			onClick={onClick}
		>
			<span className="w-[16px] h-full">{item.icon && item.icon}</span>
			<span>{item.label}</span>
		</div>
	);
};

const MoreActionsMenuPanel = ({ items }: MoreActionsMenuProps) => {
	const close = useClose();

	return (
		<div className="flex flex-col w-full shrink-0 border space-y-[3px] border-gray-200 rounded-md cursor-pointer px-[3px] py-[5px]">
			{items.map((item, index) => {
				if (item.confirmationMessage) {
					return (
						<ConfirmationModalClickWrapper
							key={index}
							message={item.confirmationMessage}
							onConfirm={() => {
								item.onClick();
								close();
							}}
						>
							<MoreActionsMenuItem item={item} onClick={() => {}} />
						</ConfirmationModalClickWrapper>
					);
				} else {
					return (
						<MoreActionsMenuItem
							item={item}
							onClick={() => {
								item.onClick && item.onClick();
								close();
							}}
						/>
					);
				}
			})}
		</div>
	);
};

export const MoreActionsMenu = ({ items, className, panelClassName }: MoreActionsMenuProps) => {
	return (
		<Menu
			button={
				<div
					className={cn(
						"flex items-center justify-center w-full h-fit space-x-[4.5px] text-[12px] leading-[18px] font-medium text-gray-600/90 ui-open:text-gray-800 hover:text-gray-800 cursor:pointer",
						className,
					)}
				>
					<span>More Actions</span>
					<HiChevronDown className="text-[12px]" />
				</div>
			}
			buttonClassName="w-full justify-center"
			panelClassName={cn("shadow-sm shadow-gray-900/10 mt-[8px] w-[185px]", panelClassName)}
			anchor="bottom"
		>
			<MoreActionsMenuPanel items={items} />
		</Menu>
	);
};
