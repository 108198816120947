import { zodResolver } from "@hookform/resolvers/zod";
import { AgentUserRole, Dtos } from "@inrev/inrev-common";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { Button } from "../../../../components/ui/Button";
import { FormDropdown } from "../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { useInviteAgentUser } from "../../../../domain/agent/user/api";
import { stripEmptyResolver } from "../../../../utils/form";

type AgentInviteFormModalProps = {
	onClose: () => void;
};

type AgentInviteFormData = {
	email: string | "";
	role: AgentUserRole | "";
};

const getDefaultFormData = (): AgentInviteFormData => ({
	email: "",
	role: "",
});

export const AgentInviteFormModal = ({ onClose }: AgentInviteFormModalProps) => {
	const {
		inviteAgentUser,
		inviteAgentUserIsLoading,
		inviteAgentUserError,
		inviteAgentUserIsSuccess,
	} = useInviteAgentUser();
	const formMethods = useForm<AgentInviteFormData, any, Dtos.Agent.Invite.Request>({
		defaultValues: getDefaultFormData(),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Agent.Invite.Request.schema)),
	});

	const handleSubmit = (data: Dtos.Agent.Invite.Request) => {
		inviteAgentUser(data);
	};

	useEffect(() => {
		if (!inviteAgentUserIsLoading && (inviteAgentUserIsSuccess || inviteAgentUserError !== null)) {
			onClose();
			formMethods.reset(getDefaultFormData());
		}
	}, [inviteAgentUserIsLoading]);

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header="Invite a Team Member"
				className="w-[500px]"
				bodyClassName="py-[25px] px-[30px]"
			>
				<form onSubmit={formMethods.handleSubmit(handleSubmit)}>
					<FormItemGroup>
						<FormItem>
							<FormItemLabel>Email</FormItemLabel>
							<FormInput
								control={formMethods.control}
								name="email"
								readonly={inviteAgentUserIsLoading}
							/>
						</FormItem>
						<FormItem>
							<FormItemLabel>Role</FormItemLabel>
							<FormDropdown
								control={formMethods.control}
								name="role"
								options={[
									{ label: "Agent Admin", value: "agent-admin" },
									{ label: "Agent", value: "agent" },
								]}
								placeholder="Select one"
								readonly={inviteAgentUserIsLoading}
							/>
						</FormItem>
					</FormItemGroup>
					<div className="flex justify-end gap-[10px] mt-[35px]">
						<Button color="gray" filled onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit" color="light-blue" filled loading={inviteAgentUserIsLoading}>
							Invite
						</Button>
					</div>
				</form>
			</ModalItemWithHeader>
		</Modal>
	);
};
