import { Dtos, SuretyBondId } from "@inrev/inrev-common";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { AdminSuretyBond } from "./types";

export const useAdminFetchBond = (id: string, enabled: boolean = true) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["bonds", id],
		queryFn: async () => await get<AdminSuretyBond>(`/v2/admin/surety/bonds/${id}`),
		retry: false,
		enabled,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { bond: data, bondError: error, bondLoading: isLoading };
};

export const useImportBond = () => {
	const { post } = useRequest();
	const [importedBondId, setImportedBondId] = useState<SuretyBondId | undefined>(undefined);
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: ["importBond"],
		mutationFn: async (data: Dtos.Admin.SuretyBond.Import.Request) => {
			const bondId = (await post<SuretyBondId>(
				`/v2/admin/surety/bonds/import`,
				data,
				"text",
			)) as SuretyBondId;
			setImportedBondId(bondId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["bondPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { importBond: mutation.mutate, importBondLoading: mutation.isLoading, importedBondId };
};

export const useAdminCloseBond = (suretyBondId: SuretyBondId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.Admin.SuretyBond.Close.Request): Promise<void> => {
			await post(`/v2/admin/surety/bonds/${suretyBondId}/close`, data, "none");
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bonds", suretyBondId] });
			queryClient.refetchQueries({ queryKey: ["bondPreviews"] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { closeBond: mutation.mutate, closeBondLoading: mutation.isLoading };
};
