import { QueryKey, useQuery } from "react-query";
import { useRequest } from "../../utils/request";
import { Modal } from "../layout/Modal";
import { ModalItemWithHeader } from "../layout/ModalItemWithHeader";
import { Spinner } from "./Spinner";

export type PDFViewerControl = {
	fetchUrl: string;
	queryKey: QueryKey;
};

const _PDFViewerModal = ({ control }: { control: PDFViewerControl }) => {
	const { get } = useRequest();
	const { data, isLoading } = useQuery({
		queryKey: [...control.queryKey, { asDownload: false }],
		queryFn: async () => {
			return await get(`${control.fetchUrl}?download=false`, undefined, "text");
		},
		retry: false,
		staleTime: 1000 * 60 * 3,
		cacheTime: 1000 * 60 * 3,
		refetchOnWindowFocus: true,
		refetchOnMount: true,
		refetchOnReconnect: true,
	});

	if (isLoading)
		return (
			<div className="w-full h-full flex items-center justify-center">
				<Spinner />
			</div>
		);
	else if (data !== undefined)
		return (
			<embed src={data ?? ""} className="h-[80vh] w-[95vw] max-w-[775px]" type="application/pdf" />
		);
	else
		return <div className="w-full h-full flex items-center justify-center">Error loading file</div>;
};

type PDFViewerModalProps = {
	control: PDFViewerControl | undefined;
	header?: string;
	onClose: () => void;
};

export const PDFViewerModal = ({ control, header, onClose }: PDFViewerModalProps) => {
	return (
		<>
			{control !== undefined && (
				<Modal onClickOutside={onClose}>
					<ModalItemWithHeader header={header} onClose={onClose} className="!m-[20px]">
						<_PDFViewerModal control={control} />
					</ModalItemWithHeader>
				</Modal>
			)}
		</>
	);
};
