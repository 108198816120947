import { AttachmentFile } from "@inrev/inrev-common";
import { useContext, useState } from "react";
import { AttachmentsListSection } from "../../../../../components/layout/AttachmentsListSection";
import { CommentsSection } from "../../../../../components/layout/CommentsSection";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { accountAttachmentTypeLabelMap } from "../../../../../constants";
import { SubmittedSuretyAccount } from "../../../../../domain/agent/account/types";
import { SuretyAccountCommentsContext } from "../../AccountView";
import { AccountBondsTable } from "../../shared/AccountBondsTable";
import { AccountHeader } from "../../shared/AccountHeader";
import { AccountRequestsTable } from "../../shared/AccountRequestsTable";

type AccountDeclinedViewProps = {
	account: SubmittedSuretyAccount;
};

export const AccountDeclinedView = ({ account }: AccountDeclinedViewProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(account.attachments);
	const { comments, commentsLoading, createComment, createCommentLoading } = useContext(
		SuretyAccountCommentsContext,
	);

	return (
		<WorkflowLayout title={account.displayName}>
			<div className="w-[785px] max-w-[785px]">
				<div className="flex flex-col space-y-[40px] pb-[125px]">
					<AccountHeader account={account} />
					<div className="flex flex-col space-y-[52px]">
						<AccountRequestsTable account={account} />
						<AccountBondsTable account={account} />
						<CommentsSection
							comments={comments}
							commentsLoading={commentsLoading}
							createComment={createComment}
							createCommentLoading={createCommentLoading}
						/>
						<AttachmentsListSection
							attachments={attachments}
							preventDelete
							upload={{
								url: `/v2/surety/accounts/${account.id}/attachments`,
								onChange: (val) => setAttachments(val),
								allowedTypesAndLabels: accountAttachmentTypeLabelMap,
								invalidateQueryKeys: [["suretyAccounts", account.id]],
							}}
							download={{
								baseUrl: `/v2/surety/accounts/${account.id}/attachments`,
								baseQueryKey: "accountAttachments",
							}}
							typeLabelMap={accountAttachmentTypeLabelMap}
						/>
					</div>
				</div>
			</div>
		</WorkflowLayout>
	);
};
